import React from 'react';
import { Grid } from '@mui/material';
import {BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Logout from '../screens/auth/Logout';
import Welcome from '../screens/pages/Welcome';
import Header from './Header';
import WrapperComponent from '../utils/WrapperComponent';
import Dasbhboard from '../screens/pages/Dashboard';
import Perfil from '../screens/pages/Perfil';
import Facturacion from '../screens/pages/Facturacion';
import PlanesPago from '../screens/pages/PlanesPago';
import Productos from '../screens/pages/Productos';
import Informacion from '../screens/pages/Informacion';

class Logged extends React.Component{

    constructor(){
        super();
        this.state ={
            menus: []
        }
    }

    render(){
        return(
            <BrowserRouter>
                <Grid container>
                    <WrapperComponent item={Header}/>
                    <Grid item xs={12} md={12}>
                        <div className='container-div'>
                            <Routes>
                                <Route exact path="/" element={<WrapperComponent item={Dasbhboard}/>} />
                                <Route exact path="logout" element={<WrapperComponent item={Logout}/>} />
                                <Route exact path="perfil" element={<WrapperComponent item={Perfil}/>} />
                                <Route exact path="facturacion/:idPlan" element={<WrapperComponent item={Facturacion}/>} />
                                <Route exact path="planes" element={<WrapperComponent item={PlanesPago}/>} />
                                <Route exact path="productos_edit/:id_producto" element={<WrapperComponent item={Productos}/>} />
                                <Route exact path="informacion" element={<WrapperComponent item={Informacion}/>} />
                                
                            </Routes>
                        </div>
                    </Grid>
                </Grid>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </BrowserRouter>
        )
    }
}

export default Logged;
