import React, { Component } from 'react';
import { Container, Grid, Radio, FormControlLabel, RadioGroup, TextField, Card, CardHeader, CardContent, Button } from '@mui/material';
import '../../App.css';

export default class Informacion extends Component {

    render() {
        let heightBlock = window.screen.height - 60 - 144;
        return (

            <div className='container-div' style={{ height: heightBlock }}>
                <Container>
                    <Card style={{ marginTop: 50, padding: 20 }}>
                        <CardContent>
                            <div>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} md={4}>
                                        <div>
                                            <img src={'assets/informacion.png'} width='250' alt='Notificación'/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={8} style={{marginTop: 50}}>
                                        <h3 style={{textAlign: 'justify'}}> Estimado cliente, lo sentimos, usted ha recibido tres notificaciones por parte
                                            de los usuarios sobre infracciones cometidas dentro de nuestra plataforma, por favor para poder
                                            ayudar a desbloquear su cuenta escribanos a servicioalcliente.dacomprando@gmail.com </h3>
                                    </Grid>

                                </Grid>


                            </div>
                        </CardContent>


                    </Card>
                </Container>
            </div>
        )
    }




}