import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Menu, MenuItem, TextField, InputAdornment, Button, ListItemIcon } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import '../App.css';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import Helper from '../utils/Helper';

export default class MenuNav extends Component {

    constructor() {
        super();
        this.state = {
            menus: [],
            openMenuUser: false,
            openMenu: false,
            anchor: null,
            anchorEl: null,
            menusLogin: [],
            search: ''
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClickSubMenu= this.handleClickSubMenu.bind(this);
        this.handleCloseSubMenu=this.handleCloseSubMenu.bind(this);
        this.helper = new Helper();
    }

    componentDidMount() {
        let menus = []
        menus.push({ name: 'Inicio', path: '/' })

        let menusLogin = []
        menusLogin.push({ name: 'Iniciar Sesion', path: '/' })
        menusLogin.push({ name: 'Registrarse', path: '/register' })

        this.setState({ menus, menusLogin })
    }

    handleClick(event) {
        this.setState({
            anchorEl: event.currentTarget,
            openMenuUser: !this.state.openMenuUser
         
        })
    };

    handleClickSubMenu(event) {
        this.setState({
            anchor: event.currentTarget,
            openMenu: !this.state.openMenu
         
        })
    };

    handleClose() {
        this.setState({ anchorEl: null, openMenuUser: false })
    };
    handleCloseSubMenu() {
        this.setState({ anchor: null, openMenu: false })
    };

    render() {
        const { login } = this.props
        return (
            <div style={{ textAlign: 'center' }}>
                <IconButton
                    aria-label="menu"
                    aria-controls={this.state.openMenuUser ? 'mobile-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={this.state.openMenuUser ? 'true' : undefined}
                    onClick={this.handleClick}
                >
                    <MenuIcon style={{ color: '#000' }} />
                </IconButton>
                <Menu
                    id="mobile-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={this.state.anchorEl}
                    open={this.state.openMenuUser}
                    onClose={this.handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                left: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}

                >

                    {this.state.menus.map((menu, index) => (
                        <MenuItem key={'Mu' + index} component={Link} to={menu.path} onClick={this.handleClose}>
                            {menu.name}
                        </MenuItem>
                    ))}
                    <center>
                        <Button
                            variant="online"
                            size="small"
                            color="primary"
                            className='text-menu1'
                            aria-label='Vender'
                            aria-controls={this.state.openMenuUser ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={this.state.openMenuUser ? 'true' : undefined}
                            onClick={this.handleClickSubMenu}
                            style={{ backgroundColor: '#FFA500' }}
                        >Vender </Button>

                        <Menu
                            anchorEl={this.state.anchor}
                            id="account-menu"
                            open={this.state.openMenu}
                            onClose={this.handleCloseSubMenu}
                            onClick={this.handleCloseSubMenu}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            {!login && this.state.menusLogin.map((menu, index) => (
                                <MenuItem key={'smu' + index} component={Link} to={menu.path} onClick={this.handleClose}>
                                    <ListItemIcon>
                                        <PersonOutlineIcon fontSize="small" />
                                    </ListItemIcon>
                                    {menu.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </center>
                </Menu>
            </div>
        )
    }
}
