import { useState, useEffect } from "react";

const ProgressiveImg = ({ placeholderSrc, src,claseImagen, ...props }) => {
    const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
    const customClass = placeholderSrc && imgSrc === placeholderSrc ? "loading" : "loaded";

  
    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setImgSrc(src);
        };
    }, [src]);

    return (
        <img
            {...{ src: imgSrc, ...props }}
            alt={props.alt || "Cargando"}
            className={`${claseImagen} ${customClass}`}
        />
    );
};
export default ProgressiveImg;