import React, { Component } from 'react';

import {
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    CardContent,
    CardHeader,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Preloader from '../../components/Preloader';


export default class ModalTerminos extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loader: false
        }
    }

    componentDidMount() {
        this.setState({ loader: true })
    }

    render() {
        const { data, onCancel, isModalShow } = this.props;
        if (!this.state.loader) {
            return (<Preloader />)
        } else {
            return (
                <Dialog
                    open={isModalShow}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                    <h4>TÉRMINOS Y CONDICIONES </h4>
                        {onCancel ? (
                            <IconButton
                                aria-label="close"
                                onClick={onCancel}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    <DialogContent dividers>
                        <div>
                            
                             
                               
                                    <div style={{ marginRight: 50, marginLeft: 50 }} >
                                        <p style={{ textAlign: 'justify' }}>
                                            DA COMPRANDO ha establecido los términos y condiciones que se aplicarán en nuestra plataforma de tal forma que al navegar en nuestro sitio, CLIENTES Y
                                            USUARIOS aceptan en su totalidad y sin ninguna limitación los términos y condiciones que a continuación se enuncian.
                                        </p>
                                        <h3>
                                            <b>POLÍTICA DE PRIVACIDAD </b>
                                        </h3>
                                        <p style={{ textAlign: 'justify' }}>
                                            DA COMPRANDO considera que los datos personales proporcionados por nuestros clientes de manera voluntaria, nos compromete a cuidar de manera permanente los datos
                                            recopilados por nuestra plataforma los mismos que serán utilizados bajo los siguientes parámetros:
                                        </p>
                                        <ol type="a">
                                            <li style={{ textAlign: 'justify' }}>Para poder acceder a los servicios que nuestra plataforma ofrece, el cliente deberá proporcionar sus datos
                                                personales de manera exacta de tal forma que correspondan a la verdad y puedan ser verificados.</li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}> Los datos proporcionados de manera voluntaria por el cliente, serán recopilados y almacenados de manera segura,
                                                utilizando los medios tecnológicos apropiados para dicho fin.</li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>Los datos proporcionados serán utilizados para crear canales de comunicación que permitan brindar un mejor servicio de manera permanente, así como para procesos de facturación o enviar información relevante sobre ofertas, nuevos productos o cambios importantes
                                                en la prestación de nuestros servicios, todo esto a través de correos electrónicos.</li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>DA COMPRANDO, se compromete a cuidar de manera celosa su información personal y no distribuirá, venderá ni cederá derechos sobre la misma, salvo que exista aceptación u autorización directa
                                                del cliente y en casos excepcionales por orden de la autoridad competente. </li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>Nuestros clientes podrán en cualquier momento darse de alta para dejar de recibir información por
                                                correo electrónico, sobre promociones, publicidad o boletines. </li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>El cliente se compromete a cuidar su cuenta de usuario y contraseña, de tal forma que sea el único que tenga acceso a la información sensible y así evitar accesos no autorizados, por tanto,
                                                DA COMPRANDO no se hace responsable de la inobservancia de este término.  </li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>El presente documento entra en vigencia a partir de su publicación y mientras dure la relación comercial entre el cliente y DA COMPRANDO. </li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>DA COMPRANDO se compromete a guardar la confidencialidad de los datos personales almacenados en nuestra plataforma    aún después de que la relación comercial haya terminado, salvo que exista autorización expresa y por escrito que disponga lo contrario,
                                                emitida por la parte propietaria o por la autoridad competente.</li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>DA COMPRANDO se reserva el derecho de modificar, aumentar, cambiar o quitar cualquier termino de este documento en cualquier momento sin previo aviso.</li>
                                        </ol>

                                        <h3>
                                            <b>TÉRMINOS Y CONDICIONES DE USO DE LA PLATAFORMA DA COMPRANDO </b>
                                        </h3>
                                        <h4>1. DE LOS CLIENTES </h4>
                                        <p style={{ textAlign: 'justify' }}>En el presente documento, se denominará “Clientes” a las personas mayores de 18 años que hayan comprado uno o más paquetes de nuestros servicios a través de nuestra plataforma www.dacomprando.com.ec, los mismos que estarán sujetos a los presentes términos
                                            y condiciones que desde ya aceptan las condiciones expresas que se detallan a continuación :</p>
                                        <ol type="a">
                                            <li style={{ textAlign: 'justify' }}>El usuario para ser nuestro cliente deberá registrarse en nuestra plataforma www.dacomprando.com.ec  y declarar ser mayor de 18 años de edad y encontrarse facultado para asumir obligaciones vinculantes con respecto a cualquier
                                                tipo de responsabilidad que se genere  por el uso de los servicios en nuestra plataforma.</li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>Todos los derechos de propiedad intelectual de la plataforma DA COMPRANDO sus contenidos y diseños pertenecen a DA COMPRANDO.</li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>El cliente se compromete a respetar los derechos de propiedad intelectual y de titularidad de DA COMPRANDO </li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>La utilización de marcas, códigos e imágenes para promocionar productos por parte de nuestros clientes será directamente responsabilidad del CLIENTE, dejando libre de cualquier responsabilidad a DA COMPRANDO por reclamos de terceros por derechos de autor o de propiedad intelectual.</li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>El cliente no podrá vender en nuestra plataforma www.dacomprando.com.ec los siguientes productos o servicios: mascotas, armas de fuego, armas no letales (de fogueo y traumáticas ), gas pimienta, municiones, manuales para armar bombas, manuales para uso de armas de fuego, manuales para uso de armas  no letales, explosivos o componentes para armar bombas,  ningún tipo de droga prohibida por la LEY ECUATORIANA, productos o juegos sexuales , pornografía infantil (literatura, fotos y videos ), pornografía para adultos (literatura-fotos y videos), servicios sexuales o damas de compañía ,  controles universales, inhibidores de señal o JAMMERS, servicios de financiamiento por parte de entidades financieras que no estén reguladas  y registradas en la SUPER INTENDENCIA DE BANCOS y SUPER INTENDENCIA DE COMPAÑIAS del ESTADO ECUATORIANO , bases de datos , duplicados de placas de vehículos
                                                y todo producto o servicio que la ley prohíba en territorio ecuatoriano.</li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>Los valores pagados por productos  o servicios en la plataforma  www.dacomprando.com  no será objeto de devolución o reembolso cuando exista razones de fuerza mayor tales como desastres naturales , interrupción de los servicios de inter conexión , daños en los servidores que utiliza la
                                                plataforma www.dacomprando.com.ec  y por casos fortuitos. </li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>El cliente que venda, promocione o direccione a sitios fuera de nuestra plataforma, productos que están expresamente prohibidos en la plataforma DA COMPRANDO, automáticamente serán suspendidos todos los derechos que se adquieren al comprar un paquete, todas las publicaciones se darán de baja y no podrán ser publicados nuevamente </li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>El CLIENTE podrá publicar sus productos y servicios hasta el último día en que termina el PLAN CONTRATADO.</li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>Si el CLIENTE no ha renovado el PLAN CONTRATADO, sus productos dejarán de visualizarse en la plataforma al siguiente día de su vencimiento y serán dados de baja a los TREINTA (30) días del vencimiento y el CLIENTE deberá volver a subir los productos a la plataforma   una vez que haya comprado un nuevo PLAN .</li>

                                        </ol>
                                        <h4>1. DE LOS USUARIOS </h4>
                                        <ol type="a">
                                            <li style={{ textAlign: 'justify' }}>En el presente documento se denominará USUARIO, a las personas que de manera voluntaria visita la plataforma www.dacomprando.com.ec para observar, consultar o comprar los productos y servicios que se ofrecen en la misma.</li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>Los USUARIOS, podrán comprar los productos y servicios que se promocionan en la plataforma www.dacomprando.com.ec sin tener que registrar sus datos personales, DA COMPRANDO asume que el USUARIO es mayor de edad y está en goce de sus DERECHOS Y OBLIGACIONES, como ciudadano.</li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>El USUARIO, se compromete a respetar todos los DERECHOS DE PROPIEDAD INTELECTUAL Y DE TITULARIDAD de DA COMPRANDO. </li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>El USUARIO acepta y reconoce que DA COMPRANDO no tiene, ni tendrá en el futuro responsabilidad alguna sobre incumplimientos de: pago, envío, calidad del producto, estado del producto o cualquier otro acuerdo que el VENDEDOR Y COMPRADOR hayan incumplido en el proceso de venta de productos o servicios ofrecidos en la plataforma.</li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>El USUARIO reconoce y acepta que, DA COMPRANDO es un facilitador que pone en contacto a vendedores y compradores a través de la plataforma www.dacomprando.com.ec  para que realicen transacciones  mercantiles lícitas en términos de conveniencia para las partes, sin que intervenga  en ningún momento en la negociación .</li>
                                            <li style={{ textAlign: 'justify', marginTop: 10 }}>El USUARIO se compromete a ser un VEEDOR CIUDADANO, que notifique mediante el canal de comunicación creado por DA COMPRANDO para denunciar la inobservancia de las normas establecidas por DA COMPRANDO y las leyes ecuatorianas, utilizando el botón de NOTIFICAR, el mismo que le llevará a un formulario donde podrá dar a conocer lo ocurrido dentro de la plataforma,
                                                no sin antes registrar sus datos personales que permitan dar seguimientos a la denuncia realizada.</li>


                                        </ol>

                                    </div>
                       

                        </div>


                    </DialogContent>
                </Dialog>
            )
        }
    }





}