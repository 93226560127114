import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class ProductosService extends BaseService {

    constructor() {
        super();
        this.collection = 'productos';
    }


    getProductosPorVistas(usuariosBloqueados, callback) {
        firebase.firestore()
        .collection(this.collection)
        .where('estado', '==', 1)
        .orderBy('vistas', 'desc').limit(10).onSnapshot(snapshot => {
            let productos = []
            snapshot.forEach(item => {
                let producto = item.data();
                producto.id = item.id
                productos.push(producto)
            })
            callback(productos)
        })
    }

    getProductosVendedor(idVendedor, callback) {
        firebase.firestore()
        .collection(this.collection)
        .where('idVendedor', '==', idVendedor)
        .limit(10)
        .get().then(snapshot => {
            let productos = []
            snapshot.forEach(item => {
                let producto = item.data();
                producto.id = item.id
                productos.push(producto)
            })
            callback(productos)
        })
    }


    getAllProductosVendedor(idVendedor, callback) {
        firebase.firestore()
            .collection(this.collection)
            .where('idVendedor', '==', idVendedor)
            .get().then(snapshot => {
                let productos = []
                snapshot.forEach(item => {
                    let producto = item.data();
                    producto.id = item.id
                    productos.push(producto)
                })
                callback(productos)
            })
    }

    async getAllProductosVendedorAsync(idVendedor) {
        let snapshot = await firebase.firestore()
            .collection(this.collection)
            .where('idVendedor', '==', idVendedor)
            .get()

        let productos = [];
        snapshot.forEach(item => {
            let producto = item.data();
            producto.id = item.id
            productos.push(producto)
        })
        return productos;
    }

    getFirstProduct(filtros, orden, limit) {
        let consulta = firebase.firestore().collection(this.collection)
        consulta = this.generateConditionsFilter(consulta, filtros, orden);
        consulta = consulta.where('estado', '==', 1).limit(limit)
        return consulta
    }

    getNextProduct(filtros, orden, limit, lastVisible) {
        let consulta = firebase.firestore().collection(this.collection)
        consulta = this.generateConditionsFilter(consulta, filtros, orden);
        consulta = consulta.startAfter(lastVisible)
        consulta = consulta.where('estado', '==', 1).limit(limit)
        return consulta
    }

    getBackProduct(filtros, orden, limit, lastVisible) {
        let consulta = firebase.firestore().collection(this.collection)
        consulta = this.generateConditionsFilter(consulta, filtros, orden);
        consulta = consulta.endBefore(lastVisible)
        consulta = consulta.where('estado', '==', 1).limit(limit)
        return consulta
    }

    generateConditionsFilter(consulta, filtros, orden){
        if (filtros['idVendedor']) {
            consulta = consulta.where('idVendedor', '==', filtros['idVendedor'])
        }

        if (filtros['categoria']) {
            consulta = consulta.where('categorias', 'array-contains', filtros['categoria'])
        }

        if (filtros['nombre']) {
            consulta = consulta.where('nombreArray', 'array-contains-any', filtros['nombre'])
        }
        if (filtros['provincia']) {
            consulta = consulta.where('provincia', '==', filtros['provincia'])

        }
        if (filtros['ciudad']) {
            consulta = consulta.where('ciudad', '==', filtros['ciudad'])

        }
        if (filtros['precio'].length > 0) {
            if (filtros['precio'][0] && filtros['precio'][1]) {
                consulta = consulta
                    .where('precio', '>=', parseFloat(filtros['precio'][0]))
                    .where('precio', '<=', parseFloat(filtros['precio'][1]))
                    .orderBy('precio')
            }
        }
        if (filtros['marca']) {
            consulta = consulta.orderBy('marca')
        }

        if (orden['fecha']) {
            consulta = consulta.orderBy('fecha', 'desc')
        }

        if (orden['nombre']) {
            consulta = consulta.orderBy('nombre', 'asc')
        }


        if (filtros['marca']) {
            consulta = consulta.startAt(filtros['marca']).endAt(filtros['marca'] + '\uf8ff')
        }
        return consulta;
    }

    getProductoPorCategorias(idCategoria, callback) {
        firebase.firestore()
            .collection(this.collection)
            .where('categorias', 'array-contains', idCategoria)
            .limit(10)
            .get().then(snapshot => {
                let productos = []
                snapshot.forEach(item => {
                    let producto = item.data();
                    producto.id = item.id
                    productos.push(producto)
                })
                callback(productos)
            })

    }


    actualizarEstadoProductos(productos, estado, callback) {

        let batch = firebase.firestore().batch();
        productos.forEach((producto) => {
            if (producto.estado !== estado) {
                let resultadosCollection = firebase.firestore()
                    .collection(this.collection).doc(producto.id)
                let data = { estado: estado }
                batch.update(resultadosCollection, data)
            }
        })
        batch.commit().then((docRef) => {
            callback()
        });
    }

    async getProductosPorBloque(bloque) {
        let snapshot = await firebase.firestore()
            .collection(this.collection)
            .where('bloque', '==', bloque)
            .get()

        let productos = [];
        snapshot.forEach(item => {
            let producto = item.data();
            producto.id = item.id
            productos.push(producto)
        })

        return productos

    }

    actualizarDataProductos(productos, data, callback) {

        let batch = firebase.firestore().batch();
        productos.forEach((producto) => {
            let resultadosCollection = firebase.firestore()
                .collection(this.collection).doc(producto.id)
            batch.update(resultadosCollection, data)

        })
        batch.commit().then((docRef) => {
            callback()
        });
    }

    getProductoClave(nombreClave, callback){
        return firebase.firestore()
        .collection(this.collection)
        .where('nombreClave', '==', nombreClave)
        .get().then(snapshot => {
            let productos = []
            snapshot.forEach(item => {
                let producto = item.data();
                producto.id = item.id
                productos.push(producto)
            })
            callback(productos[0])
        })
    }
}

export const productosService = new ProductosService();