import React from 'react';
import { Grid, ListItemIcon, Menu, MenuItem, Button, TextField, InputAdornment, IconButton, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import '../App.css';
import UserNav from '../components/UserNav';
import MenuNav from '../components/MenuNav';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Helper from '../utils/Helper'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Link } from 'react-router-dom';


class Header extends React.Component {

    constructor() {
        super();
        this.state = {
            search: '',
            isLogin: false,
            categorias: [],
            openMenuUser: false,
            anchorEl: null
        }

        this.helper = new Helper()
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);

    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user !== null) {
                if (user.emailVerified) {
                    this.setState({ isLogin: true });
                }
            }
        })

    }

    redirect = (page) => () => {
        this.props.navigate(page);
    }

    handleClick(event) {
        this.setState({
            anchorEl: event.currentTarget,
            openMenuUser: !this.state.openMenuUser
        })
    };

    handleClose() {
        this.setState({ anchorEl: null, openMenuUser: false })
    };

    render() {
        let showMenuButton = false;
        let dimensionsGridMd = [1.2, 1.3];
        if (window.innerWidth <= 625) {
            showMenuButton = true;
        }

        return (
            <Grid item xs={12} md={12}>
                <div className='header'>
                    <Grid item container spacing={2}>
                        <Grid item xs sm md={2.5} className='header-item-padre'>
                            <div style={{ cursor: 'pointer' }} onClick={this.redirect('/')} className='header-item-hijo'>
                                <img src='/assets/logo-prueba1.png' height={90} alt='DA COMPRANDO'></img>
                            </div>
                        </Grid>
                        {!showMenuButton &&
                            <Grid item xs={12} sm md className='header-item-padre'></Grid>
                        }
                        
                        {!showMenuButton &&
                            <Grid item xs={3.5} sm={1.5} md={dimensionsGridMd[0]} className='header-item-padre'>
                                <div className='header-item-hijo'>
                                    <Link to={'/'} className='text-menu'>Inicio</Link>
                                </div>
                            </Grid>
                        }

                        {!showMenuButton && !this.state.isLogin &&
                            <Grid item xs sm={1.5} md={1.5} className='header-item-padre'>
                                <div className='header-item-hijo' >
                                    <Button
                                        variant="online"
                                        size="small"
                                        color="primary"
                                        className='text-menu1'
                                        aria-label='Vender'
                                        aria-controls={this.state.openMenuUser ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={this.state.openMenuUser ? 'true' : undefined}
                                        onClick={this.handleClick}
                                        style={{ backgroundColor: '#FFA500' }}

                                    >Vender</Button>

                                    <Menu
                                        anchorEl={this.state.anchorEl}
                                        id="account-menu"
                                        open={this.state.openMenuUser}
                                        onClose={this.handleClose}
                                        onClick={this.handleClose}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                mt: 1.5,
                                                '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                '&:before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: 'background.paper',
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0,
                                                },
                                            },
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >

                                        <MenuItem component={Link} to="/register">
                                            <ListItemIcon>
                                                <PersonOutlineIcon fontSize="small" />
                                            </ListItemIcon>
                                            Registrarse
                                        </MenuItem>

                                        <MenuItem component={Link} to="/">
                                            <ListItemIcon>
                                                <PersonOutlineIcon fontSize="small" />
                                            </ListItemIcon>
                                            Iniciar Sesión
                                        </MenuItem>


                                    </Menu>
                                </div>
                            </Grid>
                        }

                        {this.state.isLogin &&
                            <Grid item xs sm={2.2} md={3} className='header-item-padre'>
                                <div className='header-item-hijo'>
                                    <UserNav navigate={this.props.navigate} />
                                </div>
                            </Grid>
                        }

                        {showMenuButton && !this.state.isLogin && (
                            <Grid item xs={2} className='header-item-padre'>
                                <div className='header-item-hijo'>
                                    <MenuNav login={this.state.isLogin} navigate={this.props.navigate} />
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </Grid>
        )
    }
}
export default Header;