import React, {Component} from 'react';
import { Grid } from '@mui/material';
import {BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Logout from '../screens/auth/Logout';
import Welcome from '../screens/pages/Welcome';
import Header from './Header';
import WrapperComponent from '../utils/WrapperComponent';
import Categorias from '../screens/pages/Categorias';
import Usuarios from '../screens/pages/Usuarios';
import Publicidad from '../screens/pages/Publicidad';
import PlanesAdmin from '../screens/pages/PlanesAdmin';
import Perfil from '../screens/pages/Perfil'
import TablaDenuncias from '../screens/pages/TablaDenuncias';


export default class Admin extends Component{

    constructor(){
        super();
        this.state = {
            menus: []
        }
    }

    render(){
        let heightBlock = window.screen.height - 60 - 144;
        return(
            <BrowserRouter>
                <Grid container>
                    <WrapperComponent item={Header}/>
                    <Grid item xs={12} md={12}>
                        <div className='container-div' style={{height: heightBlock}}>
                            <Routes>
                                <Route exact path="/" element={<WrapperComponent item={Welcome}/>} />
                                <Route exact path="/categorias" element={<WrapperComponent item={Categorias}/>} />
                                <Route exact path="/usuarios" element={<WrapperComponent item={Usuarios}/>} />
                                <Route exact path="logout" element={<WrapperComponent item={Logout}/>} />
                                <Route exact path="/publicidad" element={<WrapperComponent item={Publicidad}/>} />
                                <Route exact path="/planes" element={<WrapperComponent item={PlanesAdmin}/>} />
                                <Route exact path="perfil" element={<WrapperComponent item={Perfil}/>}/>
                                <Route exact path="/denuncias" element={<WrapperComponent item={TablaDenuncias}/>}/>
                                

                            </Routes>
                        </div>
                    </Grid>
                </Grid>
                
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </BrowserRouter>
        )
    }
}
