
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CardContent, Grid, Card, Container, tableCellClasses, TableCell, Paper, IconButton, MenuItem, TextField, Button, Table, TableBody, TableContainer, TableHead, TableRow, Fab } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { planesService } from '../../services/planes.service';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteModal from '../../components/DeleteModal';
import { bloquesService } from '../../services/bloques.service';


const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    '&:hover': {
        backgroundColor: grey[700],
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default class Planes extends React.Component {

    constructor() {
        super()
        this.state = {
            showEditor: false,
            showTabla: true,
            plan: {
                nombre: '',
                bloque: '',
                meses: '',
                gratuidad: '',
                cantidadProductos: '',
                precio: '',
                descripcionesAdicionales: [],
                orden: '',

            },


            error: {
                nombre: { error: false, text: '', required: true },
                bloque: { error: false, text: '', required: true },
                meses: { error: false, text: '', required: true },
                cantidadProductos: { error: false, text: '', required: true },
                precio: { error: false, text: '', required: true },
                gratuidad: { error: false, text: '', required: false },
                descripcion: { error: false, text: '', required: false },
                orden: { error: false, text: '', required: true }
            },
            listadoBloques: [],
            listadoMeses: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            listadoGratuidad: [0, 1, 2, 3],
            descripcion: '',
            idPlanEditable: '0',
            planes: [],
            isModalShowDelete: false,
            expanded: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
    }

    componentDidMount() {
        this.getPlanes()
    }

    getPlanes() {
        planesService.getRegistersOrden().get().then(async (res) => {
            let planes = []
            res.forEach(item => {
                let plan = item.data()
                plan.id = item.id
                planes.push(plan)
            })
            // cuidado con el de bloques 
            for (let index = 0; index < planes.length; index++) {
                planes[index].nombreBloque = ''
                let bloque = await bloquesService.getBloqueAsync(planes[index].bloque)
                if (bloque) {

                    planes[index].nombreBloque = bloque.nombre

                }


            }
            this.setState({ planes })
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'descripcion') {
            this.setState({ [name]: value });
        } else {
            let plan = { ...this.state.plan }
            plan[name] = value
            this.setState({ plan })
        }
    }


    handleInputBlur(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let error = { ...this.state.error };
        error = this.setErrorField(error, name, false, '');
        if (error[name].required && (value === '' || value === 0)) {
            error = this.setErrorField(error, name, true, 'Campo obligatirio');
        }
        this.setState({ error })
    }

    setErrorField(error, name, status, message) {
        error[name].error = status;
        error[name].text = message;
        return error;
    }

    agregarDescripciones = () => () => {
        if (this.state.descripcion) {
            let plan = { ...this.state.plan }
            plan.descripcionesAdicionales.push(this.state.descripcion)
            this.setState({ descripcion: '', plan })
        } else {
            toast.info('No se puede agregar un descripción vacia')
        }

    }

    eliminar = (index) => () => {
        let plan = { ...this.state.plan }
        plan.descripcionesAdicionales.splice(index, 1)
        this.setState({ plan })
    }

    guardar = () => () => {
        if (this.state.idPlanEditable == '0') {
            this.insertar()

        } else {
            this.actualizar()
        }

    }

    insertar() {
        planesService.addRegister(this.state.plan, () => {
            toast.info('Registro guardado correctamente')
            this.emptyData()
            this.getPlanes()


        })
    }
    actualizar() {
        planesService.updateRegister(this.state.idPlanEditable, this.state.plan, () => {
            toast.info('Registro actualizado correctamente')
            this.emptyData();
            this.getPlanes();

        })
    }

    editarPlan = (row) => () => {
        this.setState({ idPlanEditable: row.id, plan: row, expanded: true })
        this.getBloques();

    }

    eliminarPlan = () => () => {
        planesService.deleteRegister(this.state.idPlanEditable, () => {
            toast.info('Registro eliminado correctamente')
            this.setState({ isModalShowDelete: false, idPlanEditable: '0' })
            this.getPlanes();
        })

    }
    openEliminarPlan = (row) => () => {
        this.setState({ isModalShowDelete: true, idPlanEditable: row.id })
    }

    closeModal = () => () => {
        this.setState({ isModalShowDelete: false, idPlanEditable: '0' })
    }


    expandedAccordion = () => () => {
        this.setState({ expanded: !this.state.expanded });
        if (!this.state.expanded) {
            this.getBloques();
        }
    }

    getBloques() {
        bloquesService.getRegisters().get().then(res => {
            let bloques = []
            res.forEach(item => {
                let bloque = item.data()
                bloque.id = item.id
                bloques.push(bloque)
            })

            this.setState({ listadoBloques: bloques })
        })
    }

    emptyData() {
        let plan = {
            nombre: '',
            bloque: '',
            meses: '',
            gratuidad: '',
            cantidadProductos: '',
            precio: '',
            descripcionesAdicionales: [],
            orden: ''

        }
        this.setState({ idPlanEditable: '0', expanded: false, plan })
    }


    render() {
        return (
            <Container>
                <Card style={{ marginTop: 50 }}>
                    <CardContent>

                        <div>
                            <Accordion expanded={this.state.expanded} onChange={this.expandedAccordion()}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Agregar Planes</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Grid container spacing={2}>



                                        <Grid item xs={12} md={6} sm={6}>
                                            <TextField
                                                name='orden'
                                                label='Numero'
                                                value={this.state.plan.orden ? this.state.plan.orden : ""}
                                                error={this.state.error.orden.error}
                                                helperText={this.state.error.orden.error ? this.state.error.orden.text : ''}
                                                fullWidth
                                                margin="normal"
                                                required={this.state.error.orden.required}
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputBlur}
                                            />
                                            <TextField
                                                name='nombre'
                                                label='Nombre'
                                                value={this.state.plan.nombre ? this.state.plan.nombre : ""}
                                                error={this.state.error.nombre.error}
                                                helperText={this.state.error.nombre.error ? this.state.error.nombre.text : ''}
                                                fullWidth
                                                margin="normal"
                                                required={this.state.error.nombre.required}
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputBlur}
                                            />

                                            <TextField
                                                select
                                                name='bloque'
                                                label='Bloque'
                                                value={this.state.plan.bloque ? this.state.plan.bloque : ""}
                                                error={this.state.error.bloque.error}
                                                helperText={this.state.error.bloque.error ? this.state.error.bloque.text : ''}
                                                required={this.state.error.bloque.required}
                                                fullWidth
                                                margin="normal"
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputBlur}

                                            >
                                                {this.state.listadoBloques.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.nombre}
                                                    </MenuItem>

                                                ))}
                                            </TextField>

                                            <TextField
                                                select
                                                name='meses'
                                                label='Meses'
                                                value={this.state.plan.meses ? this.state.plan.meses : ""}
                                                error={this.state.error.meses.error}
                                                helperText={this.state.error.meses.error ? this.state.error.meses.text : ''}
                                                required={this.state.error.meses.required}
                                                fullWidth
                                                margin="normal"
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputBlur}

                                            >
                                                {this.state.listadoMeses.map((option) => (
                                                    <MenuItem key={option + 'M'} value={option}>
                                                        {option}
                                                    </MenuItem>

                                                ))}
                                            </TextField>

                                            <TextField
                                                select
                                                name='gratuidad'
                                                label='Gratuidad'
                                                value={this.state.plan.gratuidad ? this.state.plan.gratuidad : ""}
                                                error={this.state.error.gratuidad.error}
                                                helperText={this.state.error.gratuidad.error ? this.state.error.gratuidad.text : ''}
                                                required={this.state.error.gratuidad.required}
                                                fullWidth
                                                margin="normal"
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputBlur}

                                            >
                                                {this.state.listadoGratuidad.map((option) => (
                                                    <MenuItem key={option + 'G'} value={option}>
                                                        {option}
                                                    </MenuItem>

                                                ))}
                                            </TextField>
                                            <TextField
                                                name='cantidadProductos'
                                                label='Cantidad de Productos'
                                                value={this.state.plan.cantidadProductos ? this.state.plan.cantidadProductos : ""}
                                                error={this.state.error.cantidadProductos.error}
                                                helperText={this.state.error.cantidadProductos.error ? this.state.error.cantidadProductos.text : ''}
                                                fullWidth
                                                margin="normal"
                                                required={this.state.error.cantidadProductos.required}
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputBlur}
                                            />

                                            <TextField
                                                name='precio'
                                                label='Precio'
                                                value={this.state.plan.precio ? this.state.plan.precio : ""}
                                                error={this.state.error.precio.error}
                                                helperText={this.state.error.precio.error ? this.state.error.precio.text : ''}
                                                fullWidth
                                                margin="normal"
                                                required={this.state.error.precio.required}
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputBlur}
                                            />




                                        </Grid>

                                        <Grid item xs={12} md={6} sm={6}>

                                            <Grid container spacing={2}>

                                                <Grid item xs={10} md={10} sm={10}>
                                                    <TextField
                                                        name='descripcion'
                                                        label='Beneficios'
                                                        value={this.state.descripcion ? this.state.descripcion : ""}
                                                        error={this.state.error.descripcion.error}
                                                        helperText={this.state.error.descripcion.error ? this.state.error.descripcion.text : ''}
                                                        fullWidth
                                                        margin="normal"
                                                        required={this.state.error.descripcion.required}
                                                        onChange={this.handleInputChange}
                                                        onBlur={this.handleInputBlur}
                                                    />

                                                </Grid>

                                                <Grid item xs={2} md={2} sm={2} style={{ display: 'flex', alignItems: 'center' }}>

                                                    <Fab color="primary" aria-label="add" onClick={this.agregarDescripciones()}>
                                                        <AddIcon />
                                                    </Fab>
                                                </Grid>


                                            </Grid>

                                            <div>

                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                    {this.state.plan.descripcionesAdicionales.map((value, indexD) => {

                                                        return (
                                                            <ListItem
                                                                key={indexD}
                                                                secondaryAction={
                                                                    <IconButton style={{ color: 'red' }} edge="end" aria-label="comments" onClick={this.eliminar(indexD)}>
                                                                        <DeleteOutlineOutlinedIcon />
                                                                    </IconButton>
                                                                }
                                                                disablePadding
                                                            >
                                                                <ListItemButton role={undefined} dense>

                                                                    <ListItemText primary={value} />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                            </div>

                                        </Grid>

                                    </Grid>
                                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                                        <ColorButton style={{ width: '20%' }}
                                            variant="contained"
                                            disabled={this.state.blockButton}
                                            onClick={this.guardar()}

                                        >Guardar</ColorButton>

                                    </div>

                                </AccordionDetails>
                            </Accordion>

                        </div>

                        <div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Nombre</StyledTableCell>
                                            <StyledTableCell align="right">Bloque</StyledTableCell>
                                            <StyledTableCell align="right">Meses</StyledTableCell>
                                            <StyledTableCell align="right">Gratuidad</StyledTableCell>
                                            <StyledTableCell align="right">Cantidad Productos</StyledTableCell>
                                            <StyledTableCell align="right">Precio</StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {this.state.planes.map((row, index) => (
                                            <StyledTableRow key={'US' + index}>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.nombre}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{row.nombreBloque}</StyledTableCell>
                                                <StyledTableCell align="right">{row.meses}</StyledTableCell>
                                                <StyledTableCell align="right">{row.gratuidad}</StyledTableCell>
                                                <StyledTableCell align="right">{row.cantidadProductos}</StyledTableCell>
                                                <StyledTableCell align="right">{row.precio}</StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <div>
                                                        <IconButton style={{ color: 'blue' }} onClick={this.editarPlan(row)}>
                                                            <EditOutlinedIcon />
                                                        </IconButton>
                                                        <IconButton style={{ color: 'red' }} onClick={this.openEliminarPlan(row)}>
                                                            <DeleteOutlineOutlinedIcon />
                                                        </IconButton>



                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                    </CardContent>

                </Card>
                <DeleteModal
                    title='¿Seguro de eliminar este plan?'
                    onAccept={this.eliminarPlan()}
                    onCancel={this.closeModal()}
                    isModalShow={this.state.isModalShowDelete}

                />

            </Container >

        )
    }





}
