import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PublicidadCard from "../../components/PublicidadCard";
import ModalPublicidad from "../../screens/pages/ModalPublicidad";
import { publicidadService } from "../../services/publicidad.service";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 2000, min: 800 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






class Publicidades extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            publicidades: [],
            isModalPublicidad: false,
            itemPublicidad: "",
        }
    }


    componentDidMount() {
        this.getPublicidades()

    }

    viewModal = (value, item) => () => {
        this.setState({ isModalPublicidad: value, itemPublicidad: item });
    };

    getPublicidades() {
        publicidadService.getPublicidades().get().then((snapshot) => {
            let publicidades = [];
            snapshot.forEach((item) => {
                let publicidad = item.data();
                publicidad.id = item.id;
                let fecha = new Date(publicidad.fecha);
                fecha.setDate(fecha.getDate() + parseInt(publicidad.dias));
                let fechaActual = new Date();


                if (fecha >= fechaActual) {
                    publicidades.push(publicidad);
                }
            });
            this.setState({ publicidades });
        });
    }


    render() {
        if (this.state.publicidades.length > 0) {
            return (
                <div style={{ marginTop: 5 }} className='vistaCelular'>
                    <Carousel
                        responsive={responsive}
                        autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={1000}
                        transitionDuration={1090}
                        centerMode={true}
                        infinite={true}
                    >
                        {this.state.publicidades &&
                            this.state.publicidades.map((item, index) => (
                                <div
                                    style={{ marginRight: 10, marginBottom: 5 }}
                                    key={index + "publi"}
                                >
                                    <PublicidadCard
                                        item={item}
                                        verDetalle={this.viewModal(true, item)}
                                    />
                                </div>
                            ))}
                    </Carousel>

                    {this.state.isModalPublicidad ? (
                        <ModalPublicidad
                            onCancel={this.viewModal(false, "")}
                            data={this.state.itemPublicidad}
                            isModalShow={this.state.isModalPublicidad}
                        />
                    ) : null}
                </div>
            )

        } else{
            return (<div className='espacio-publicitario'>ESPACIO PUBLICITARIO</div>)
        }

    }
}
export default Publicidades;