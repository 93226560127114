
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container, CardContent, Grid, Card,  Paper, Box,
  Table, TableBody, TableContainer, TableHead, TableRow, TableCell, tableCellClasses, 
  Button, IconButton, MenuItem, TextField, FormControl, InputLabel, Select, Chip, OutlinedInput,
  Accordion, AccordionSummary, AccordionDetails, Typography
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { bloquesService } from '../../services/bloques.service';
import { categoriasService } from '../../services/categorias.service';
import DeleteModal from '../../components/DeleteModal';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    '&:hover': {
        backgroundColor: grey[700],
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(categoria, categorias) {
  let count_style = 0;
  categorias.forEach(item => {
    if(item.id == categoria.id){
      count_style++
    }
  })

  return {
    fontWeight:
    count_style <= 0
      ? 'normal'
      : 'bold',
  };
}

export default class Bloques extends React.Component {

    constructor() {
        super()
        this.state = {
            showEditor: false,
            showTabla: true,
            bloque: {
                orden: '',
                nombre: '',
                categorias: []

            },

            error: {
                nombre: { error: false, text: '', required: true },
                orden: {error: false, text: '', required: true}
            },
            
            bloques: [],
            idBloqueEditable: '0',
            isModalShowDelete: false,
            categorias: [],
            expanded: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }
    
    componentDidMount(){
      this.getBloques();
    }

    getBloques(){
      bloquesService.getRegistersOrden().get().then(res => {
        let bloques = []
        res.forEach(item => {
          let bloque = item.data();
          bloque.id = item.id
          bloque.nombresCategorias = '';
          bloque.categorias.forEach(categoria => {
            bloque.nombresCategorias += categoria.nombre+', ';
          })
          bloque.nombresCategorias = bloque.nombresCategorias.slice(0, -2);
          bloques.push(bloque);
        })
        this.setState({bloques});
      })
    }

    getCategorias(){
      categoriasService.getCategoriasNivel1(categorias => {
        this.setState({categorias})
      })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let bloque = { ...this.state.bloque }
        bloque[name] = value
        this.setState({ bloque })
    }


    handleInputBlur(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let error = { ...this.state.error };
        error = this.setErrorField(error, name, false, '');
        if (error[name].required && (value === '' || value === 0)) {
            error = this.setErrorField(error, name, true, 'Campo obligatirio');
        }
        this.setState({ error })
    }

    handleSelectChange (event) {
        const target = event.target;
        const value = target.value;
        let valueSelect = typeof value === 'string' ? value.split(',') : value;
        let bloque = { ...this.state.bloque }

        let items = []
        valueSelect.forEach(item => {
          items[item.id] = 0;
        })

        valueSelect.forEach(item => {
          items[item.id]++;
        })
        let keys = Object.keys(items)
        let categorias = [];
        valueSelect.forEach(item => {
          if(items[item.id] == 1){
            categorias.push(item)
          }
        })

        bloque.categorias = categorias
        this.setState({bloque});
    };

    setErrorField(error, name, status, message) {
        error[name].error = status;
        error[name].text = message;
        return error;
    }

    guardar = () => () => {
      delete this.state.bloque.nombresCategorias
      if (this.state.idBloqueEditable == '0') {
          this.insertar()
      } else {
          this.actualizar()
      }
    }

    insertar() {
        bloquesService.addRegister(this.state.bloque, () => {
          toast.info('Registro guardado correctamente')
          this.emptyData();
          this.getBloques();
        })
    }
    actualizar() {
        bloquesService.updateRegister(this.state.idBloqueEditable, this.state.bloque, () => {
          toast.info('Registro actualizado correctamente')
          this.emptyData();
          this.getBloques();
        })
    }

    emptyData(){
      let bloque = {
        orden: '',
        nombre: '',
        categorias: []
      }
      this.setState({idBloqueEditable: '0', expanded: false, bloque})
    }

    editarBloque = (row) => () => {
      this.setState({idBloqueEditable: row.id, bloque: row, expanded: true })
      this.getCategorias();
    }

    eliminarBloque = () => () => {
      bloquesService.deleteRegister(this.state.idBloqueEditable, ()=>{
        toast.info('Registro eliminado correctamente')
        this.setState({isModalShowDelete: false, idBloqueEditable: '0'})
        this.getBloques();
      })
    }

    openEliminarBloque=(row)=>()=>{
        this.setState({isModalShowDelete: true, idBloqueEditable: row.id})
    }

    closeModal = () => () => {
        this.setState({ isModalShowDelete: false, idPlanEditable: ''})
    }

    expandedAccordion = () => () => {
      this.setState({expanded: !this.state.expanded});
      if(!this.state.expanded){
        this.getCategorias();
      }
    }

    render() {
        return (
            <Container>
                <Card style={{ marginTop: 50 }}>
                    <CardContent>
                        <div>
                            <Accordion expanded={this.state.expanded} onChange={this.expandedAccordion()}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Agregar Bloque</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>

                                    <Grid item xs={12} md={6} sm={6}>
                                            <TextField
                                                name='orden'
                                                label='Numero'
                                                value={this.state.bloque.orden ? this.state.bloque.orden : ""}
                                                error={this.state.error.orden.error}
                                                helperText={this.state.error.orden.error ? this.state.error.orden.text : ''}
                                                fullWidth
                                                margin="normal"
                                                required={this.state.error.orden.required}
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} sm={6}>
                                            <TextField
                                                name='nombre'
                                                label='Nombre'
                                                value={this.state.bloque.nombre ? this.state.bloque.nombre : ""}
                                                error={this.state.error.nombre.error}
                                                helperText={this.state.error.nombre.error ? this.state.error.nombre.text : ''}
                                                fullWidth
                                                margin="normal"
                                                required={this.state.error.nombre.required}
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} sm={6}>
                                            <FormControl fullWidth margin="normal">
                                                <InputLabel id="demo-multiple-chip-label">Categorias</InputLabel>
                                                <Select
                                                    labelId="categorias"
                                                    id="categorias"
                                                    name="categorias"
                                                    multiple
                                                    value={this.state.bloque.categorias ? this.state.bloque.categorias : ""}
                                                    onChange={this.handleSelectChange}
                                                    input={<OutlinedInput id="select-multiple-chip" label="Categorias" />}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value, indexS) => {
                                                            return <Chip key={'S'+indexS} label={value.nombre} />
                                                        })}
                                                        </Box>
                                                    )}
                                                    MenuProps={MenuProps}
                                                >
                                                    {this.state.categorias.map((categoria, indexO) => (
                                                        <MenuItem
                                                            key={'O'+indexO}
                                                            value={categoria}
                                                            style={getStyles(categoria, this.state.bloque.categorias)}
                                                        >
                                                            {categoria.nombre}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                          </FormControl>
                                        </Grid>
                                    </Grid>
                                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                                        <ColorButton style={{ width: '20%' }}
                                            variant="contained"
                                            disabled={this.state.blockButton}
                                            onClick={this.guardar()}
                                        >Guardar</ColorButton>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Nombre</StyledTableCell>
                                            <StyledTableCell align="right">Categorias</StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {this.state.bloques.map((row, index) => (
                                            <StyledTableRow key={'US' + index}>
                                                <StyledTableCell component="th" scope="row">{row.nombre}</StyledTableCell>
                                                <StyledTableCell align="right">{row.nombresCategorias}</StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <div>
                                                        <IconButton style={{ color: 'blue' }} onClick={this.editarBloque(row)}>
                                                            <EditOutlinedIcon />
                                                        </IconButton>
                                                        <IconButton style={{ color: 'red' }} onClick={this.openEliminarBloque(row)}>
                                                            <DeleteOutlineOutlinedIcon />
                                                        </IconButton>
                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                    </CardContent>

                </Card>
                <DeleteModal
                    title='¿Seguro de eliminar este plan?'
                    onAccept={this.eliminarBloque()}
                    onCancel={this.closeModal()}
                    isModalShow={this.state.isModalShowDelete}

                />

            </Container >

        )
    }





}
