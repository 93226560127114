
export default class Helper {

    constructor() {

    }

    dimensionWindow() {
        let tam = { width: 0, height: 0 };
        if (typeof window.innerWidth !== 'undefined') {
            tam = { width: window.innerWidth, height: window.innerHeight };
        }
        else if (typeof document.documentElement !== 'undefined'
            && typeof document.documentElement.clientWidth !==
            'undefined' && document.documentElement.clientWidth !== 0) {
            tam = {
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight
            };
        }
        else {
            tam = {
                width: document.getElementsByTagName('body')[0].clientWidth,
                height: document.getElementsByTagName('body')[0].clientHeight
            };
        }
        return tam;
    }

    generateDate(dateStr, withHour) {
        let date = new Date();
        if(dateStr){
            date = new Date(dateStr);
        }
        let day = this.addCero(date.getDate());
        let month = this.addCero(date.getMonth() + 1);
        let hour = this.addCero(date.getHours());
        let minute = this.addCero(date.getMinutes());
        let hourComplete = '';
        if(withHour){
            hourComplete = + ' ' + hour + ':' + minute;
        }
        return date.getFullYear() + '-' + month + '-' + day + hourComplete;
    }

    addCero(value) {
        if (parseInt(value) < 10) {
            value = '0' + value;
        }
        return value;
    }
    generarArrayNombre(nombre, separador) {
        let articulos = ['el', 'los', 'las', 'la', 'un', 'una', 'unos', 'unas', 'de', 'en', 'y', 'o','/']
        let nombreMinusculas = nombre.toLowerCase()
        let nombreArray = nombreMinusculas.split(separador)

        let nombreResultado = []

        nombreArray.forEach(item => {
            if (!articulos.includes(item)) {
                if(item){
                    item = this.removeAccents(item);
                    nombreResultado.push(this.replaceAll(item , '/', ''))
                }
            }
        })
        return nombreResultado
    }

    removeAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    replaceAll(text, busca, reemplaza) {
        while (text.toString().indexOf(busca) !== -1)
            text = text.toString().replace(busca, reemplaza);
        return text;
    }

    arrayUnique(mergedArray) {
        let set = new Set();
        let unionArray = mergedArray.filter(item => {
            if (!set.has(item.id)) {
                set.add(item.id);
                return true;
            }
            return false;
        }, set);
        return unionArray
    }

    getImagenResize(urlImage, dimension, original){
        let pathImagen = "https://storage.googleapis.com/tienda-54320.appspot.com/";
        if(original){
            return pathImagen + urlImage;
        }
        let arrayUrl = urlImage.split('/');
        let nameImage = arrayUrl[1].substring(0, (arrayUrl[1].length - 4));
        let extension = arrayUrl[1].substring((arrayUrl[1].length - 4), arrayUrl[1].length); 
        return pathImagen + arrayUrl[0] + '/thumb/' + nameImage + '_'+ dimension + extension;
    }

    getNamesFieldsProducts(){
        let data = {
            marca: 'Marca',
            estado_producto: 'Estado',
            origen: 'Origen',
            codigo: 'Codigo',
            tecnica: 'Técnica',
            medidas: 'Medidas',
            incluye_marco: 'Incluye marco',
            talla: 'Talla',
            colores: 'Colores',
            material: 'Material',
            genero: 'Género',
            modelo: 'Modelo',
            linea: 'Línea',
            procesador: 'Procesador',
            tamano_pantalla: 'Tamaño de pantalla',
            voltaje: 'Voltaje',
            dimensiones: 'Dimensiones',
            fabricante: 'Fabricante',
            peso: 'Peso',
            autor: 'Autor',
            anio: 'Año',
            idioma: 'Idioma',
            tipo_comida: 'Tipo de comida',
            servicio_catering: 'Servicio catering',
            nombre_empresa: 'Nombre de la empresa',
            seguro_de: 'Seguros de',
            costos_desde: 'Coste desde',
            nombre_servicio: 'Nombre del profesional',
            formacion: 'Formación',
            experiencia: 'Experiencia',
            atencion: 'Atención a nivel',
            incluye_instalacion: 'Incluye Instalación',
            tipo: 'Tipo',
            cilindraje: 'Cilindraje',
            transmision: 'Transmisión',
            traccion: 'Tracción',
            placa: 'Placa',
            recorrido: 'Recorrido',
            combustible: 'Combustible',
            area_construccion: 'Área de construcción',
            areas_verdes: 'Áreas verdes',
            habitaciones: 'Habitaciones',
            banos: 'Baños',
            garaje: 'Garaje',
            modalidad: 'Modalidad',
            forma_pago: 'Formas de pago',
            costo_envio: 'Costo de envío',
            tiempo_entrega: 'Tiempo de entrega'
        }
        return data;
    }

}