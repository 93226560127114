import React from 'react';

import {
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent, Table, TableBody, TableContainer, TableHead, TableRow, Paper, TableCell, tableCellClasses

} from '@mui/material';
import { toast } from 'react-toastify';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import Preloader from '../../components/Preloader';
import DeleteModal from '../../components/DeleteModal';
import { userService } from '../../services/user.service';
import { productosService } from '../../services/productos.service';
import Helper from '../../utils/Helper';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    '&:hover': {
        backgroundColor: grey[700],
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default class ModalSubscripciones extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loader: false,
            usuario: '',
            isModalShowConfirm: false,
            plan: '', 
            indexPlan: 0
        }

        this.helper = new Helper();
    }

    async componentDidMount() {
        let usuario = this.props.usuario
        if (usuario.planes) {
            this.setState({ loader: true, usuario })
        }
    }

    activarSubscripcion = () => () => {
        let plan = this.state.plan;
        let indexPlan = this.state.indexPlan;
        let fechaSubscripcion = new Date()
        let fechaCaducidad = new Date()

        if(plan.num_reactivaciones){
            if(plan.estado == 'reactivar'){
                plan.num_reactivaciones = parseInt(plan.num_reactivaciones) + 1;    
            }
        }else{
            plan.num_reactivaciones = 0;
        }

        let gratuidad = 0
        if (plan.plan.gratuidad) {
            gratuidad = parseInt(plan.plan.gratuidad)
        }
        let duracion = parseInt(plan.plan.meses) + gratuidad
        let meses = parseInt(duracion);
        fechaCaducidad.setMonth(fechaCaducidad.getMonth() + meses)
        plan.fechaSubscripcion = fechaSubscripcion.toDateString()
        plan.fechaCaducidad = fechaCaducidad.toDateString()
        plan.fechaEliminacion = ''
        plan.estado = 'activo'

        let planes = this.state.usuario.planes
        planes[indexPlan] = plan
        let planesVendedor = []

        for (let index = 0; index < planes.length; index++) {
            let planVendedor = { ...planes[index] }
            if (planVendedor.fechaCaducidad) {
                planVendedor.fecha = new Date(planVendedor.fechaCaducidad)
                planesVendedor.push(planVendedor)
            }
        }

        planesVendedor.sort(function (x, y) {
            if (x.fecha < y.fecha) {
                return -1;
            }

            if (x.fecha > y.fecha) {
                return 1;
            }
            return 0;
        });

        let ultimoPlan = planesVendedor.length - 1
        let data = {
            planes: planes,
            subscripcion: 'activo',
            fechaSubscripcion: fechaSubscripcion.toDateString(),
            fechaCaducidad: planesVendedor[ultimoPlan].fechaCaducidad,
            fechaEliminacion: '',
        }
        userService.updateRegister(this.state.usuario.id, data, () => {
            this.actualizarFechaProductos(planesVendedor, plan.plan.bloque)
        })
    }

    async actualizarFechaProductos(planesVendedor, bloque) {
        let planesBloque = []
        planesVendedor.forEach(planVendedor => {
            if (planVendedor.plan.bloque === bloque) {
                planesBloque.push(planVendedor)
            }
        })
        let ultimoPlan = planesBloque.length - 1
        let productos = await productosService.getProductosPorBloque(bloque)
        if (productos.length > 0) {
            let data = { fechaCaducidad: planesBloque[ultimoPlan].fechaCaducidad }
            productosService.actualizarDataProductos(productos, data, () => {
                this.setState({
                    isModalShowConfirm: false,
                    plan: '', 
                    indexPlan: 0
                })
                toast.info('Usuario actualizado correctamente')
                this.props.onCancel();
            })

        } else {
            this.setState({
                isModalShowConfirm: false,
                plan: '', 
                indexPlan: 0
            })
            toast.info('Usuario actualizado correctamente')
            this.props.onCancel();
        }
    }

    closeModal = () => () => {
        this.setState({ isModalShowConfirm: false})
    }

    openSubscripcion = (plan, indexPlan) => () => {
        this.setState({
            isModalShowConfirm: true,
            plan: plan, 
            indexPlan: indexPlan
        })
    }

    render() {
        const { data, onCancel, isModalShow } = this.props;
        if (!this.state.loader) {
            return (<Preloader />)
        } else {
            return (
                <Dialog
                    open={isModalShow}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        <center><b>Planes</b> </center>
                        {onCancel ? (
                            <IconButton
                                aria-label="close"
                                onClick={onCancel}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{ marginBottom: 50 }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 300 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Plan</StyledTableCell>
                                            <StyledTableCell>Fecha Suscripción</StyledTableCell>
                                            <StyledTableCell>Fecha Caducidad</StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.usuario.planes.map((plan, index) => (
                                            <StyledTableRow key={'PL' + index}>
                                                <StyledTableCell component="th" scope="row">
                                                    {plan.plan.nombre + '- $' + plan.plan.precio}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {plan.fechaSubscripcion && 
                                                        (<span>{this.helper.generateDate(plan.fechaSubscripcion, false)}</span>)
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {plan.fechaCaducidad && 
                                                        (<span>{this.helper.generateDate(plan.fechaCaducidad, false)}</span>)
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    {plan.estado === 'inactivo' &&
                                                        <Button
                                                            variant="contained"
                                                            disabled={this.state.blockButton}
                                                            onClick={this.openSubscripcion(plan, index)}

                                                        >Activar</Button>
                                                    }

                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </DialogContent>
                    <DeleteModal
                        title="Esta seguro de activar este plan?"
                        onAccept={this.activarSubscripcion()}
                        onCancel={this.closeModal()}
                        isModalShow={this.state.isModalShowConfirm}

                    />
                </Dialog>
            )
        }
    }
}