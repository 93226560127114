
const firebaseConfig = {
   apiKey: "AIzaSyAfW99kclJoGmaQ1BkEn3kWohk_9nTT_dM",
   authDomain: "tienda-54320.firebaseapp.com",
   projectId: "tienda-54320",
   storageBucket: "tienda-54320.appspot.com",
   messagingSenderId: "805599529182",
   appId: "1:805599529182:web:fcfb7314ed89b837beda4f",
   measurementId: "G-YZSB4QS8LJ"
 };

/*const firebaseConfig = {
  apiKey: "AIzaSyCAA5Gws7Z8fauUkvzbr8rTbzSdB8_jPzY",
  authDomain: "tienda-desarrollo-20a8a.firebaseapp.com",
  projectId: "tienda-desarrollo-20a8a",
  storageBucket: "tienda-desarrollo-20a8a.appspot.com",
  messagingSenderId: "888186376342",
  appId: "1:888186376342:web:61edab62d866654ec472b7",
  measurementId: "G-XJK2JLMDB2"
};*/



export default firebaseConfig;