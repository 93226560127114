import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'firebase/compat/storage';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Resizer from "react-image-file-resizer";
import {
    Container, CardContent, Grid, Card, Paper, 
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, tableCellClasses,
    Button, IconButton, MenuItem, TextField, 
    Accordion, AccordionSummary, AccordionDetails, Typography
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { publicidadService } from '../../services/publicidad.service';
import DeleteModal from '../../components/DeleteModal';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    '&:hover': {
        backgroundColor: grey[700],
    },
}));

export default class Publicidad extends React.Component {

    constructor() {
        super()
        this.state = {
            publicidad: {
                nombre: '',
                imagenes: [],
                imagenPrincipal: '',
                dias: '',
                fecha: ''
            },

            error: {
                nombre: { error: false, text: '', required: true },
                dias: { error: false, text: '', required: true }
            },

            imageCompany: '',
            imagenFile: '',
            imagenesFile: [],
            imagenesPrevisualizar: [],
            imagenesRespaldo: [],
            expanded: false,
            publicidades: [],
            idPublicidadEditable: '0',
            listadoDias: [30, 60, 90, 120],
            snapshotNext: '',
            snapshotBack: '',

        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.getPublicidades();
    }

    handleImageChangePrincipal = (event) => {
        try {
            Resizer.imageFileResizer(
                event.target.files[0],
                400,
                400,
                "JPEG",
                70,
                0,
                (uri) => {

                    this.setState({ imageCompany: URL.createObjectURL(uri), imagenFile: uri });
                },
                "file",
                200,
                200
            );
        } catch (err) {
            console.error(err);
        }
    }

    showImage() {
        return (<img src={this.state.imageCompany} alt='Publicidad'/>)
    }

    handleImageChange = (event) => {
        var _URL = window.URL || window.webkitURL;
        let image = new Image();
        let component = this;
        image.onload = function () {
            if (image.width >= 400 && image.height >= 400) {
                component.subirImagen(event)
            } else {
                toast.warning('La imagen tener minimo 400 px en ancho o en alto')
            }
        };
        image.src = _URL.createObjectURL(event.target.files[0]);

    }

    subirImagen(event) {
        try {
            Resizer.imageFileResizer(
                event.target.files[0],
                1024,
                1024,
                "JPEG",
                70,
                0,
                (uri) => {
                    if (this.state.imagenesPrevisualizar.length < 4) {
                        let imagenesFile = this.state.imagenesFile
                        imagenesFile.push(uri)
                        let imagenesPrevisualizar = this.state.imagenesPrevisualizar
                        imagenesPrevisualizar.push(URL.createObjectURL(uri))
                        let publicidad = { ...this.state.publicidad }
                        publicidad.imagenes.push('nuevo')
                        this.setState({ imagenesFile, imagenesPrevisualizar, publicidad });
                    } else {
                        toast.warning('El maximo son 4 imagenes')
                    }
                },
                "file",
                400,
                400
            );
        } catch (err) {
            console.error(err);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let publicidad = { ...this.state.publicidad }
        publicidad[name] = value
        this.setState({ publicidad })
    }

    expandedAccordion = () => () => {
        this.setState({ expanded: !this.state.expanded });
    }

    eliminarImagen = (indexi) => () => {
        let imagenesFile = this.state.imagenesFile
        let imagenesPrevisualizar = this.state.imagenesPrevisualizar
        let publicidad = { ...this.state.publicidad }
        if (publicidad.imagenes[indexi] !== 'nuevo') {
            let nameImage = this.state.publicidad.imagenes[indexi]
            firebase.storage().ref(nameImage).delete().then(res => {
                imagenesFile.splice(indexi, 1)
                imagenesPrevisualizar.splice(indexi, 1)
                publicidad.imagenes.splice(indexi, 1)
                this.setState({ imagenesFile, imagenesPrevisualizar, publicidad })
                toast.info('Imagen eliminada correctamente')
            })
        } else {
            imagenesFile.splice(indexi, 1)
            imagenesPrevisualizar.splice(indexi, 1)
            publicidad.imagenes.splice(indexi, 1)
            this.setState({ imagenesFile, imagenesPrevisualizar, publicidad })
            toast.info('Imagen eliminada correctamente')
        }
    }

    saveImagePrincipal(id, publicidad) {
        if (this.state.imagenFile != '0') {
            let nameImage = 'images/' + id + '-principal.png';
            firebase.storage().ref(nameImage).put(this.state.imagenFile)
                .then((snapshot) => {
                    this.actualizarImagenPrincipal(id, nameImage, publicidad)
                });
        } else {
            let imagenes = this.state.imagenesFile
            this.saveImage(publicidad, id, imagenes, 0)

        }
    }

    saveImage(publicidad, id_imagen, imagenes, index) {
        if (imagenes[index] != '0') {
            let secuencial = this.getSecuencialImagen(publicidad)
            let nameImage = 'images/' + id_imagen + '-' + secuencial + '.jpg';
            firebase.storage().ref(nameImage).put(imagenes[index])
                .then((snapshot) => {
                    publicidad.imagenes[index] = nameImage
                    if (index == (imagenes.length - 1)) {
                        this.actualizarNombreDeImagenes(publicidad, id_imagen)
                    } else {
                        let indexSiguiente = index + 1
                        this.saveImage(publicidad, id_imagen, imagenes, indexSiguiente)
                    }
                });
        } else {
            if (index == (imagenes.length - 1)) {
                this.actualizarNombreDeImagenes(publicidad, id_imagen)
            } else {
                let indexSiguiente = index + 1
                this.saveImage(publicidad, id_imagen, imagenes, indexSiguiente)
            }
        }
    }

    actualizarImagenPrincipal(id, nombre, publicidad) {
        let data = { imagenPrincipal: nombre }
        let imagenes = this.state.imagenesFile
        publicidadService.updateRegister(id, data, () => {
            this.saveImage(publicidad, id, imagenes, 0)
        })
    }

    actualizarNombreDeImagenes(publicidad, id) {
        let data = { imagenes: publicidad.imagenes }
        publicidadService.updateRegister(id, data, () => {
            toast.info('Producto registrado correctamente')
            this.emptyData();
            this.getPublicidades()
        })
    }

    emptyData() {
        let publicidad = {
            nombre: '',
            imagenes: [],
            imagenPrincipal: ''
        }
        this.setState({
            idPublicidadEditable: '0', expanded: false, publicidad, imageCompany: '',
            imagenFile: '',
            imagenesFile: [],
            imagenesPrevisualizar: [],
            imagenesRespaldo: [],
        })
    }

    getPublicidades() {
        publicidadService.getPublicidades().get().then(snapshot => {
            let publicidades = []
            snapshot.forEach(item => {
                let publicidad = item.data()
                publicidad.id = item.id
                publicidades.push(publicidad)
            })
            this.setState({ publicidades })
        })
    }

    getSecuencialImagen(publicidad) {
        let secuenciales = []
        let resultado = 0
        if (publicidad.imagenes.length > 0) {
            publicidad.imagenes.forEach(item => {
                if (item !== 'nuevo') {
                    let arrayNombre = item.split('-')
                    secuenciales.push(parseInt(arrayNombre[1]))
                } else {
                    secuenciales.push(0)
                }
            })
            resultado = Math.max(...secuenciales) + 1
        }

        return resultado
    }

    guardar = () => () => {
        let publicidad = {...this.state.publicidad}
        publicidad.fecha = this.obtenerFecha()
        if (this.state.idPublicidadEditable == '0') {
            this.insertar(publicidad)
        } else {
            this.actualizar(publicidad)
        }
    }

    insertar(publicidad) {
        publicidadService.addRegister(publicidad, (doc) => {
            this.saveImagePrincipal(doc, publicidad)
        })
    }

    actualizar(publicidad) {
        publicidadService.updateRegister(this.state.idPublicidadEditable, publicidad, () => {
            this.saveImagePrincipal(this.state.idPublicidadEditable, publicidad)
        })
    }

    editarPublicidad = (row) => () => {
        this.setState({ idPublicidadEditable: row.id, publicidad: row, expanded: true })
        this.getImagesStorage(0, row)
        this.getImagesStoragePrincipal(row.imagenPrincipal)
    }

    openEliminarPublicidad = (row) => () => {
        this.setState({ isModalShowDelete: true, idPublicidadEditable: row.id })
    }

    eliminarPublicidad = () => () => {
        publicidadService.deleteRegister(this.state.idPublicidadEditable, () => {
            toast.info('Registro eliminado correctamente')
            this.setState({ isModalShowDelete: false, idPublicidadEditable: '0' })
            this.getPublicidades();
        })
    }

    getImagesStorage(index, publicidad) {
        let nameImage = publicidad.imagenes[index];
        firebase.storage().ref(nameImage).getDownloadURL()
            .then((url) => {
                let imagenesPrevisualizar = this.state.imagenesPrevisualizar
                let imagenesFile = this.state.imagenesFile
                imagenesPrevisualizar.push(url);
                imagenesFile.push('0')
                this.setState({ publicidad, imagenesPrevisualizar, imagenesFile });

                if (index == (publicidad.imagenes.length - 1)) {
                    this.setState({ loader: true });
                } else {
                    let indexSiguiente = index + 1;
                    this.getImagesStorage(indexSiguiente, publicidad)
                }
            })
            .catch(error => {
                console.error(error);
                if (index == (publicidad.imagenes.length - 1)) {
                    this.setState({ loader: true });
                } else {
                    let indexSiguiente = index + 1;
                    this.getImagesStorage(indexSiguiente, publicidad)
                }

            })
    }

    getImagesStoragePrincipal(nameImage) {
        firebase.storage().ref(nameImage).getDownloadURL()
            .then((url) => {
                this.setState({ imageCompany: url, imagenFile: '0' });
            })
    }

    closeModal = () => () => {
        this.setState({ isModalShowDelete: false, idPublicidadEditable: '0' })
    }

    obtenerFecha (){
        let fecha = new Date()
        return fecha.toDateString();
    }

    getPublicidadesPreview = () => () => {
        this.setState({ publicidades: [] })
        this.indexPosition--;
        if (this.indexPosition <= 0) {
            this.setState({ blockBack: true, blockNext: false })
        }
        publicidadService.getBackPublicidad(this.state.snapshotBack)
            .get().then(snapshot => {
                if (snapshot.docs.length > 0) {
                    let snapshotBack = snapshot.docs[0];
                    let snapshotNext = snapshot.docs[snapshot.docs.length - 1];
                    this.setState({ snapshotBack, snapshotNext })
                    this.generarPublicidades(snapshot)
                } else {
                    this.getPublicidades();
                }
            });
    }

    getPublicidadesNext = () => () => {
        this.setState({ publicidades: [] })
        this.indexPosition++;
        if (this.indexPosition <= 0) {
            this.setState({ blockBack: true, blockNext: false })
        }
        publicidadService.getNextPublicidad(this.state.snapshotNext)
            .get().then(snapshot => {
                if (snapshot.docs.length > 0) {
                    let snapshotBack = snapshot.docs[0];
                    let snapshotNext = snapshot.docs[snapshot.docs.length - 1];
                    this.setState({ snapshotBack, snapshotNext })
                    this.generarPublicidades(snapshot)
                } else {
                    this.getPublicidades();
                }
            });
    }

    generarPublicidades(snapshot) {
        let publicidades = [];
        snapshot.forEach((doc, key) => {
            let item = doc.data()
            item.id = doc.id
            publicidades.push(item);
        })
        this.setState({ publicidades })
    }

    render() {
        return (
            <Container>
                <Card style ={{marginBlockEnd: 30}}>
                    <CardContent>
                        <Accordion expanded={this.state.expanded} onChange={this.expandedAccordion()}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Agregar Publicidad</Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} md={6} sm={6} >
                                        <h4>Agrega imagen Principal de la Compañia</h4>
                                        <div>
                                            <label htmlFor="contained-button">
                                                <input accept="image/*" id="contained-button" multiple type="file" hidden onChange={(event) => this.handleImageChangePrincipal(event)} />
                                                <Button variant='contained' aria-label="Agregar imager" component="span">
                                                    Agregar imagen
                                                </Button>
                                            </label>
                                        </div>
                                        <div style={{ marginTop: 8 }} >
                                            {this.showImage()}
                                        </div>

                                        <TextField
                                            name='nombre'
                                            label='Nombre de la Compañia'
                                            value={this.state.publicidad.nombre ? this.state.publicidad.nombre : ""}
                                            error={this.state.error.nombre.error}
                                            helperText={this.state.error.nombre.error ? this.state.error.nombre.text : ''}
                                            fullWidth
                                            margin="normal"
                                            required={this.state.error.nombre.required}
                                            onChange={this.handleInputChange}
                                        />

                                        <TextField
                                            select
                                            name='dias'
                                            label='Dias'
                                            value={this.state.publicidad.dias ? this.state.publicidad.dias : ""}
                                            error={this.state.error.dias.error}
                                            helperText={this.state.error.dias.error ? this.state.error.dias.text : ''}
                                            required={this.state.error.dias.required}
                                            fullWidth
                                            margin="normal"
                                            onChange={this.handleInputChange}
                                            onBlur={this.handleInputBlur}

                                        >
                                            {this.state.listadoDias.map((option) => (
                                                <MenuItem key={option + 'D'} value={option}>
                                                    {option}
                                                </MenuItem>

                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} md={6} sm={6}>
                                        <h3>Agrega Máximo 4 imagenes</h3>
                                        <div>
                                            <label htmlFor="contained-button-file">
                                                <input accept="image/*" id="contained-button-file" multiple type="file" hidden onChange={(event) => this.handleImageChange(event)} />
                                                <Button variant='contained' aria-label="Agregar imagenes" component="span">
                                                    Agregar imagenes
                                                </Button>
                                            </label>
                                        </div>
                                        {this.state.imagenesPrevisualizar.length > 0 &&
                                            <div>
                                                {this.state.imagenesPrevisualizar.map((item, indexi) => (
                                                    <div key={indexi + 'IP'} style={{ marginTop: 20 }}>
                                                        <Grid container>
                                                            <Grid item xs={11} md={11}>
                                                                <img src={item} width={150} alt='Imagen publicidad' />
                                                            </Grid>
                                                            <Grid item xs={1} md={1}>
                                                                <IconButton style={{ color: 'red' }} onClick={this.eliminarImagen(indexi)}>
                                                                    <DeleteOutlineOutlinedIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </Grid>
                                </Grid>

                                <div>
                                    <ColorButton style={{ width: '10%' }}
                                        variant="contained"
                                        onClick={this.guardar()}

                                    >Guardar</ColorButton>
                                </div>

                            </AccordionDetails>
                        </Accordion>
                        <div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align='center'>Compañía</StyledTableCell>
                                            <StyledTableCell align="right">Fecha</StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.publicidades.map((row, index) => (
                                            <StyledTableRow key={'US' + index}>
                                                <StyledTableCell component="th" scope="row" align="center">{row.nombre}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row" align="right">{row.dias}</StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <div>
                                                        <IconButton style={{ color: 'blue' }} onClick={this.editarPublicidad(row)}>
                                                            <EditOutlinedIcon />
                                                        </IconButton>
                                                        <IconButton style={{ color: 'red' }} onClick={this.openEliminarPublicidad(row)}>
                                                            <DeleteOutlineOutlinedIcon />
                                                        </IconButton>
                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </CardContent>
                </Card>
                <DeleteModal
                    title='¿Seguro de eliminar esta publicidad?'
                    onAccept={this.eliminarPublicidad()}
                    onCancel={this.closeModal()}
                    isModalShow={this.state.isModalShowDelete}

                />
            </Container>
        )
    }
}