import React from 'react';
import "../../App.css";
import { productosService } from "../../services/productos.service";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CourseCard from "../../components/CourseCard";
import { userService } from "../../services/user.service";



const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 2000, min: 800 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

class ProductosInicio extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      productos: [],


    }
    
  }


  componentDidMount() {
    this.getVendedoresBloqueados();

  }

  getVendedoresBloqueados() {
      userService.getUsuariosBloqueados((usuarios) => {
      this.getProductos(usuarios);
    });
  }
  getProductos(usuarios) {
    productosService.getProductosPorVistas(usuarios, (productos) => {
      this.setState({ productos });
    });
  }

  redirect = (page) => () => {
    this.props.navigate(page);
  };



  render() {
    return (
      <div style={{ backgroundColor: "#1b5583", paddingBottom: 80 }}>
        <div
          style={{ paddingTop: 10, marginBottom: 20, textAlign: "center" }}
        >
          <div className="title-block1">PRODUCTOS MÁS VENDIDOS</div>
        </div>
        <Carousel
          responsive={responsive}
          // autoPlay={this.props.deviceType !== "mobile" ? true : false}
          // autoPlaySpeed={1000}
          transitionDuration={1000}
          centerMode={true}
          infinite={true}
        >
          {this.state.productos &&
            this.state.productos.map((item, index) => (
              <div
                style={{ marginRight: 10, marginBottom: 5 }}
                key={index + "prod"}
              >
                <CourseCard
                  item={item}
                  editable={false}
                />
              </div>
            ))}
        </Carousel>
      </div>

    )



  }
}
export default ProductosInicio;