import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class CiudadService extends BaseService {

    constructor() {
        super();
        this.collection = 'tbl_canton';
    }

    getCiudades(idProvincia, callback) {
        firebase.firestore()
            .collection(this.collection)
            .where('id_provincia', '==', idProvincia)
            .onSnapshot(snapshot => {
                let ciudades = []
                snapshot.forEach(item => {
                    let ciudad = item.data();
                    ciudad.id = item.id
                    ciudades.push(ciudad)
                })
                callback(ciudades)
            })

    }

    async getCiudadAsync(id_ciudad) {
        let ciudad = await firebase.firestore()
            .collection(this.collection)
            .doc(id_ciudad).get();
        return ciudad.data();
    }


}


export const ciudadService = new CiudadService();