import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class BloquesService extends BaseService {

    constructor() {
        super();
        this.collection = 'bloques';
    }

    async getBloqueAsync(bloqueId) {
        let bloque = await firebase.firestore()
            .collection(this.collection)
            .doc(bloqueId)
            .get();

        return bloque.data();
    }

    getRegistersOrden(){
        return firebase.firestore()
        .collection(this.collection)
        .orderBy('orden', 'asc')
    }


}

export const bloquesService = new BloquesService();
