import React from 'react';

import {
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Preloader from '../../components/Preloader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { userService } from '../../services/user.service';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    '&:hover': {
        backgroundColor: grey[700],
    },
}));


export default class ModalPagos extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loader: false
        }
    }

    componentDidMount() {
        this.setState({ loader: true })
    }

    contactar = () => () => {
        let planes = []
        let planesUsuario = this.props.usuario.planes
        if (planesUsuario) {
            planes = planesUsuario
        }

        let idsPlans = []
        planes.forEach(plan => {
            idsPlans.push(plan.plan.id)
        });

        if(idsPlans.includes(this.props.plan.id)){
            planes.forEach(plan => {
                if(plan.plan.id == this.props.plan.id){
                    plan.estado = 'reactivar'
                }
            });
        }else{
            planes.push(
                {
                    plan: this.props.plan,
                    estado: 'inactivo'
                }
            )
        }

        let data = { planes: planes }
        if (this.props.usuario.subscripcion == 'registrado' || this.props.usuario.subscripcion == 'inactivo') {
            data = {
                planes: planes,
                subscripcion: 'pendiente',
            }
        }

        userService.updateRegister(firebase.auth().currentUser.uid, data, () => {
            // window.open("https://wa.me/+593987179691", '_blank')
            window.open("https://wa.me/+593962372385", '_blank')
            this.props.onCancel();
        })

    }

    render() {
        const { data, onCancel, isModalShow } = this.props;
        if (!this.state.loader) {
            return (<Preloader />)
        } else {
            return (
                <Dialog
                    open={isModalShow}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        <center><b>Pagos por Transferencia </b> </center>
                        {onCancel ? (
                            <IconButton
                                aria-label="close"
                                onClick={onCancel}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    <DialogContent dividers>
                        <center>
                            <img style={{ with: '150px', height: '300px' }} src={'/assets/modalTransferencia.jpeg'} alt='Transferencia'></img> 
                        </center>
                        <div>
                            <p style={{ textAlign: 'center' }}> Para continuar con el proceso debe dar clic en el botón contactar  </p>
                            <ul>
                                <li>Una vez verificado el pago la activación de su cuenta será de manera inmediata  </li>
                                <li> Su factura será enviada en un plazo de 24 a 48 horas </li>
                            </ul>
                        </div>
                        <div style={{ marginTop: 0, textAlign: 'center' }}>
                            <ColorButton style={{ width: '20%' }}
                                variant="contained"
                                disabled={this.state.blockButton}
                                onClick={this.contactar()}
                            >Conctactar</ColorButton>
                        </div>
                    </DialogContent>
                </Dialog>
            )
        }
    }





}