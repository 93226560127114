import { CardContent, Grid, Card, Container, CardHeader, Button, TextField, Tooltip, IconButton } from '@mui/material';
import React, { Component } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { userService } from '../../services/user.service';
import Box from '@mui/material/Box';
import CourseCard from '../../components/CourseCard';
import { productosService } from '../../services/productos.service';
import { categoriasService } from '../../services/categorias.service';
import DeleteModal from '../../components/DeleteModal';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { toast } from 'react-toastify';
import Helper from '../../utils/Helper';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default class Dasbhboard extends Component {

    constructor() {
        super()
        this.state = {
            usuario: '',
            productos: [],
            isModalShowDelete: false,
            idRegisterProducto: '',
            snapshotNext: '',
            snapshotBack: '',
            nombre: '',
            marca: '',
            planes: []
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.helper = new Helper()
    }

    componentDidMount() {
        this.getUser()
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    getUser() {
        userService.getUser(firebase.auth().currentUser.uid).then(async (res) => {
            let usuario = res.data()
            if (usuario.subscripcion === 'registrado' || usuario.subscripcion === 'pendiente') {
                this.props.navigate('/planes')
            } if (usuario.estado === 0) {
                this.props.navigate('/informacion')
            }
            let planes = []
            if (usuario.planes) {
                for (let index = 0; index < usuario.planes.length; index++) {
                    if (usuario.planes[index].estado === 'activo') {
                        planes.push(usuario.planes[index].plan)
                    }
                }
            }
            console.log(usuario);
            this.setState({ usuario, planes })
            this.getProductos()
        })
    }

    getProductos() {
        this.setState({ productos: [] })
        let arrayBuscar = ''
        if (this.state.nombre) {
            arrayBuscar = this.helper.generarArrayNombre(this.state.nombre, ' ')
        }
        let filtros = {
            idVendedor: firebase.auth().currentUser.uid,
            nombre: arrayBuscar,
            marca: this.state.marca,
            precio: [],
            vendedoresBloqueados: []
        }
        let orden = { nombre: false, precio: false, fecha: true }
        if(arrayBuscar){
            orden.nombre = true;
            orden.fecha = false;
        }
        let consulta = productosService.getFirstProduct(filtros, orden, 8)
        consulta.get().then(snapshot => {
            let snapshotNext = snapshot.docs[snapshot.docs.length - 1];
            this.setState({ snapshotNext })
            this.generarProducto(snapshot)
        })
    }

    editarProducto = (producto) => () => {
        this.props.navigate('/productos_edit/' + producto.id)
    }

    openEliminarProducto = (producto) => () => {
        this.setState({ isModalShowDelete: true, idRegisterProducto: producto })
    }

    closeModal = () => () => {
        this.setState({ isModalShowDelete: false })
    }

    onAcceptEliminar = () => () => {
        this.eliminarImagenes(this.state.idRegisterProducto, 0)
    }

    eliminarProducto(producto) {
        productosService.deleteRegister(producto.id, () => {
            toast.info('Producto eliminado correctamente');
            this.setState({ isModalShowDelete: false, idRegisterProducto: '' })
            this.getProductos()

        })
    }

    eliminarImagenes(producto, index) {
        firebase.storage().ref(producto.imagenes[index]).delete().then(res => {
            if (index === (producto.imagenes.length - 1)) {
                this.eliminarProducto(producto)
            } else {
                let indexSiguiente = index + 1
                this.eliminarImagenes(producto, indexSiguiente)
            }
        })

    }

    generarProducto(snapshot) {
        let items = []
        let productos = []

        snapshot.forEach(item => {
            let producto = item.data();
            producto.id = item.id
            items.push(producto)
        })
        items.forEach(element => {
            element.stringCategoria = ''
            element.categorias.forEach(async (idCategoria) => {
                let categoria = await categoriasService.getCategoriaAsync(idCategoria)
                element.stringCategoria += categoria.nombre + '/'
            });
            productos.push(element)
        });
        this.setState({ productos })
    }

    getProductosPreview = () => () => {
        let arrayBuscar = ''
        if (this.state.nombre) {
            arrayBuscar = this.helper.generarArrayNombre(this.state.nombre, ' ')
        }
        let filtros = { idVendedor: firebase.auth().currentUser.uid, nombre: arrayBuscar, marca: this.state.marca, precio: [] }
        let orden = { nombre: false, precio: false, fecha: true }
        let consulta = productosService.getBackProduct(filtros, orden, 8, this.state.snapshotBack)
        consulta.get().then(snapshot => {
            if (snapshot.docs.length > 0) {
                this.setState({ productos: [] })
                let interval = setInterval(() => {
                    let snapshotBack = snapshot.docs[0];
                    let snapshotNext = snapshot.docs[snapshot.docs.length - 1];
                    this.setState({ snapshotNext, snapshotBack })
                    this.generarProducto(snapshot)
                    clearInterval(interval);
                }, 100);
            } else {
                this.getProductos()
            }
        })
    }

    getProductosNext = () => () => {
        let arrayBuscar = ''
        if (this.state.nombre) {
            arrayBuscar = this.helper.generarArrayNombre(this.state.nombre, ' ')
        }
        let filtros = { idVendedor: firebase.auth().currentUser.uid, nombre: arrayBuscar, marca: this.state.marca, precio: [] }
        let orden = { nombre: false, precio: false, fecha: true }
        let consulta = productosService.getNextProduct(filtros, orden, 8, this.state.snapshotNext)
        consulta.get().then(snapshot => {
            if (snapshot.docs.length > 0) {
                this.setState({ productos: [] })
                let interval = setInterval(() => {
                    let snapshotBack = snapshot.docs[0];
                    let snapshotNext = snapshot.docs[snapshot.docs.length - 1];
                    this.setState({ snapshotBack, snapshotNext })
                    this.generarProducto(snapshot)
                    clearInterval(interval);
                }, 100);
            } else {
                this.getProductos()
            }
        })
    }

    buscarProductos = () => () => {
        this.getProductos()
    }

    agregarPlanes = () => () => {
        this.props.navigate('/planes')
    }

    copyLink = (scheduleLink) => () => {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = scheduleLink;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        toast.info('Enlace copiado correctamente');
    }

    reactivate = (plan) => () => {
        this.props.navigate('/facturacion/' + plan.id);
    }
    
    render() {
        if (this.state.usuario.subscripcion) {
            const scheduleLink = 'https://dacomprando.com.ec/productos/V/'+this.state.usuario.codigo;

            let marginPlans = 0;
            if(this.state.usuario.subscripcion === 'inactivo'){
                marginPlans = 100;
            }

            return (
                <Container style={{ paddingTop: 30 }}>
                    {this.state.usuario.subscripcion === 'activo' &&
                        <Card>
                            <CardContent>
                                <div className='div-link-wellcome'>
                                    <div style={{ marginBottom: 10 }}>
                                        Date a conocer, comparte este link en tus redes o con tus clientes.
                                    </div>
                                    <Grid container className='link-wellcome'>
                                        <Grid item xs={12} sm={11} md={11}>{scheduleLink}</Grid>
                                        <Grid item xs={12} sm={1} md={1} style={{textAlign: 'right'}}>
                                            <Tooltip title="Copiar link" placement="right">
                                                <IconButton color="primary" onClick={this.copyLink(scheduleLink)}>
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </div>
                            </CardContent>
                        </Card>
                    }
                    <Card style={{marginTop: 20, marginBottom: marginPlans}}>

                        <CardHeader title='Mis planes actuales'  />
                        <CardContent>
                            <div style={{textAlign: 'right', marginBottom: 10}}>
                                <Button variant='contained' onClick={this.agregarPlanes()} aria-label='Comprar otro plan'>
                                    Comprar otro plan
                                </Button>
                            </div>
                            <div className='container-planes-dashboard'>
                                {this.state.planes.map((plan, indexP) => (
                                    <div key={'Plan' + indexP} className='plan-dashboard'>
                                        <div className='tituloPlanes'>
                                            {plan.nombre}
                                        </div>

                                        <div className='precioPlanes'>
                                            {'$' + plan.precio}
                                        </div>
                                        <ul>
                                            <li>{`${plan.meses} MESES DE PUBLICIDAD  `}</li>
                                            <li>{`${plan.gratuidad} MES(ES) GRATIS DE PUBLICIDAD - PROMOCIÓN`}</li>
                                            <li>{`${plan.cantidadProductos} PRODUCTOS A PUBLICAR`}</li>
                                            {plan.descripcionesAdicionales.map((descripcion, indexD) => (
                                                <li key={'D' + indexD}> {descripcion}</li>
                                            ))}
                                        </ul>
                                        {plan.precio != 0 && 
                                            <div style={{position: 'absolute', bottom: 15, right: 20}}>
                                                <Button variant="contained" color='primary' onClick={this.reactivate(plan)} aria-label='Reactivar'>
                                                    <b>Reactivar </b>
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </CardContent>
                    </Card>
                    {this.state.usuario.subscripcion === 'activo' &&
                        <Card style={{ marginTop: 20, marginBottom:100 }}>
                            <CardHeader title='Subir productos' />
                            <CardContent>
                                <div style={{ marginBottom: 20 }}>
                                    <Button variant='contained' onClick={() => { this.props.navigate('/productos_edit/0') }} aria-label='Nuevo Producto'>
                                        Nuevo Producto
                                    </Button>
                                </div>
                                <div>
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        <Grid item xs={2} sm={4} md={3} style={{ display: 'flex', alignItems: 'center' }} >
                                            <TextField
                                                name='nombre'
                                                label='Nombre de su producto'
                                                value={this.state.nombre ? this.state.nombre : ""}
                                                fullWidth
                                                margin="normal"
                                                onChange={this.handleInputChange}

                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={3} style={{ display: 'flex', alignItems: 'center' }} >
                                            <TextField
                                                name='marca'
                                                label='Marca de su producto'
                                                value={this.state.marca ? this.state.marca : ""}
                                                fullWidth
                                                margin="normal"
                                                onChange={this.handleInputChange}

                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Button variant='contained' onClick={this.buscarProductos()} aria-label='Buscar'>
                                                Buscar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={2} sm={4} md={3} ></Grid>
                                    </Grid>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                            {this.state.productos.map((item, index) => (
                                                <Grid item xs={2} sm={4} md={3} key={'prd'+index}>
                                                    <CourseCard
                                                        item={item}
                                                        editable={true}
                                                        eliminarProducto={this.openEliminarProducto(item)}
                                                        editarProducto={this.editarProducto(item)}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </div>
                                <div style={{ padding: 20, textAlign: 'center' }}>
                                    <Tooltip title="Atras">
                                        <IconButton onClick={this.getProductosPreview()}>
                                            <NavigateBeforeIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {' '}
                                    <Tooltip title="Siguiente">
                                        <IconButton onClick={this.getProductosNext()}>
                                            <NavigateNextIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                            </CardContent>
                        </Card>
                    }
                    <DeleteModal
                        onAccept={this.onAcceptEliminar()}
                        onCancel={this.closeModal()}
                        isModalShow={this.state.isModalShowDelete}
                    />
                </Container>
            )

        } else {
            return null
        }
    }
}