import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';
import { productosService } from './productos.service'

class UserService extends BaseService {

    constructor() {
        super();
        this.collection = 'usuarios';
    }


    getUser(userId) {
        return firebase.firestore()
            .collection(this.collection)
            .doc(userId)
            .get();

    }

    getUserDoc(userId) {
        return firebase.firestore()
            .collection(this.collection)
            .doc(userId);

    }

    getUsuariosBloqueados(callback){
        firebase.firestore()
        .collection(this.collection)
        .where('estado', '==', 0)
        .get().then(snapshot => {
            let usuarios = []
            snapshot.forEach(item => {
                usuarios.push(item.id);
            })
            callback(usuarios);
        })
    }

    getUsuarios(filters){
        let consulta = firebase.firestore().collection(this.collection)
        if(filters['dni']){
            consulta = consulta.where('dni', '==', filters['dni']);
        }
        if(filters['codigo']){
            consulta = consulta.where('codigo', '==', filters['codigo']);
        }
        if(filters['estado']){
            consulta = consulta.where('subscripcion', '==', filters['estado']);
        }
        consulta = consulta.orderBy('fechaCreacion', 'desc')
        consulta = consulta.limit(5)

        return consulta
    }

    getNextUsuarios(filters, lastVisible){
        let consulta = firebase.firestore().collection(this.collection)
        if(filters['dni']){
            consulta = consulta.where('dni', '==', filters['dni']);
        }
        if(filters['codigo']){
            consulta = consulta.where('codigo', '==', filters['codigo']);
        }
        if(filters['estado']){
            consulta = consulta.where('subscripcion', '==', filters['estado']);
        }
        consulta = consulta.orderBy('fechaCreacion', 'desc')
        consulta = consulta.startAfter(lastVisible)
        consulta = consulta.limit(5)

        return consulta;
    }

    getBackUsuarios(filters, lastVisible){
        let consulta = firebase.firestore().collection(this.collection)
        if(filters['dni']){
            consulta = consulta.where('dni', '==', filters['dni']);
        }
        if(filters['codigo']){
            consulta = consulta.where('codigo', '==', filters['codigo']);
        }
        if(filters['estado']){
            consulta = consulta.where('subscripcion', '==', filters['estado']);
        }
        consulta = consulta.orderBy('fechaCreacion', 'desc')
        consulta = consulta.endBefore(lastVisible)
        consulta = consulta.limit(5)

        return consulta;
    }

    getUsuarioPorCodigo(codigo, callback) {
        firebase.firestore()
            .collection(this.collection)
            .where('codigo', '==' , codigo)
            .get().then(snapshot => {
                let usuarios = []
                snapshot.forEach(item => {
                    let usuario = item.data();
                    usuario.id = item.id
                    usuarios.push(usuario)
                })
                callback(usuarios[0])
            })

    }

    async actualizarEstadoVendedor(idVendedor, data, callback){
        await firebase.firestore()
        .collection(this.collection)
        .doc(idVendedor)
        .update(data)
        
            let productos = await productosService.getAllProductosVendedorAsync(idVendedor);
            productosService.actualizarEstadoProductos(productos, data.estado, callback);
        

    }


    async getUsuarioAsync(idVendedor) {
        let usuario = await firebase.firestore()
            .collection(this.collection)
            .doc(idVendedor).get();
        return usuario.data();
    }

}

export const userService = new UserService();
