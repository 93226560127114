import React from 'react';
import { styled } from '@mui/material/styles';
import {
    Container, Grid, Button, TextField, Card, CardContent, CardHeader,
    InputAdornment, IconButton, Switch, MenuItem
} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css';
import { grey } from '@mui/material/colors';
import { userService } from '../../services/user.service';
import FormControlLabel from '@mui/material/FormControlLabel';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

import { provinciasService } from '../../services/provincias.service';
import { ciudadService } from '../../services/ciudad.service';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    '&:hover': {
        backgroundColor: grey[700],
    },
}));

export default class Perfil extends React.Component {

    constructor() {
        super();
        this.state = {
            usuario: {
                foto: '',
                nombre_comercial: '',
                nombre: '',
                apellido: '',
                dni: '',
                provincia: '',
                ciudad: '',
                direccion: '',
                telefono: '',
                celular: '',
                email: '',
                terminos: false,
            },
            isCambioDeClave: false,
            isModalTerminos: false,
            password: '',
            confirm_password: '',
            showPassword: {
                pass: false,
                confirm: false
            },
            error: {
                nombre_comercial: { error: false, text: '', required: true },
                nombre: { error: false, text: '', required: true },
                apellido: { error: false, text: '', required: true },
                dni: { error: false, text: '', required: true },
                provincia: { error: false, text: '', required: true },
                ciudad: { error: false, text: '', required: true },
                direccion: { error: false, text: '', required: true },
                telefono: { error: false, text: '', required: false },
                celular: { error: false, text: '', required: true },
                email: { error: false, text: '', required: true },
                password: { error: false, text: '', required: true },
                confirm_password: { error: false, text: '', required: true },
            },
            blockButton: false,
            imageCompany: '',
            listadoProvincias: [],
            listadoCiudades: []
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.register = this.register.bind(this);
    }

    componentDidMount() {
        this.getUser()
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let usuario = { ...this.state.usuario }
        if (name === 'terminos' && value) {
            this.setState({ blockButton: false })
        } else {
            this.setState({ blockButton: true })
        }
        if (name === 'password' || name === 'confirm_password' || name === 'isCambioDeClave') {
            this.setState({ [name]: value })
        }
        if (name === 'provincia') {
            this.getCiudades(value)
        }
        usuario[name] = value
        this.setState({
            usuario
        });
    }

    handleInputBlur(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let error = { ...this.state.error };
        error = this.setErrorField(error, name, false, '');
        if (error[name].required && (value === '' || value === 0)) {
            error = this.setErrorField(error, name, true, 'Campo obligatirio');
        } else {
            if (name === 'email') {
                if (!this.validEmail(value)) {
                    error = this.setErrorField(error, name, true, 'Email incorrecto');
                }
            }

            if (name === 'password') {
                if (value.length < 6) {
                    error = this.setErrorField(error, name, true, 'La contraseña debe tener mas de 6 caracteres');
                }
            }

            if (name === 'confirm_password') {
                if (value !== this.state.password) {
                    error = this.setErrorField(error, name, true, 'La contraseña no coiciden');
                }
            }
        }
        this.setState({ error })
    }

    setErrorField(error, name, status, message) {
        error[name].error = status;
        error[name].text = message;
        this.setState({ blockButton: status });
        return error;
    }

    handleClickShowPassword = (type) => () => {
        let showPassword = { ...this.state.showPassword }
        if (type === 'P') {
            showPassword.pass = !showPassword.pass;
        } else {
            showPassword.confirm = !showPassword.confirm;
        }
        this.setState({ showPassword });
    };

    handleMouseDownPassword(event) {
        event.preventDefault();
    };

    validEmail(emailValue) {
        let result = false;
        let reg = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (reg.test(emailValue)) {
            result = true;
        }
        return result;
    }

    register = () => () => {
        this.setState({ blockButton: true })
        if (this.validateFields()) {
            this.updateUser();
        }
    }

    updateUser() {
        userService.updateRegister(firebase.auth().currentUser.uid, this.state.usuario, () => {
            if (this.state.isCambioDeClave) {
                this.actualizarPassword()
            } else {
                this.setState({ blockButton: false })
                toast.info('Actualizado con exito');
            }
        })

    }

    actualizarPassword() {
        firebase.auth().currentUser.updatePassword(this.state.password).then(res => {
            this.setState({ blockButton: false })
            toast.info('Actualizado con exito');
        })
    }

    getUser() {
        userService.getRegister(firebase.auth().currentUser.uid).then(
            res => {
                let usuario = res.data();
                this.setState({ usuario })
                let nameImage = 'images/' + usuario.dni + '.png';
                this.getImagesStorage(nameImage);
                this.getProvincias(usuario)
            }
        )
    }

    getImagesStorage(nameImage) {
        firebase.storage().ref(nameImage).getDownloadURL()
            .then((url) => {
                this.setState({ imageCompany: url });
            })
    }

    validateFields() {
        let validation = true;
        let error = { ...this.state.error };

        if (this.state.usuario.nombre === '') {
            error.name.error = true;
            error.name.text = 'Campo obligatorio';
            validation = false;
        }

        if (!this.validEmail(this.state.usuario.email)) {
            error.email.error = true;
            error.email.text = 'Email incorrecto';
            validation = false;
        }

        if (this.state.isCambioDeClave) {
            if (this.state.password.length < 6) {
                error.password.error = true;
                error.password.text = 'La contraseña debe tener mas de 6 caracteres';
                validation = false;
            }
            if (this.state.confirm_password !== this.state.password) {
                error.confirm_password.error = true;
                error.confirm_password.text = 'La contraseña no coiciden';
                validation = false;
            }
        }
        if (!validation) {
            this.setState({ error, blockButton: false })

        } else {
            this.setState({ error, blockButton: true })
        }
        return validation;
    }

    viewModal = (value) => () => {
        this.setState({ isModalTerminos: value })
    }

    handleImageChange = (event) => {
        if (event.target.files[0].size <= 100000) {
            try {
                Resizer.imageFileResizer(
                    event.target.files[0],
                    400,
                    400,
                    "png",
                    100,
                    0,
                    (uri) => {
                        this.setState({ imageCompany: uri });
                    },
                    "base64",
                    200,
                    200
                );
            } catch (err) {
                console.error(err);
            }
        } else {
            toast.warning('La imagen no debe pasar de 100 KB')
        }
    }

    async saveImage(companyId, image, message) {
        if (image == null) return;
        const response = await fetch(image);
        const blob = await response.blob();
        let nameImage = 'images/' + companyId + '.png';
        firebase.storage().ref(nameImage).put(blob)
            .then(() => {
                this.setState({ blockButton: false })
                toast.info(message);
            });
    }


    showImage() {
        if (this.state.imageCompany === '') {
            return (<img src='assets/usuario.webp' alt='Usuario' className="imagen" />)
        } else {
            return (<img src={this.state.imageCompany} alt='Usuario' className="imagen" />)
        }
    }

    getProvincias(usuario) {
        provinciasService.getRegisters().get().then(res => {
            let provincias = []
            res.forEach(item => {
                let provincia = item.data()
                provincia.id = item.id
                provincias.push(provincia)
            })
            this.setState({ listadoProvincias: provincias })
            this.getCiudades(usuario.provincia)
        })
    }

    getCiudades(id_provincia) {
        ciudadService.getCiudades(id_provincia, res => {
            this.setState({ listadoCiudades: res })
        })
    }

    render() {
        return (
            <Container>
                <div className="register-div">
                    <Card>
                        <CardHeader title="Datos de un vendedor exitoso!!" />
                        <CardContent>
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <div style={{ position: 'relative', textAlign: 'center' }}>
                                            <div className='container-imagen'>
                                                {this.showImage()}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            name='nombre_comercial'
                                            label='Nombre comercial'
                                            value={this.state.usuario.nombre_comercial ? this.state.usuario.nombre_comercial : ""}
                                            error={this.state.error.nombre_comercial.error}
                                            helperText={this.state.error.nombre_comercial.error ? this.state.error.nombre_comercial.text : ''}
                                            fullWidth
                                            margin="normal"
                                            required={this.state.error.nombre_comercial.required}
                                            onChange={this.handleInputChange}
                                            onBlur={this.handleInputBlur}
                                            disabled={true}
                                        />
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='dni'
                                                    label='Cédula o RUC'
                                                    value={this.state.usuario.dni ? this.state.usuario.dni : ""}
                                                    error={this.state.error.dni.error}
                                                    helperText={this.state.error.dni.error ? this.state.error.dni.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.dni.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='nombre'
                                                    label='Nombre'
                                                    value={this.state.usuario.nombre ? this.state.usuario.nombre : ""}
                                                    error={this.state.error.nombre.error}
                                                    helperText={this.state.error.nombre.error ? this.state.error.nombre.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.nombre.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                    disabled={true}
                                                />

                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='apellido'
                                                    label='Apellido'
                                                    value={this.state.usuario.apellido ? this.state.usuario.apellido : ""}
                                                    error={this.state.error.apellido.error}
                                                    helperText={this.state.error.apellido.error ? this.state.error.apellido.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.apellido.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                    disabled={true}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    select
                                                    name='provincia'
                                                    label='Seleccione la Provincia'
                                                    value={this.state.usuario.provincia ? this.state.usuario.provincia : ""}
                                                    error={this.state.error.provincia.error}
                                                    helperText={this.state.error.provincia.error ? this.state.error.provincia.text : ''}
                                                    required={this.state.error.provincia.required}
                                                    fullWidth
                                                    margin="normal"
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                >
                                                    {this.state.listadoProvincias.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.provincia}
                                                        </MenuItem>

                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} md={4}>

                                                <TextField
                                                    select
                                                    name='ciudad'
                                                    label='Seleccione la Ciudad'
                                                    value={this.state.usuario.ciudad ? this.state.usuario.ciudad : ""}
                                                    error={this.state.error.ciudad.error}
                                                    helperText={this.state.error.ciudad.error ? this.state.error.ciudad.text : ''}
                                                    required={this.state.error.ciudad.required}
                                                    fullWidth
                                                    margin="normal"
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                >
                                                    {this.state.listadoCiudades.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.canton}
                                                        </MenuItem>

                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='direccion'
                                                    label='Direccion'
                                                    value={this.state.usuario.direccion ? this.state.usuario.direccion : ""}
                                                    error={this.state.error.direccion.error}
                                                    helperText={this.state.error.direccion.error ? this.state.error.direccion.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.direccion.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='telefono'
                                                    label='Teléfono'
                                                    value={this.state.usuario.telefono ? this.state.usuario.telefono : ""}
                                                    error={this.state.error.telefono.error}
                                                    helperText={this.state.error.telefono.error ? this.state.error.telefono.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.telefono.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='celular'
                                                    label='Celular'
                                                    value={this.state.usuario.celular ? this.state.usuario.celular : ""}
                                                    error={this.state.error.celular.error}
                                                    helperText={this.state.error.celular.error ? this.state.error.celular.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.celular.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                />

                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='email'
                                                    label='Email'
                                                    value={this.state.usuario.email ? this.state.usuario.email : ""}
                                                    error={this.state.error.email.error}
                                                    helperText={this.state.error.email.error ? this.state.error.email.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.email.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                    disabled={true}
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormControlLabel
                                            control={
                                                <Switch value={this.state.isCambioDeClave} color='primary' onChange={this.handleInputChange} name='isCambioDeClave' />
                                            }
                                            label="¿Desea cambiar la clave?"
                                            labelPlacement='start'

                                        />
                                        {this.state.isCambioDeClave &&
                                            <div className="div-element">
                                                <TextField
                                                    type={this.state.showPassword.pass ? 'text' : 'password'}
                                                    name='password'
                                                    label='Contraseña'
                                                    value={this.state.password ? this.state.password : ""}
                                                    error={this.state.error.password.error}
                                                    helperText={this.state.error.password.error ? this.state.error.password.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.password.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={this.handleClickShowPassword('P')}
                                                                onMouseDown={this.handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {this.state.showPassword.pass ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>,
                                                    }}
                                                />
                                                <TextField
                                                    type={this.state.showPassword.confirm ? 'text' : 'password'}
                                                    name='confirm_password'
                                                    label='Confirmar contraseña'
                                                    value={this.state.confirm_password ? this.state.confirm_password : ""}
                                                    error={this.state.error.confirm_password.error}
                                                    helperText={this.state.error.confirm_password.error ? this.state.error.confirm_password.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.confirm_password.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={this.handleClickShowPassword('C')}
                                                                onMouseDown={this.handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {this.state.showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>,
                                                    }}
                                                />
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                                <div className="div-element">
                                    <ColorButton style={{ width: '30%' }}
                                        variant="contained"
                                        onClick={this.register()}
                                        disabled={this.state.blockButton}

                                    >Guardar</ColorButton>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Container>
        )
    }
}