import { useEffect } from "react";
import { iniciarDatos } from "../configuration/ppx.index";

const PpxButton = ({ data }) => {
  const estiloBoton = {
    display: "none",
    backgroundColor: "#FAFAFA",
    right: "80px",
    backgroundImage:
      "url(https://paybox.pagoplux.com/img/pagar.png?v1)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "96px",
    width: "215px",
    border: "none",
    cursor: "pointer",
    backgroundSize: "contain",
    outline: "0",
    boxShadow: "0px 2px 2px lightgray",
  };

  useEffect(() => {
    if (data) {
      iniciarDatos(data);
    }
  }, [data]);
  if (data) {
    return (
      <>
        <div id="modalPaybox"></div>
        <button style={estiloBoton} id="pay" type="submit"></button>
      </>
    );

  }else {
    return null
  }
  
};

export default PpxButton;
