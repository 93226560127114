import React, { useState, Component } from "react";
import { Slide } from "react-slideshow-image";
import "../App.css";
import ProgressiveImg from "./ProgressiveImg";

const SectionSlider = () => {
  const [autoplay, setAutoplay] = useState(true);
  const style = {
    textAlign: "center",
    padding: "200px 0",
    fontSize: "30px",
  };

  return (

  
      <Slide autoplay={autoplay}>
          <div>
            <ProgressiveImg 
            placeholderSrc={'assets/pequeña1.webp'} 
            src={'assets/IMAGEN1.webp'}
            claseImagen= 'container-image1'>
            </ProgressiveImg>
          </div>

          <div>
            <ProgressiveImg 
            placeholderSrc={'assets/pequeña2.jpeg'} 
            src={'assets/IMAGEN2.jpeg'}
            claseImagen= 'container-image2'>
            </ProgressiveImg>
          </div>
          <div>
            <ProgressiveImg 
            placeholderSrc={'assets/pequeña3.jpg'} 
            src={'assets/IMAGEN3.jpg'}
            claseImagen= 'container-image3'>
            </ProgressiveImg>
          </div>
        </Slide>
     

  );
};

export default SectionSlider;
