import React, { Component } from 'react';

import {
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    MenuItem,
    Grid,
    DialogActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Preloader from '../../components/Preloader';
import { toast } from 'react-toastify';
import { categoriasService } from '../../services/categorias.service';

import ListSubheader from '@mui/material/ListSubheader';






export default class ModalCategorias extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loader: false,
            showImagen: false,
            showPadre: false,
            nivelSelect: '',
            categoria: { nivel: '', nombre: '', imagen: '', idPadre: '', nickName: '' },
            error: {
                nivel: { error: false, text: '', required: true },
                nombre: { error: false, text: '', required: true },
                imagen: { error: false, text: '', required: false },
                nivel1: { error: false, text: '', required: false },
                nivel2: { error: false, text: '', required: false },
                nivel3: { error: false, text: '', required: false },
                nivel4: { error: false, text: '', required: false },
                nivel5: { error: false, text: '', required: false },
                nickName:{error: false, text: '', required: true}
            },
            nivel_items: [
                { id: 1, nombre: 'Nivel 1' },
                { id: 2, nombre: 'Nivel 2' },
                { id: 3, nombre: 'Nivel 3' },
                { id: 4, nombre: 'Nivel 4' },
                { id: 5, nombre: 'Nivel 5' }
            ],
            imagenes: [],
            listadoCategorias: {
                nivel1: { visible: true, data: [], valor: '' },
                nivel2: { visible: false, data: [], valor: '' },
                nivel3: { visible: false, data: [], valor: '' },
                nivel4: { visible: false, data: [], valor: '' },
                nivel5: { visible: false, data: [], valor: '' }
            },
            idPadre_items: []
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);



    }

    componentDidMount() {
        this.setState({ loader: true })
        let categoria = {};
        if(this.props.data == ''){
            categoria = { nivel: '', nombre: '', imagen: '', idPadre: '', nickName: '' }            
        } else {
            categoria = { 
                nivel: this.props.data.nivel, 
                nombre: this.props.data.nombre, 
                imagen: this.props.data.imagen, 
                idPadre: this.props.data.idPadre,
                nickName: this.props.data.nickName
            }
            
        }
        this.setState({ categoria })
        

    }

    

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let categoria = { ...this.state.categoria }
        let listadoCategorias = { ...this.state.listadoCategorias }
        if (name == 'nivel' && value) {
            if (value == 1) {
                this.setState({ showImagen: true, showPadre: false })
                this.getImagenesCategoria();
            } else {
                this.setState({ showImagen: false, showPadre: true })
                this.getCategoriasNivel1()

            }
        }
        let changeNivel = false
         for (let index = 1; index < this.state.categoria.nivel; index ++){
            let nombreNivel = 'nivel'+index;
            if (name == nombreNivel && value) {
                changeNivel = true;
                let indexNivel = parseInt(name.slice(-1)) + 1;
                let nombre = 'nivel'+indexNivel
                if(index < (this.state.categoria.nivel - 1)){
                    listadoCategorias[nombre].visible = true;
                    this.setState({ listadoCategorias })
                    this.getSubCategoriaNivel(value, nombre);
                }else {
                    categoria.idPadre = value
                    this.setState({categoria}) 
                }
            }
        }
        if (changeNivel) {            
            listadoCategorias[name].valor = value
            this.setState({ listadoCategorias })

        } else {
            categoria[name] = value
            this.setState({
                categoria
            });

        }


    }


    setErrorField(error, name, status, message) {
        error[name].error = status;
        error[name].text = message;
        //this.setState({ blockButton: status });
        return error;
    }

    handleInputBlur(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let error = { ...this.state.error };
        error = this.setErrorField(error, name, false, '');
        if (error[name].required && (value === '' || value === 0)) {
            error = this.setErrorField(error, name, true, 'Campo obligatirio');
        }

        this.setState({ error })

    }

    getImagenesCategoria() {
        categoriasService.getImagenesCategoria(imagenes => {
            this.setState({ imagenes })
        })
    }

    getCategoriasNivel1() {
        categoriasService.getCategoriasNivel1(categorias => {
            let listadoCategorias = { ...this.state.listadoCategorias }
            listadoCategorias.nivel1.data = categorias
            this.setState({ listadoCategorias })
        })
    }



    getSubCategoriaNivel(value, nombre) { 
        categoriasService.getSubCategoria(value, (categorias) => {

            let listadoCategorias = { ...this.state.listadoCategorias }
            listadoCategorias[nombre].data = categorias
            this.setState({ listadoCategorias })
        
        })
    }

    save = (callback) => () => {
        if (this.validateFields()) {
            this.saveCategoria(callback)
        }
    }

    validateFields() {
        let validation = true;
        let error = { ...this.state.error };

        if (this.state.categoria.nivel === '') {
            error.nivel.error = true;
            error.nivel.text = 'Campo obligatorio';
            validation = false;
        } else {
            if (this.state.categoria.nivel === 1) {
                if (this.state.categoria.imagen === '') {
                    error.imagen.error = true;
                    error.imagen.text = 'Campo obligatorio';
                    validation = false;
                }
            } 
        }
        if (this.state.categoria.nombre === '') {
            error.nombre.error = true;
            error.nombre.text = 'Campo obligatorio';
            validation = false;
        }
        if (!validation) {
            this.setState({ error })
        } else {
            this.setState({ error })
        }
        return validation;
    }

    saveCategoria(callback) {
        if (this.props.idRegister == 0) {
            categoriasService.addRegister(this.state.categoria, () => {
                toast.info('Registro Guardado Correctamente')
                callback()
            })

        } else {
            categoriasService.updateRegister(this.props.idRegister, this.state.categoria, () => {
                toast.info('Registro Actualizado Correctamente')
                callback()
            })

        }
    }





    render() {
        const { onCancel, isModalShow, onAccept } = this.props;
        if (!this.state.loader) {
            return (<Preloader />)
        } else {
            return (
                <Dialog
                    open={isModalShow}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        Nueva Categoría
                        {onCancel ? (
                            <IconButton
                                aria-label="close"
                                onClick={onCancel}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    <DialogContent dividers>

                        <TextField
                            select
                            name='nivel'
                            label='Selecciona el Nivel'
                            value={this.state.categoria.nivel ? this.state.categoria.nivel : ""}
                            error={this.state.error.nivel.error}
                            helperText={this.state.error.nivel.error ? this.state.error.nivel.text : ''}
                            required={this.state.error.nivel.required}
                            fullWidth
                            margin="normal"
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}

                        >
                            {this.state.nivel_items.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.nombre}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            name='nombre'
                            label='Nombre'
                            value={this.state.categoria.nombre ? this.state.categoria.nombre : ""}
                            error={this.state.error.nombre.error}
                            helperText={this.state.error.nombre.error ? this.state.error.nombre.text : ''}
                            fullWidth
                            margin="normal"
                            required={this.state.error.nombre.required}
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}
                        />
                          <TextField
                            name='nickName'
                            label='Sobrenombre'
                            value={this.state.categoria.nickName ? this.state.categoria.nickName : ""}
                            error={this.state.error.nickName.error}
                            helperText={this.state.error.nickName.error ? this.state.error.nickName.text : ''}
                            fullWidth
                            margin="normal"
                            required={this.state.error.nickName.required}
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}
                        />
                        
                        {this.state.showImagen &&
                            <TextField
                                select
                                name='imagen'
                                label='Selecciona la imagen'
                                value={this.state.categoria.imagen ? this.state.categoria.imagen : ""}
                                error={this.state.error.imagen.error}
                                helperText={this.state.error.imagen.error ? this.state.error.imagen.text : ''}
                                required={this.state.error.imagen.required}
                                fullWidth
                                margin="normal"
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}

                            >
                                {this.state.imagenes.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>



                        }
                        {this.state.showPadre &&
                            <TextField
                                select
                                name='nivel1'
                                label='Categoria Nivel 1'
                                value={this.state.listadoCategorias.nivel1.valor ? this.state.listadoCategorias.nivel1.valor : ""}
                                error={this.state.error.nivel1.error}
                                helperText={this.state.error.nivel1.error ? this.state.error.nivel1.text : ''}
                                required={this.state.error.nivel1.required}
                                fullWidth
                                margin="normal"
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}

                            >
                                {this.state.listadoCategorias.nivel1.data.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.nombre}
                                    </MenuItem>

                                ))}
                            </TextField>

                        }

                        {this.state.listadoCategorias.nivel2.visible &&

                            <TextField
                                select
                                name='nivel2'
                                label='Categoria Nivel 2'
                                value={this.state.listadoCategorias.nivel2.valor ? this.state.listadoCategorias.nivel2.valor : ""}
                                error={this.state.error.nivel2.error}
                                helperText={this.state.error.nivel2.error ? this.state.error.nivel2.text : ''}
                                required={this.state.error.nivel2.required}
                                fullWidth
                                margin="normal"
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}

                            >
                                {this.state.listadoCategorias.nivel2.data.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.nombre}
                                    </MenuItem>

                                ))}
                            </TextField>
                        }
                        {this.state.listadoCategorias.nivel3.visible &&

                            <TextField
                                select
                                name='nivel3'
                                label='Categoria Nivel 3'
                                value={this.state.listadoCategorias.nivel3.valor ? this.state.listadoCategorias.nivel3.valor : ""}
                                error={this.state.error.nivel3.error}
                                helperText={this.state.error.nivel3.error ? this.state.error.nivel3.text : ''}
                                required={this.state.error.nivel3.required}
                                fullWidth
                                margin="normal"
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}

                            >
                                {this.state.listadoCategorias.nivel3.data.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.nombre}
                                    </MenuItem>

                                ))}
                            </TextField>



                        }

                        {this.state.listadoCategorias.nivel4.visible &&

                            <TextField
                                select
                                name='nivel4'
                                label='Categoria Nivel 4'
                                value={this.state.listadoCategorias.nivel4.valor ? this.state.listadoCategorias.nivel4.valor : ""}
                                error={this.state.error.nivel4.error}
                                helperText={this.state.error.nivel4.error ? this.state.error.nivel4.text : ''}
                                required={this.state.error.nivel4.required}
                                fullWidth
                                margin="normal"
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}

                            >
                                {this.state.listadoCategorias.nivel4.data.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.nombre}
                                    </MenuItem>

                                ))}
                            </TextField>
                        }


                        {this.state.listadoCategorias.nivel5.visible &&

                            <TextField
                                select
                                name='nivel5'
                                label='Categoria Nivel 5'
                                value={this.state.listadoCategorias.nivel5.valor ? this.state.listadoCategorias.nivel5.valor : ""}
                                error={this.state.error.nivel5.error}
                                helperText={this.state.error.nivel5.error ? this.state.error.nivel5.text : ''}
                                required={this.state.error.nivel5.required}
                                fullWidth
                                margin="normal"
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}

                            >
                                {this.state.listadoCategorias.nivel5.data.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.nombre}
                                    </MenuItem>

                                ))}
                            </TextField>

                        }


                    </DialogContent>

                    <DialogActions sx={{ justifyContent: 'center' }}>
                        <Button variant="contained" onClick={this.save(onAccept)} aria-label='Guardar'>
                            Guardar
                        </Button>
                    </DialogActions>

                </Dialog>
            )
        }
    }





}