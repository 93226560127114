import React from 'react';
import { CardContent, Grid, Card, Container, CardHeader, Button, TextField, MenuItem, IconButton, InputAdornment, Switch } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { categoriasService } from '../../services/categorias.service';
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'firebase/compat/storage';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { productosService } from '../../services/productos.service';
import Preloader from '../../components/Preloader';
import { userService } from '../../services/user.service';
import Helper from '../../utils/Helper'
import { bloquesService } from '../../services/bloques.service';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    '&:hover': {
        backgroundColor: grey[700],
    },
}));

export default class Productos extends React.Component {
    constructor() {
        super()
        this.state = {
            producto: {
                nombre: '',
                descripcion: '',
                categorias: [],
                nickNames: [],
                imagenes: [],
                precio: '',
                stock: '',
                nombreArray: [],
                precio_a_convenir: false
            },
            error: {
                nombre: { error: false, text: '', required: true },
                descripcion: { error: false, text: '', required: true },
                imagen: { error: false, text: '', required: true },
                precio: { error: false, text: '', required: true },
                stock: { error: false, text: '', required: true },
                nivel1: { error: false, text: '', required: false },
                nivel2: { error: false, text: '', required: false },
                nivel3: { error: false, text: '', required: false },
                nivel4: { error: false, text: '', required: false },
                nivel5: { error: false, text: '', required: false }
            },
            listadoCategorias: {
                nivel1: { visible: true, data: [], valor: '' },
                nivel2: { visible: false, data: [], valor: '' },
                nivel3: { visible: false, data: [], valor: '' },
                nivel4: { visible: false, data: [], valor: '' },
                nivel5: { visible: false, data: [], valor: '' }
            },
            listadoCategoriasNickname: {
                nivel1: {},
                nivel2: {},
                nivel3: {},
                nivel4: {},
                nivel5: {}
            },
            listadoEstados: [
                { estado: 'Nuevo' },
                { estado: 'Usado' }
            ],

            listadoStock: [
                { estado: 'Disponible' },
                { estado: 'Agotado' }
            ],
            loader: false,
            vendedor: '',
            imagenesFile: [],
            imagenesPrevisualizar: [],
            imagenesRespaldo: [],
            categoriasBloque: [],
            bloquesUsuario: [],
            bloquearBoton: true,
            listasCampos: {},
            categoriaPrincipal: ''
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.helper = new Helper()
    }

    componentDidMount() {
        this.getUser(firebase.auth().currentUser.uid)
        this.setState({listasCampos: this.helper.getNamesFieldsProducts()})
    }

    getUser(idVendedor) {
        userService.getUser(idVendedor).then(async (item) => {
            let vendedor = item.data()
            this.setState({ vendedor })
            let categorias = []
            let bloques = []
            let categoriasBloque = []
            let planesVendedor = []

            for (let index = 0; index < vendedor.planes.length; index++) {
                bloques[vendedor.planes[index].plan.bloque] = { total: 0, inactivos: 0 }
                let plan = vendedor.planes[index]
                plan.fecha = new Date(plan.fechaCaducidad)
                planesVendedor.push(plan)
            }
            
            planesVendedor.sort(function (x, y) {
                if (x.fecha < y.fecha) {
                    return -1;
                }
                if (x.fecha > y.fecha) {
                    return 1;
                }
                return 0;
            });

            for (let index = 0; index < planesVendedor.length; index++) {
                let bloque = await bloquesService.getBloqueAsync(planesVendedor[index].plan.bloque);
                bloques[planesVendedor[index].plan.bloque].total += parseInt(planesVendedor[index].plan.cantidadProductos)
                
                if (planesVendedor[index].estado === 'inactivo') {
                    bloques[planesVendedor[index].plan.bloque].inactivos += parseInt(planesVendedor[index].plan.cantidadProductos)
                }else{
                    for (let indexB = 0; indexB < bloque.categorias.length; indexB++) {
                        
                        categoriasBloque[bloque.categorias[indexB].id] = {
                            bloque: planesVendedor[index].plan.bloque,
                            fechaCaducidad: planesVendedor[index].fechaCaducidad,
                            nombre: planesVendedor[index].plan.nombre
                        }
                    }

                }
                
                if (index === 0) {
                    categorias = bloque.categorias
                }
                categorias = [...categorias, ...bloque.categorias]
            }
            this.setState({ categoriasBloque, bloquesUsuario: bloques })
            let categoriasAgrupadas = this.helper.arrayUnique(categorias)
            this.getCategoriaBloque(categoriasAgrupadas)
        })
    }

    getProducto() {
        productosService.getRegister(this.props.params.id_producto).then(async (item) => {
            let producto = item.data();
            if (!producto.nickNames) {
                producto.nickNames = []
            }
            this.setState({ imagenesPrevisualizar: [], imagenesFile: [] })

            let listadoCategorias = { ...this.state.listadoCategorias }

            for (let index = 0; index < producto.categorias.length; index++) {
                let idcategoria = producto.categorias[index];
                let indexCategoria = index + 1
                let nombre = 'nivel' + indexCategoria;
                listadoCategorias[nombre].visible = true;
                listadoCategorias[nombre].valor = idcategoria;
                if (nombre === 'nivel1') {
                    let categoria = await categoriasService.getCategoriaAsync(idcategoria)
                    let error = {...this.state.error}
                    categoria.campos.forEach(campo => {
                        error[campo] = { error: false, text: '', required: false }
                    })            
                    this.setState({error, producto, categoriaPrincipal: categoria});
                }
                this.setState({ listadoCategorias })
                let siguienteNivel = 'nivel' + (indexCategoria + 1);
                this.getSubCategoriaNivel(idcategoria, siguienteNivel);
            }
            this.setState({ imagenesRespaldo: [].concat(producto.imagenes) })
            this.getImagesStorage(0, producto)
        })
    }

    handleInputBlur(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let error = { ...this.state.error };
        error = this.setErrorField(error, name, false, '');
        if (error[name].required && (value === '' || value === 0)) {
            error = this.setErrorField(error, name, true, 'Campo obligatirio');
        }
        this.setState({ error })
    }

    async handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let producto = { ...this.state.producto }
        let listadoCategorias = { ...this.state.listadoCategorias }
        let changeNivel = false
        for (let index = 1; index < 5; index++) {
            let nombreNivel = 'nivel' + index;
            if (name === nombreNivel && value) {
                changeNivel = true;
                let indexNivel = parseInt(name.slice(-1)) + 1;
                let nombre = 'nivel' + indexNivel
                if (index < 5) {
                    listadoCategorias[nombre].visible = true;
                    listadoCategorias[nombre].valor = ''
                    for (let indexC = (indexNivel + 1); indexC < 5; indexC++) {
                        let nombreC = 'nivel' + indexC
                        listadoCategorias[nombreC].visible = false;
                        listadoCategorias[nombreC].valor = ''
                    }
                    this.setState({ listadoCategorias })
                    this.getSubCategoriaNivel(value, nombre);
                }
            }
        }
        if (name === 'nivel1') {
            let categoria = await categoriasService.getCategoriaAsync(value)
            let error = {...this.state.error}
            categoria.campos.forEach(campo => {
                producto[campo] = '';
                error[campo] = { error: false, text: '', required: false }
            })
            this.setState({error, producto, categoriaPrincipal: categoria});
        }

        if (changeNivel) {
            listadoCategorias[name].valor = value
            this.setState({ listadoCategorias })
        } else {
            if (name === 'precio') {
                if (!this.validPrecio(value)) {
                    producto[name] = ''
                } else {
                    producto[name] = value
                }
            } else {
                producto[name] = value
                // let fieldsProducts = ['nombre','descripcion','categorias','nickNames','imagenes','precio','stock'];
                // if(fieldsProducts.includes(name)){
                // }else{
                // }
            }

            if(name == 'precio_a_convenir'){
                if(value){
                    producto.precio = '0';
                    let error = {...this.state.error}
                    error.precio = { error: false, text: '', required: false }
                    this.setState({error})
                }
            }

            this.setState({
                producto
            });
        }

    }

    setErrorField(error, name, status, message) {
        error[name].error = status;
        error[name].text = message;
        this.setState({bloquearBoton: status})
        return error;
    }

    getCategoriaBloque(categorias) {
        let listadoCategorias = { ...this.state.listadoCategorias }
        listadoCategorias.nivel1.data = categorias
        this.setState({ listadoCategorias })
        let listadoCategoriasNickname = { ...this.state.listadoCategoriasNickname }
        categorias.forEach(item => {
            listadoCategoriasNickname.nivel1[item.id] = item
        })
        this.setState({ listadoCategoriasNickname })
        if (this.props.params.id_producto != '0') {
            this.setState({ bloquearBoton: false })
            this.getProducto()
        } else {
            this.setState({ loader: true });
        }

    }

    // getCategoriasNivel1() {
    //     categoriasService.getCategoriasNivel1(categorias => {
    //         let listadoCategorias = { ...this.state.listadoCategorias }
    //         listadoCategorias.nivel1.data = categorias
    //         this.setState({ listadoCategorias })
    //         let listadoCategoriasNickname = { ...this.state.listadoCategoriasNickname }
    //         categorias.forEach(item => {
    //             listadoCategoriasNickname.nivel1[item.id] = item
    //         })
    //         this.setState({ listadoCategoriasNickname })
    //         if (this.props.params.id_producto != '0') {
    //             this.getProducto()
    //         } else {
    //             this.setState({ loader: true });
    //         }
    //     })
    // }

    getSubCategoriaNivel(value, nombre) {
        categoriasService.getSubCategoria(value, (categorias) => {
            let listadoCategorias = { ...this.state.listadoCategorias }
            let listadoCategoriasNickname = { ...this.state.listadoCategoriasNickname }
            categorias.forEach(item => {
                listadoCategoriasNickname[nombre][item.id] = item
            })
            this.setState({ listadoCategoriasNickname })
            if (categorias.length > 0) {
                listadoCategorias[nombre].data = categorias
            } else {
                listadoCategorias[nombre].visible = false
            }
            this.setState({ listadoCategorias })
        })
    }

    getImagesStorage(index, producto) {
        let nameImage = producto.imagenes[index];
        firebase.storage().ref(nameImage).getDownloadURL()
            .then((url) => {
                let imagenesPrevisualizar = this.state.imagenesPrevisualizar
                let imagenesFile = this.state.imagenesFile
                imagenesPrevisualizar.push(url);
                imagenesFile.push('0')
                this.setState({ producto, imagenesPrevisualizar, imagenesFile });

                if (index == (producto.imagenes.length - 1)) {
                    this.setState({ loader: true });
                } else {
                    let indexSiguiente = index + 1;
                    this.getImagesStorage(indexSiguiente, producto)
                }
            })
            .catch(error => {
                if (index == (producto.imagenes.length - 1)) {
                    this.setState({ loader: true });
                } else {
                    let indexSiguiente = index + 1;
                    this.getImagesStorage(indexSiguiente, producto)
                }
            })
    }

    handleImageChange = (event) => {
        var _URL = window.URL || window.webkitURL;
        let image = new Image();
        let component = this;
        image.onload = function () {
            if (image.width >= 400 && image.height >= 400) {
                component.subirImagen(event)
            } else {
                toast.warning('La imagen tener minimo 400 px en ancho o en alto')
            }
        };
        image.src = _URL.createObjectURL(event.target.files[0]);
    }

    subirImagen(event) {
        try {
            Resizer.imageFileResizer(
                event.target.files[0],
                1024,
                1024,
                "JPEG",
                70,
                0,
                (uri) => {
                    if (this.state.imagenesPrevisualizar.length < 4) {
                        let imagenesFile = this.state.imagenesFile
                        imagenesFile.push(uri)
                        let imagenesPrevisualizar = this.state.imagenesPrevisualizar
                        imagenesPrevisualizar.push(URL.createObjectURL(uri))
                        let producto = { ...this.state.producto }
                        producto.imagenes.push('nuevo')
                        this.setState({ imagenesFile, imagenesPrevisualizar, producto, bloquearBoton: false });

                    } else {
                        toast.warning('El maximo son 4 imagenes')
                    }
                },
                "file",
                400,
                400
            );
        } catch (err) {
            console.error(err);
        }
    }

    eliminarImagen = (indexi) => () => {
        let imagenesFile = this.state.imagenesFile
        let imagenesPrevisualizar = this.state.imagenesPrevisualizar
        let producto = { ...this.state.producto }
        if (producto.imagenes[indexi] !== 'nuevo') {
            let nameImage = this.state.producto.imagenes[indexi]
            firebase.storage().ref(nameImage).delete().then(res => {
                this.actualizarDeleteImagen(indexi, imagenesFile, imagenesPrevisualizar, producto, this.props.params.id_producto);
            })
        } else {
            imagenesFile.splice(indexi, 1)
            imagenesPrevisualizar.splice(indexi, 1)
            producto.imagenes.splice(indexi, 1)
            this.setState({ imagenesFile, imagenesPrevisualizar, producto })
            toast.info('Imagen eliminada correctamente')
        }
    }

    saveImage(producto, idProducto, imagenes, index) {
        if (imagenes[index] != '0') {
            let secuencial = this.getSecuencialImagen(producto)
            let nameImage = 'images/' + producto.nombreClave + '-' + secuencial + '.jpg';
            firebase.storage().ref(nameImage).put(imagenes[index])
                .then((snapshot) => {
                    producto.imagenes[index] = nameImage
                    if (index == (imagenes.length - 1)) {
                        this.actualizarNombreDeImagenes(producto, idProducto)
                    } else {
                        let indexSiguiente = index + 1
                        this.saveImage(producto, idProducto, imagenes, indexSiguiente)
                    }
                });
        } else {
            if (index == (imagenes.length - 1)) {
                this.actualizarNombreDeImagenes(producto, idProducto)
            } else {
                let indexSiguiente = index + 1
                this.saveImage(producto, idProducto, imagenes, indexSiguiente)
            }
        }

    }

    getSecuencialImagen(producto) {
        let secuenciales = []
        let resultado = 0
        if (producto.imagenes.length > 0) {
            producto.imagenes.forEach(item => {
                if (item !== 'nuevo') {
                    let arrayNombre = item.split('-')
                    let indexUltimo = arrayNombre.length - 1
                    secuenciales.push(parseInt(arrayNombre[indexUltimo]))

                } else {
                    secuenciales.push(0)
                }
            })
            resultado = Math.max(...secuenciales) + 1
        }

        return resultado
    }

    guardar = () => () => {
        this.setState({ bloquearBoton: true })
        let producto = { ...this.state.producto }
        let imagenes = this.state.imagenesFile
        producto.precio = parseFloat(producto.precio)
        producto.provincia = this.state.vendedor.provincia
        producto.ciudad = this.state.vendedor.ciudad
        producto.nombreArray = this.helper.generarArrayNombre(producto.nombre.trim(), ' ')
        producto.nombreClave = ''
        producto.nombreArray.forEach(nombre => {
            producto.nombreClave += nombre + '-';

        })
        producto.nombreClave = producto.nombreClave + this.state.vendedor.codigo;
        this.agregarCategorias(producto)
        producto.bloque = this.state.categoriasBloque[producto.categorias[0]].bloque
        producto.fechaCaducidad = this.state.categoriasBloque[producto.categorias[0]].fechaCaducidad

        let imagenesProducto = []
        producto.imagenes.forEach(item => {
            if(item != 'nuevo'){
                imagenesProducto.push(item);
            }
        })
        producto.imagenes = imagenesProducto;

        if (this.validateFields()) {
            if (this.props.params.id_producto == 0) {
                let fecha = new Date()
                producto.vistas = 0
                producto.idVendedor = firebase.auth().currentUser.uid
                producto.fecha = fecha
                producto.estado = 1
                if (this.validarProductosPlan(producto.bloque)) {
                    this.validarNombreProducto(producto, imagenes)
                } else {
                    toast.warning('A sobrepasado la cantidad de productos permitidos para el plan ' + this.state.categoriasBloque[producto.categorias[0]].nombre)
                }
            } else {
                this.actualizarProducto(producto, this.props.params.id_producto, imagenes)
            }
        }
    }

    agregarCategorias(producto) {
        if (this.state.listadoCategorias.nivel1.valor) {
            producto.categorias[0] = this.state.listadoCategorias.nivel1.valor;
            producto.nickNames[0] = this.state.listadoCategoriasNickname.nivel1[this.state.listadoCategorias.nivel1.valor].nickName
        }
        if (this.state.listadoCategorias.nivel2.valor) {
            producto.categorias[1] = this.state.listadoCategorias.nivel2.valor;
            producto.nickNames[1] = this.state.listadoCategoriasNickname.nivel2[this.state.listadoCategorias.nivel2.valor].nickName
        }
        if (this.state.listadoCategorias.nivel3.valor) {
            producto.categorias[2] = this.state.listadoCategorias.nivel3.valor;
            producto.nickNames[2] = this.state.listadoCategoriasNickname.nivel3[this.state.listadoCategorias.nivel3.valor].nickName
        }
        if (this.state.listadoCategorias.nivel4.valor) {
            producto.categorias[3] = this.state.listadoCategorias.nivel4.valor;
            producto.nickNames[3] = this.state.listadoCategoriasNickname.nivel4[this.state.listadoCategorias.nivel4.valor].nickName
        }
        if (this.state.listadoCategorias.nivel5.valor) {
            producto.categorias[4] = this.state.listadoCategorias.nivel5.valor;
            producto.nickNames[4] = this.state.listadoCategoriasNickname.nivel5[this.state.listadoCategorias.nivel5.valor].nickName
        }
    }

    validarProductosPlan(bloque) {
        let result = true
        if (this.state.vendedor.bloques) {
            let residuo = parseInt(this.state.bloquesUsuario[bloque].inactivos) - parseInt(this.state.vendedor.bloques[bloque])
            if (residuo < 0) {
                residuo = 0;
            }
            let disponible = parseInt(this.state.bloquesUsuario[bloque].total) - residuo
            let cantidadNueva = parseInt(this.state.vendedor.bloques[bloque]) + 1;
            if (cantidadNueva > disponible) {
                result = false
            }
        }
        return result
    }

    validarNombreProducto(producto, imagenes) {
        productosService.getProductoClave(producto.nombreClave, (item) => {
            if (item === undefined) {
                this.insertarProducto(producto, imagenes);
            } else{
                this.setState({bloquearBoton: false})
                toast.warning('Ya ingreso un producto con el mismo nombre')
            }
        })
    }

    insertarProducto(data, imagenes) {
        productosService.addRegister(data, (doc) => {
            this.actualizarCantidadProductosUsuario(data, doc, imagenes)
        })
    }

    actualizarCantidadProductosUsuario(data, idProducto, imagenes) {
        let bloques = {}
        if (this.state.vendedor.bloques) {
            bloques = this.state.vendedor.bloques
        }
        if (bloques[data.bloque]) {
            bloques[data.bloque] += 1
        } else {
            bloques[data.bloque] = 1
        }

        let dataUsuario = { bloques: bloques }
        userService.updateRegister(firebase.auth().currentUser.uid, dataUsuario, () => {
            this.saveImage(data, idProducto, imagenes, 0)
        })
    }

    actualizarProducto(data, id, imagenes) {
        productosService.updateRegister(id, data, () => {
            this.saveImage(data, id, imagenes, 0)
        })
    }

    actualizarDeleteImagen(indexi, imagenesFile, imagenesPrevisualizar, producto, id) {
        imagenesFile.splice(indexi, 1)
        imagenesPrevisualizar.splice(indexi, 1)
        producto.imagenes.splice(indexi, 1)
        let interval = setInterval(() => {
            productosService.updateRegister(id, producto, () => {
                clearInterval(interval)
                this.setState({ imagenesFile, imagenesPrevisualizar, producto })
                toast.info('Imagen eliminada correctamente')
            })
        }, 100)
    }

    actualizarNombreDeImagenes(producto, id) {
        let data = { imagenes: producto.imagenes }
        productosService.updateRegister(id, data, () => {
            this.generateSiteMapUser('', '', '')
        })
    }

    generateSiteMapUser(data, doc, imagenes){
        // fetch('http://127.0.0.1:5001/tienda-54320/us-central1/userSitemap/'+firebase.auth().currentUser.uid, {
        fetch('https://us-central1-tienda-54320.cloudfunctions.net/userSitemap/'+firebase.auth().currentUser.uid, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            },
        })
        .then(res => res.json())
        .then((result) => {
            this.setState({ bloquearBoton: false })
            toast.info('Producto registrado correctamente')
            this.props.navigate('/')
        })
        .catch(console.error)
    }

    validPrecio(precio) {
        let result = false;
        if (!isNaN(precio)) {
            result = true;
        }
        return result;
    }

    validateFields() {
        let validation = true;
        let error = { ...this.state.error };

        if (this.state.producto.nombre === '') {
            error.nombre.error = true;
            error.nombre.text = 'Campo obligatorio';
            validation = false;
            toast.error('El campo nombre es obligatorio');
        }
        if (this.state.producto.stock === '') {
            error.stock.error = true;
            error.stock.text = 'Campo obligatorio';
            validation = false;
            toast.error('El campo stock es obligatorio');
        }

        if (this.state.producto.descripcion === '') {
            error.descripcion.error = true;
            error.descripcion.text = 'Campo obligatorio';
            validation = false;
            toast.error('El campo descripcion es obligatorio');
        }

        if (this.state.producto.categorias.length === 0) {
            validation = false;
            toast.error('Debe elegir las categorias, para continuar');

        }
        if (!this.validPrecio(this.state.producto.precio)) {
            error.precio.error = true;
            error.precio.text = 'Debe ingresar números ';
            validation = false;
        }


        if (this.state.imagenesPrevisualizar.length === 0) {
            validation = false;
            toast.error('Debe subir una imagen, para continuar');
        }

        return validation
    }

    render() {
        const { params } = this.props
        if (this.state.loader) {
            let showSellConditions = false
            if(this.state.listadoCategorias.nivel1.valor){
                this.state.categoriaPrincipal.campos.map((campo, indexCamp) => {
                    if(campo === 'forma_pago' || campo === 'costo_envio' || campo === 'tiempo_entrega'){
                        showSellConditions = true
                    }
                })
            }

            let sm = 6;
            if(this.state.categoriaPrincipal.nombre == 'SERVICIOS'){
                sm = 4;
            }
            return (
                <Container>
                    <div className="register-div">
                        <Card style={{ marginBlockEnd: 80 }}>
                            <CardHeader title="NUEVO PRODUCTO" style={{ fontSize: '20px' }} />

                            <CardContent>
                                <div className='recordatorio'> 
                                    Recuerde que todos sus productos con excepción de: vehículos, libros y antigüedades deben ser NUEVOS
                                </div>
                                <div className='title-producto'>Elija las Categorías y subcategorías que corresponde a su producto</div>
                                <TextField
                                    select
                                    name='nivel1'
                                    label='Categoria Nivel 1'
                                    value={this.state.listadoCategorias.nivel1.valor ? this.state.listadoCategorias.nivel1.valor : ""}
                                    error={this.state.error.nivel1.error}
                                    helperText={this.state.error.nivel1.error ? this.state.error.nivel1.text : ''}
                                    required={this.state.error.nivel1.required}
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    onChange={this.handleInputChange}
                                    onBlur={this.handleInputBlur}
                                    disabled={params.id_producto != '0'}
                                >
                                    {this.state.listadoCategorias.nivel1.data.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.nombre}
                                        </MenuItem>

                                    ))}
                                </TextField>
                                {this.state.listadoCategorias.nivel2.visible &&
                                    <TextField
                                        select
                                        name='nivel2'
                                        label='Categoria Nivel 2'
                                        value={this.state.listadoCategorias.nivel2.valor ? this.state.listadoCategorias.nivel2.valor : ""}
                                        error={this.state.error.nivel2.error}
                                        helperText={this.state.error.nivel2.error ? this.state.error.nivel2.text : ''}
                                        required={this.state.error.nivel2.required}
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputBlur}
                                    >
                                        {this.state.listadoCategorias.nivel2.data.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.nombre}
                                            </MenuItem>

                                        ))}
                                    </TextField>
                                }
                                {this.state.listadoCategorias.nivel3.visible &&
                                    <TextField
                                        select
                                        name='nivel3'
                                        label='Categoria Nivel 3'
                                        value={this.state.listadoCategorias.nivel3.valor ? this.state.listadoCategorias.nivel3.valor : ""}
                                        error={this.state.error.nivel3.error}
                                        helperText={this.state.error.nivel3.error ? this.state.error.nivel3.text : ''}
                                        required={this.state.error.nivel3.required}
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputBlur}
                                    >
                                        {this.state.listadoCategorias.nivel3.data.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.nombre}
                                            </MenuItem>

                                        ))}
                                    </TextField>
                                }

                                {this.state.listadoCategorias.nivel4.visible &&
                                    <TextField
                                        select
                                        name='nivel4'
                                        label='Categoria Nivel 4'
                                        value={this.state.listadoCategorias.nivel4.valor ? this.state.listadoCategorias.nivel4.valor : ""}
                                        error={this.state.error.nivel4.error}
                                        helperText={this.state.error.nivel4.error ? this.state.error.nivel4.text : ''}
                                        required={this.state.error.nivel4.required}
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputBlur}
                                    >
                                        {this.state.listadoCategorias.nivel4.data.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.nombre}
                                            </MenuItem>

                                        ))}
                                    </TextField>
                                }

                                {this.state.listadoCategorias.nivel5.visible &&
                                    <TextField
                                        select
                                        name='nivel5'
                                        label='Categoria Nivel 5'
                                        value={this.state.listadoCategorias.nivel5.valor ? this.state.listadoCategorias.nivel5.valor : ""}
                                        error={this.state.error.nivel5.error}
                                        helperText={this.state.error.nivel5.error ? this.state.error.nivel5.text : ''}
                                        required={this.state.error.nivel5.required}
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputBlur}
                                    >
                                        {this.state.listadoCategorias.nivel5.data.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.nombre}
                                            </MenuItem>

                                        ))}
                                    </TextField>
                                }
                                <div>
                                    <div className='title-producto'>
                                        {this.state.categoriaPrincipal.nombre == 'SERVICIOS'?
                                            'Identifique su servicio':'Identifique lo que desea vender'
                                        }
                                        
                                    </div>
                                    <TextField
                                        name='nombre'
                                        label={this.state.categoriaPrincipal.nombre == 'SERVICIOS'? 'Nombre del servicio': 'Nombre del producto'}
                                        value={this.state.producto.nombre ? this.state.producto.nombre : ""}
                                        error={this.state.error.nombre.error}
                                        helperText={this.state.error.nombre.error ? this.state.error.nombre.text : ''}
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                        required={this.state.error.nombre.required}
                                        onChange={this.handleInputChange}
                                        onBlur={this.handleInputBlur}
                                    />
                                    <div className='title-producto' style={{marginBottom: 22}}>
                                        {this.state.categoriaPrincipal.nombre == 'SERVICIOS'?
                                            'Datos específicos del servicio':'Datos específicos del producto'
                                        }
                                        
                                    </div>
                                    <div style={{marginBottom: 15}}>
                                        <Grid container spacing={2}>
                                            {this.state.categoriaPrincipal.nombre == 'SERVICIOS' &&
                                                <Grid item container xs={12} sm={sm} style={{paddingTop: 0, alignItems: 'center'}}>
                                                    <Grid item xs={6} sm={6}>
                                                        Precio a convenir
                                                    </Grid>
                                                    <Grid item xs={6} sm={6}>
                                                        <Switch
                                                            name='precio_a_convenir'
                                                            checked={this.state.producto.precio_a_convenir}
                                                            onChange={this.handleInputChange}
                                                            size="small"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid item xs={12} sm={sm} style={{paddingTop: 0}}>
                                                <TextField
                                                    name='precio'
                                                    label={this.state.categoriaPrincipal.nombre == 'SERVICIOS'? 'Valor del servicio': 'Precio'}
                                                    value={this.state.producto.precio ? this.state.producto.precio : ""}
                                                    error={this.state.error.precio.error}
                                                    helperText={this.state.error.precio.error ? this.state.error.precio.text : ''}
                                                    fullWidth
                                                    margin="dense"
                                                    size="small"
                                                    disabled={this.state.producto.precio_a_convenir}
                                                    required={this.state.error.precio.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={sm} style={{paddingTop: 0}}>
                                                <TextField
                                                    select
                                                    name='stock'
                                                    label={this.state.categoriaPrincipal.nombre == 'SERVICIOS'? 'Elija la disponibilidad del servicio': 'Elija el stock de su producto'}
                                                    value={this.state.producto.stock ? this.state.producto.stock : ""}
                                                    error={this.state.error.stock.error}
                                                    helperText={this.state.error.stock.error ? this.state.error.stock.text : ''}
                                                    required={this.state.error.stock.required}
                                                    fullWidth
                                                    margin="dense"
                                                    size="small"
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}

                                                >
                                                    {this.state.listadoStock.map((option) => (
                                                        <MenuItem key={option.estado} value={option.estado}>
                                                            {option.estado == 'Agotado' && this.state.categoriaPrincipal.nombre == 'SERVICIOS' ? 'No disponible': option.estado}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <Grid container spacing={2}>
                                        {this.state.listadoCategorias.nivel1.valor && 
                                            this.state.categoriaPrincipal.campos.map((campo, indexCamp) => {
                                                if(campo != 'forma_pago' && campo != 'costo_envio' && campo != 'tiempo_entrega' && campo != 'modalidad'){
                                                    return (
                                                        <Grid item xs={12} sm={4} style={{paddingTop: 0}}>
                                                            <TextField
                                                                key={'Input'+indexCamp}
                                                                name={campo}
                                                                label={this.state.listasCampos[campo]}
                                                                value={this.state.producto[campo] ? this.state.producto[campo]: ""}
                                                                error={this.state.error[campo].error}
                                                                helperText={this.state.error[campo].error ? this.state.error[campo].text : ''}
                                                                fullWidth
                                                                margin="dense"
                                                                size="small"
                                                                required={this.state.error[campo].required}
                                                                onChange={this.handleInputChange}
                                                                onBlur={this.handleInputBlur}
                                                            />
                                                        </Grid>
                                                    )
                                                }else{
                                                    if(campo == 'modalidad'){
                                                        return (
                                                            <Grid item xs={12} sm={4} style={{paddingTop: 0}}>
                                                                <TextField
                                                                    select
                                                                    key={'Input'+indexCamp}
                                                                    name={campo}
                                                                    label={this.state.listasCampos[campo]}
                                                                    value={this.state.producto[campo] ? this.state.producto[campo]: ""}
                                                                    error={this.state.error[campo].error}
                                                                    helperText={this.state.error[campo].error ? this.state.error[campo].text : ''}
                                                                    fullWidth
                                                                    margin="dense"
                                                                    size="small"
                                                                    required={this.state.error[campo].required}
                                                                    onChange={this.handleInputChange}
                                                                    onBlur={this.handleInputBlur}
                                                                >
                                                                    <MenuItem value='Venta'>Venta</MenuItem>
                                                                    <MenuItem value='Arriendo'>Arriendo</MenuItem>
                                                                    <MenuItem value='Temporal'>Temporal</MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                        )
                                                    }
                                                }
                                            })
                                        }
                                    </Grid>
                                    <div>
                                        <TextField
                                            name='descripcion'
                                            label={this.state.categoriaPrincipal.nombre == 'SERVICIOS'? 'Descripción del servicio': 'Descripción del producto'}
                                            value={this.state.producto.descripcion ? this.state.producto.descripcion : ""}
                                            error={this.state.error.descripcion.error}
                                            helperText={this.state.error.descripcion.error ? this.state.error.descripcion.text : ''}
                                            fullWidth
                                            margin="dense"
                                            required={this.state.error.descripcion.required}
                                            onChange={this.handleInputChange}
                                            onBlur={this.handleInputBlur}
                                            multiline
                                            rows={10}

                                        />
                                    </div>
                                    {showSellConditions && 
                                        <div className='title-producto' style={{marginBottom: 22}}>Condiciones de venta</div>
                                    }
                                    <Grid container spacing={2}>
                                        {this.state.listadoCategorias.nivel1.valor && 
                                            this.state.categoriaPrincipal.campos.map((campo, indexCamp) => {
                                                if(campo === 'forma_pago' || campo === 'costo_envio' || campo === 'tiempo_entrega'){
                                                    return (
                                                        <Grid item xs={12} sm={4} style={{paddingTop: 0}}>
                                                            <TextField
                                                                key={'Input'+indexCamp}
                                                                name={campo}
                                                                label={this.state.listasCampos[campo]}
                                                                value={this.state.producto[campo] ? this.state.producto[campo]: ""}
                                                                error={this.state.error[campo].error}
                                                                helperText={this.state.error[campo].error ? this.state.error[campo].text : ''}
                                                                fullWidth
                                                                margin="dense"
                                                                size="small"
                                                                required={this.state.error[campo].required}
                                                                onChange={this.handleInputChange}
                                                                onBlur={this.handleInputBlur}
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                            })
                                        }
                                    </Grid>
                                    <div className='title-producto' style={{marginBottom: 12}}>Agrega máximo 4 imágenes</div>
                                    <div>
                                        <label htmlFor="contained-button-file">
                                            <input accept="image/*" id="contained-button-file" multiple type="file" hidden onChange={(event) => this.handleImageChange(event)} />
                                            <Button variant='contained' aria-label="Agregar imagen" component="span">
                                                Agregar imagenes
                                            </Button>
                                        </label>
                                    </div>
                                    {this.state.imagenesPrevisualizar.length > 0 &&
                                        <div>
                                            {this.state.imagenesPrevisualizar.map((item, indexi) => (
                                                <div key={indexi + 'IP'} style={{ marginTop: 20 }}>
                                                    <Grid container>
                                                        <Grid item xs={11} md={11}>
                                                            <img src={item} width={150} alt='Imagen Producto'/>
                                                        </Grid>
                                                        <Grid item xs={1} md={1}>
                                                            <IconButton style={{ color: 'red' }} onClick={this.eliminarImagen(indexi)}>
                                                                <DeleteOutlineOutlinedIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            ))}
                                        </div>

                                    }
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <h3>*Antes de guardar verifique que los datos esten correctos</h3>
                                </div>
                                <div style={{ marginTop: 20, textAlign: 'center' }}>
                                    <ColorButton style={{ width: '30%', marginRight: 10 }}
                                        variant="contained"
                                        disabled={this.state.bloquearBoton}
                                        onClick={this.guardar()}


                                    >Guardar</ColorButton>
                                    <ColorButton style={{ width: '30%' }}
                                        variant="contained"
                                        disabled={this.state.blockButton}
                                        onClick={() => { this.props.navigate('/') }}

                                    >Regresar</ColorButton>

                                </div>
                            </CardContent>
                        </Card>
                    </div>

                </Container>
            )
        } else {
            return (<Preloader />);
        }
    }

}