import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem, ListItemIcon, Avatar } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Logout from '@mui/icons-material/Logout';
import '../App.css';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { userService } from '../services/user.service';

export default class UserNav extends Component {

    constructor() {
        super();
        this.state = {
            user: '',
            openMenuUser: false,
            anchorEl: null,
            isLogin: false,
            isAdmin: false
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user1) => {
            if (user1 !== null) {
                if (user1.emailVerified) {
                    let user = firebase.auth().currentUser;
                    this.validAdmin(user);
                }
            }
        })
    }

    validAdmin(user) {
        firebase.firestore().collection('admin')
            .get()
            .then(snapshot => {
                snapshot.forEach(element => {
                    if (element.data().email == firebase.auth().currentUser.email) {
                        this.setState({ isAdmin: true });
                    } else {
                        this.setState({ isAdmin: false });
                    }
                })
                this.setState({ user, isLogin: true });
            });
    }

    handleClick(event) {
        this.setState({
            anchorEl: event.currentTarget,
            openMenuUser: !this.state.openMenuUser
        })
    };

    handleClose() {
        this.setState({ anchorEl: null, openMenuUser: false })
    };

    redirect = (page) => () => {
        this.props.navigate(page);
    }

    render() {
        if (this.state.isLogin) {
            return (
                <div>
                    <Button
                        variant="text"
                        style={{ color: '#000' }}
                        endIcon={<Avatar src={this.state.user.photoURL} />}
                        aria-label={this.state.user.displayName}
                        aria-controls={this.state.openMenuUser ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={this.state.openMenuUser ? 'true' : undefined}
                        onClick={this.handleClick}
                    >
                        {this.state.user.displayName}
                    </Button>
                    <Menu
                        anchorEl={this.state.anchorEl}
                        id="account-menu"
                        open={this.state.openMenuUser}
                        onClose={this.handleClose}
                        onClick={this.handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >

                        {this.state.isAdmin &&
                            <MenuItem component={Link} to="/perfil">
                                <ListItemIcon>
                                    <PersonOutlineIcon fontSize="small" />
                                </ListItemIcon>
                                Mi perfil
                            </MenuItem>
                        }

                        {!this.state.isAdmin &&
                            <MenuItem component={Link} to="/perfil">
                                <ListItemIcon>
                                    <PersonOutlineIcon fontSize="small" />
                                </ListItemIcon>
                                Mi perfil
                            </MenuItem>
                        }

                        {this.state.isAdmin &&
                            <MenuItem component={Link} to="/categorias">
                                <ListItemIcon>
                                    <AccountTreeIcon fontSize="small" />
                                </ListItemIcon>
                                Categorias
                            </MenuItem>
                        }

                        {this.state.isAdmin &&
                            <MenuItem component={Link} to="/usuarios">
                                <ListItemIcon>
                                    <PeopleOutlineIcon fontSize="small" />
                                </ListItemIcon>
                                Usuarios
                            </MenuItem>
                        }
                        {this.state.isAdmin &&
                            <MenuItem component={Link} to="/planes">
                                <ListItemIcon>
                                    <PeopleOutlineIcon fontSize="small" />
                                </ListItemIcon>
                                Planes
                            </MenuItem>
                        }
                        {this.state.isAdmin &&
                            <MenuItem component={Link} to="/publicidad">
                                <ListItemIcon>
                                    <PeopleOutlineIcon fontSize="small" />
                                </ListItemIcon>
                                Publicidad
                            </MenuItem>
                        }

                        {this.state.isAdmin &&
                            <MenuItem component={Link} to="/denuncias">
                                <ListItemIcon>
                                    <PeopleOutlineIcon fontSize="small" />
                                </ListItemIcon>
                                Denuncias
                            </MenuItem>
                        }

                        <MenuItem component={Link} to="/logout">
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Salir
                        </MenuItem>
                    </Menu>
                </div>
            )
        } else {
            return null;
        }
        // else{
        //     return(
        //         <div style={{padding: 10}}>
        //             <Button 
        //                 variant="outlined" 
        //                 size="small"
        //                 color="primary"
        //                 onClick={this.redirect('/')}
        //             >
        //                 Inicia sesión
        //             </Button>
        //             <Button 
        //                 variant="contained" 
        //                 size="small"
        //                 color="primary"
        //                 style={{marginLeft: 15}}
        //                 onClick={this.redirect('/register')}
        //             >
        //                 Registrate
        //             </Button>
        //         </div>
        //     )
        // }
    }
}
