import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  IconButton,
  CardHeader,
  Typography,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Helper from "../utils/Helper";

class CourseCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen1: false,
      tooltipOpen2: false,
      permission: false,
      imagenProducto: "",
    };
    this.helper = new Helper()
  }
  getImagesStorage(urlImage) {
    let url = this.helper.getImagenResize(urlImage, '400x400', false);
    this.setState({ imagenProducto: url });
  }

  componentDidMount() {
    if (this.props.item.imagenes) {
      this.getImagesStorage(this.props.item.imagenes[0]);
    }
  }

  render() {
    const { item, editable, editarProducto, eliminarProducto } = this.props;
    if (editable) {
      return (
        <Card variant="outlined" className="card-productos">
          <CardHeader
            subheader={
              <Typography style={{ fontWeight: "bold" }}>
                {"$" + item.precio}
              </Typography>
            }
            style={{ textAlign: "right" }}
          />
          <CardMedia
            component="img"
            height="200"
            image={this.state.imagenProducto}
            sx={{ objectFit: "contain" }}
            alt={item.nombreClave}
            className="card-productos1"
          />
          <CardContent style={{ paddingTop: 10, paddingBottom: 0 }}>
            <div className="title-item-block1" style={{ height: 60 }}>
              {item.nombre}
            </div>
            
            {editable && (
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Vistas : {item.vistas}
              </div>
            )}
          </CardContent>
          <CardActions>
            {editable && (
              <div>
                <IconButton
                  style={{ color: "blue" }}
                  onClick={editarProducto}
                >
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton
                  style={{ color: "red" }}
                  onClick={eliminarProducto}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </div>
            )}
          </CardActions>
        </Card>
      );
    } else {
      return(
      <Link to={"/detalle/" + item.nombreClave} style={{textDecoration: 'none'}}>
        <Card variant="outlined" className="card-productos">
          <CardHeader
            subheader={
              <Typography style={{ fontWeight: "bold" }}>
                {"$" + item.precio}
              </Typography>
            }
            style={{ textAlign: "right" }}
          />
          <CardMedia
            component="img"
            height="200"
            image={this.state.imagenProducto}
            sx={{ objectFit: "contain" }}
            alt={item.nombreClave}
            className="card-productos1"
          />
          <CardContent style={{ paddingTop: 10, paddingBottom: 0 }}>
            <div className="title-item-block1" style={{ height: 30 }}>
              {item.nombre}
            </div>
          </CardContent>
        </Card>
      </Link>
      )
    }
  }
}
export default CourseCard;
