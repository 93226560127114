import React, { Component } from "react";
import { Grid, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import "../../App.css";
import Helper from "../../utils/Helper";
import Footer from "../../components/Footer";
import SectionSlider from "../../components/SectionSlider";
import Publicidades from "../../components/principal/Publicidades";
import CategoriasInicio from "../../components/principal/CategoriasInicio";
import ProductosInicio from "../../components/principal/ProductosInicio";

export default class Wellcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productos: [],
      posicionScroll: 0,
      activarSeccion: [true, false, false, false, false]
    };
    this.helper = new Helper();
    this.scrollPosition = this.scrollPosition.bind(this);
    this.contadorScroll = 0
  }

  redirect = (page) => () => {
    this.props.navigate(page);
  };

  scrollPosition(event) {
    this.setState({ posicionScroll: event.currentTarget.scrollTop })
    let element = document.getElementById('divPrincipal');
    if (element.offsetHeight + element.scrollTop + 1 >= element.scrollHeight) {
      this.contadorScroll++
      let activarSeccion = this.state.activarSeccion
      activarSeccion[this.contadorScroll] = true
      this.setState({ activarSeccion })
    }
  }

  render() {
    let heightBlock = window.screen.height - 60 - 144;
    return (
      <div id='divPrincipal' className='container-div' style={{ height: heightBlock }} onScroll={this.scrollPosition}>
        <div>
          <SectionSlider />
        </div>
        {this.state.activarSeccion[0] && <Publicidades />}
        {this.state.activarSeccion[1] && <ProductosInicio navigate={this.props.navigate} />}
        {this.state.activarSeccion[2] && <CategoriasInicio />}
        {this.state.activarSeccion[3] && <div style={{ backgroundColor: "#1b5583", padding: 50 }}>
          <div className="cuadros">
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper elevation={3} style={{ height: 200, width: 300 }}>
                  <div>
                    <p
                      style={{ textAlign: "center", fontFamily: "Fjalla One" }}
                    >
                      {" "}
                      ¿ CÓMO PAGA SUS COMPRAS ?{" "}
                    </p>
                    <center>
                      <img src="assets/tarjeta.png" alt="Como pagar" height="50px" />
                    </center>
                  </div>
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        fontFamily: "Cormorant Garamond",
                      }}
                    >
                      Pague fácilmente sus compras{" "}
                    </p>
                    <center>
                      <Link style={{ color: "#00AAE4" }} to="/compras">
                        Ver más
                      </Link>{" "}
                    </center>
                  </div>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper elevation={3} style={{ height: 200, width: 300 }}>
                  <div>
                    <p
                      style={{ textAlign: "center", fontFamily: "Fjalla One" }}
                    >
                      {" "}
                      ¿ CÓMO Y CUANDO RECIBE SUS COMPRAS ?{" "}
                    </p>
                    <center>
                      <img src="assets/recibido.png" alt="Como comprar" height="50px" />
                    </center>
                    <p
                      style={{
                        textAlign: "center",
                        fontFamily: "Cormorant Garamond",
                      }}
                    >
                      Conozca las formas de envío{" "}
                    </p>
                  </div>
                  <div>
                    <center>
                      {" "}
                      <Link style={{ color: "#00AAE4" }} to="/entregas">
                        Ver más
                      </Link>
                    </center>
                  </div>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper elevation={3} style={{ height: 200, width: 300 }}>
                  <div>
                    <p
                      style={{ textAlign: "center", fontFamily: "Fjalla One" }}
                    >
                      {" "}
                      SEGURIDAD EN SUS COMPRAS{" "}
                    </p>
                    <center>
                      <img src="assets/escudo.png" alt="Seguridad" height="50px" />
                    </center>
                  </div>
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        fontFamily: "Cormorant Garamond",
                      }}
                    >
                      {" "}
                      La seguridad en sus compras es lo más importante para
                      nosotros
                    </p>
                    <center>
                      {" "}
                      <Link style={{ color: "#00AAE4" }} to="/seguridad">
                        Ver más
                      </Link>{" "}
                    </center>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
        }
        {this.state.activarSeccion[4] && <Footer />}
      </div>
    );
  }
}
