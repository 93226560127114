import React, { useState, Component } from "react";
import { Slide } from "react-slideshow-image";

import { styled } from '@mui/material/styles';


import '../App.css';
const SectionSlider1 = (data) => {
  
  const [autoplay, setAutoplay] = useState(true);
  return (
      <Slide autoplay={autoplay}>
        {data && data.imagenes.map((item, indice) =>(
          <div className='imagen-producto' key={'im'+indice}>
             <img src={item} className="imagen-detalle" alt="" />
          </div>
        ))}
      </Slide>
  );
};

export default SectionSlider1;