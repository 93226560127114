import * as React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";

export default function ResponsiveGrid(data) {
  const navigate = useNavigate();
  const irProductos = (item) => () => {
    navigate('/productos/C/' + item.nickName)
  }
  return (
    <Box sx={{ flexGrow: 1 }}>

      <div className="container-categorias">
        {data && data.categorias.map((item, index) => (
          <div className="item-categorias" key={index + 'CT'}>
            <div style = {{backgroundColor: '#fff', padding: 20, borderRadius: 5, fontWeight:'bold'}} onClick={irProductos(item)} >
              <div className="title" style={{ textAlign: 'center' }} >{item.nombre} </div>
              <img src={'assets/' + item.imagen} alt={item.nombre} />
            </div>
          </div>
        ))}

      </div>
    </Box>
  );
}
