import React, { Component } from 'react';
import { CardActions, CardContent, Grid, Card, Container, CardHeader, Button, Typography } from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import MuiAlert from '@mui/material/Alert';

import { planesService } from '../../services/planes.service';
import { bloquesService } from '../../services/bloques.service';
import { userService } from '../../services/user.service';
import Preloader from '../../components/Preloader';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



export default class PlanesPago extends React.Component {
    constructor() {
        super()
        this.state = {
            planes: [],
            bloques: [],
            mensajePendiente: false,
            loader: true,
        }
    }
    componentDidMount() {
        this.setState({loader: true})
        this.getUser()
    }

    getUser() {
        userService.getUser(firebase.auth().currentUser.uid).then(res => {
            let usuario = res.data()
            if (usuario.subscripcion == 'pendiente') {
                this.setState({ mensajePendiente: true })
            }
            let planesUsuario = [];
            if(usuario.planes){
                usuario.planes.forEach(plan => {
                    planesUsuario.push(plan.plan.id);
                })
            }
            
            this.getBloques(planesUsuario)
        })
    }

    getBloques(planesUsuario) {
        bloquesService.getRegistersOrden().get().then(async (res) => {
            let bloques = []
            res.forEach(item => {
                let bloque = item.data();
                bloque.id = item.id
                bloque.nombresCategorias = '';
                bloque.categorias.forEach(categoria => {
                    bloque.nombresCategorias += categoria.nombre + ', ';
                })

                bloque.nombresCategorias = bloque.nombresCategorias.slice(0, -2);
                bloques.push(bloque);
            })
            for (let index = 0; index < bloques.length; index++) {
                let item = bloques[index]
                item.planes = await planesService.getPlanesBloquesAsync(item.id)
            }

            bloques.forEach(bloque => {
                let planesFinales = [];
                bloque.planes.forEach(plan => {
                    
                    if(!planesUsuario.includes(plan.id)){
                        planesFinales.push(plan);
                    }
                })
                bloque.planes = planesFinales;
            })

            this.setState({ bloques, loader: false });
        })
    }

    irAFacturacion = (plan) => () => {
        this.props.navigate('/facturacion/' + plan.id);
    }

    back = () => () => {
        this.props.navigate('/');
    }

    render() {
        if(this.state.loader){
            return (<Preloader></Preloader>)
        }else{

            return (
                <Container className='pagos' style={{ paddingTop: 30, marginTop: 30}}>
                    {this.state.mensajePendiente &&
                     <Alert severity="info">Su plan esta en proceso</Alert>
                    }

                    <div style={{ marginBottom: 40, color: '#000' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <h3>ELIJA EL MEJOR PLAN PARA SU NEGOCIO</h3>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{display: 'flex',alignItems: 'center', justifyContent: 'end'}}>
                                <Button variant="text" color='primary' onClick={this.back()} aria-label='Regresar'>
                                    <b>Regresar </b>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    {this.state.bloques.map((bloque, indexB) => (
                        <Card key={'B' + indexB} style={{ marginBottom: 10, marginBlockEnd:50  }}>
                            <CardHeader className='pagos1' title= {<Typography style={{fontWeight: 'bold',fontSize: 25 }}>{bloque.nombre}</Typography>} subheader={bloque.nombresCategorias} style={{ textAlign: 'center' }} />
                            <CardContent className='pagos2'>
                                <div className='container-planes-pagos'>
                                    {bloque.planes.map((plan, indexP) => (
                                        <Card key={'Plan' + indexP} className='plan-pago'>
                                            <CardContent>
                                                <div className='tituloPlanes'>
                                                    {plan.nombre}
                                                </div>
    
                                                <div className='text-precio1'>
                                                    ${plan.precio}
                                                </div>
                                                <ul style={{height: 250, overflowY: 'auto'}}>
                                                    <li>{`${plan.meses} MESES DE PUBLICIDAD`}</li>
                                                    <li>{`${plan.gratuidad} MES(ES) GRATIS DE PUBLICIDAD - PROMOCIÓN`} </li>
                                                    <li>{`${plan.cantidadProductos} PRODUCTOS A PUBLICAR`}</li>
                                                    {plan.descripcionesAdicionales.map((descripcion, indexD) => (
                                                        <li key={'D' + indexD}> {descripcion}</li>
                                                    ))}
                                                </ul>
                                            </CardContent>
                                            <CardActions >
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                    <Button variant="outlined" color='primary' onClick={this.irAFacturacion(plan)} aria-label='Comprar'>
                                                        <b>Comprar </b>
                                                    </Button>
                                                </div>
    
                                            </CardActions>
                                        </Card>
                                    ))}
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </Container>
            )
        }
    }

}