import React, { Component } from "react";
import {
  Container,
  Grid,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Card,
  CardContent,
  Fab,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import "../../App.css";
//import fondo from '../../assets/fondologin.jpg';

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "firebase/auth";

const styles = {
  paperContainer: {
    // backgroundImage: `url(${fondo})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
};

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      showPassword: "",
      errorEmail: false,
      errorPass: false,
      message: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
    this.login = this.login.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  login() {
    let validation = this.validateFields();
    if (validation) {
      let auth = getAuth();
      signInWithEmailAndPassword(auth, this.state.email, this.state.password)
        .then((user) => {
          if (user.user.emailVerified) {
            this.props.navigate("/");
          } else {
            toast.warning(
              "Debe verificar su cuenta para poder ingresar, verifique que el email que le enviamos no este en spam y haga click en el link de verificación"
            );
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorCode === "auth/wrong-password") {
            toast.error("Contraseña incorrecta");
          } else {
            // toast.error('El usuario no existe por favor registrese, si ya se registro verifique su cuenta para poder iniciar sesion. Si tuvo problemas con la verificacion comuniquese con nosotros');
            toast.error("El usuario no existe por favor registrese");
          }
        });
    }
  }

  validateFields() {
    let validation = true;

    if (this.state.email == "") {
      toast.error("El campo email es obligatorio");
      validation = false;
    } else {
      let validEmail = /@/.test(this.state.email);
      if (!validEmail) {
        toast.error("Email incorrecta");
        validation = false;
      }
    }
    if (this.state.password == "") {
      toast.error("El campo contraseña es obligatorio");
      validation = false;
    } else {
      if (this.state.password.length < 6) {
        toast.error("La contraseña debe tener mas de 6 caracteres");
        validation = false;
      }
    }
    return validation;
  }

  handleClickShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="container-div" 
        style={{
            backgroundImage: `url("/assets/IMAGEN1.webp")`, 
            backgroundRepeat: 'no-repeat', 
            backgroundSize: 'cover'
        }}
    >
        <Container>
          <div className="login-div">
            <Card>
              <CardContent>
                <div>
                  <div className="div-element">
                    <img
                      src="/assets/logo-prueba1.png"
                      alt="Da Comprando"
                      width={160}
                      height={90}
                    />
                    <h3>BIENVENIDO</h3>
                    <h4>Iniciar Sesión</h4>
                  </div>
                  <div className="div-element">
                    <FormControl variant="outlined" fullWidth margin="normal">
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <OutlinedInput
                        id="email"
                        name="email"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        label="Email"
                        error={this.state.errorEmail}
                      />
                      {this.state.errorEmail && (
                        <FormHelperText style={{ color: "red" }}>
                          We'll never share your email.
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl variant="outlined" fullWidth margin="normal">
                      <InputLabel htmlFor="password">Contraseña</InputLabel>
                      <OutlinedInput
                        id="password"
                        name="password"
                        type={this.state.showPassword ? "text" : "password"}
                        value={this.state.password}
                        onChange={this.handleInputChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end"
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Contraseña"
                      />
                    </FormControl>
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <Button
                      style={{ width: "100%" }}
                      variant="contained"
                      onClick={this.login.bind()}
                      aria-label="Ingresar"
                    >
                      Ingresar
                    </Button>
                  </div>
                  <div className="div-element">
                    <Link className="link-text" to="/restablecerpassword">
                      Olvidó su contraseña?
                    </Link>
                  </div>
                  <div className="div-element">
                    ¿No tiene una cuenta?{" "}
                    <Link className="link-text" to="/register">
                      Regístrese
                    </Link>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Link className="link-text" to="http://dacomprando.com.ec/">
                      Regresar
                    </Link>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </Container>
      </div>
    );
  }
}
