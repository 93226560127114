let data = {
  PayboxRemail: "ventasdacomprando@gmail.com",
  PayboxSendmail: "",
  PayboxRename: "Da comprando",
  PayboxSendname: "",
  PayboxBase0: "0",
  PayboxBase12: "0",
  PayboxDescription: "",
  PayboxProduction: true,
  PayboxEnvironment: "prod",
  PayboxLanguage: "es",
  PayboxPagoPlux: true,
  PayboxDirection: "",
  PayBoxClientPhone: "",
  PayBoxClientIdentification: "",
  onAuthorize: (response) => {
    if (response.status === "succeeded") {
      let data_pay = JSON.parse(localStorage.getItem('data_pay'))
      let gratuidad = 0
      if (data_pay.plan.gratuidad) {
        gratuidad = parseInt(data_pay.plan.gratuidad)
      }
      let data = {
        vendedor: data_pay.user,
        plan: data_pay.plan,
        duracionPlan: parseInt(data_pay.plan.meses) + gratuidad
      }
      //fetch('http://localhost:5001/tienda-54320/us-central1/activarSubscripcion', {
      fetch('https://us-central1-tienda-54320.cloudfunctions.net/activarSubscripcion', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      })
        .then(res => res.json())
        .then((result) => {
          localStorage.setItem('data_pay', '')
          localStorage.setItem('pagado', 1)
          //window.location.reload()

        })
        .catch(console.error)
    } else {
      localStorage.setItem('data_pay', '')
      localStorage.setItem('pagado', 0)
      //window.location.reload()
    }

  }
}
export { data };