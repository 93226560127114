import React, { Component } from 'react';
import { Grid } from '@mui/material';
import { BrowserRouter, Route, Routes, Scrol } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './Header';
import ScrollToTop from '../utils/ScrollToTop';
import WrapperComponent from '../utils/WrapperComponent';

import Login from '../screens/auth/Login';
import Logout from '../screens/auth/Logout';
import ChangePassword from '../screens/auth/ChangePassword';
import Register from '../screens/auth/Register';
import Verificacion from '../screens/auth/Verificacion'
import RestablecerPassword from '../screens/auth/RestablecerPassword';
import Autenticacion from '../screens/auth/Autenticacion';

export default class Guest extends Component {

    constructor() {
        super();
    }

    render() {
        let heightBlock = window.screen.height - 60 - 144;
        return (
            <BrowserRouter>
                <Grid container>
                    {/* <WrapperComponent item={Header} /> */}
                    <Grid item xs={12} md={12}>
                        {/* <ScrollToTop> */}
                            {/* <div className='container-div' style={{height: heightBlock}}> */}
                            <div>
                                <Routes>
                                    <Route exact path="/" element={<WrapperComponent item={Login} />} />
                                    <Route exact path="/register" element={<WrapperComponent item={Register} />} />
                                    <Route exact path="/changepassword" element={<WrapperComponent item={ChangePassword} />} />
                                    <Route exact path="/restablecerpassword" element={<WrapperComponent item={RestablecerPassword} />} />
                                    <Route exact path="/verificacion" element={<WrapperComponent item={Verificacion} />} />
                                    <Route exact path="/autenticacion" element={<WrapperComponent item={Autenticacion} />} />
                                    <Route exact path="/logout" element={<WrapperComponent item={Logout} />} />
                                </Routes>
                            </div>
                        {/* </ScrollToTop> */}
                    </Grid>
                </Grid>
                <ToastContainer
                    position="bottom-right"
                    autoClose={15000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </BrowserRouter>
        )
    }
}
