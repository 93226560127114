import React from 'react';

import {
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Preloader from '../../components/Preloader';
import SectionSlider1 from '../../components/SectionSlider1';
import Helper from '../../utils/Helper';

export default class ModalPublicidad extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loader: false,
            imagenesPrevisualizar:[]
        }

        this.helper = new Helper()
    }

    componentDidMount() {
       this.getImagesStorage(this.props.data)
    }

    getImagesStorage(publicidad) {
        let imagenesPrevisualizar = this.state.imagenesPrevisualizar
        publicidad.imagenes.forEach(urlImage => {
            let url = this.helper.getImagenResize(urlImage, '800x800', false);
            imagenesPrevisualizar.push(url);
        });
        this.setState({imagenesPrevisualizar, loader: true });
    }

    render() {
        const { data, onCancel, isModalShow } = this.props;
        if (!this.state.loader) {
            return (<Preloader />)
        } else {
            return (
                <Dialog
                    open={isModalShow}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        Publicidad
                        {onCancel ? (
                            <IconButton
                                aria-label="close"
                                onClick={onCancel}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    <DialogContent>
                        <SectionSlider1 imagenes={this.state.imagenesPrevisualizar}/>
                    </DialogContent>


                </Dialog>
            )
        }
    }





}