import React from 'react';
import { styled } from '@mui/material/styles';
import {
    Container, Grid, Button, TextField, Card, CardContent,
    InputAdornment, IconButton, MenuItem
} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css';
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import Resizer from "react-image-file-resizer";
import { userService } from '../../services/user.service';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ModalTerminos from '../pages/ModalTerminos';
import 'firebase/compat/storage';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { provinciasService } from '../../services/provincias.service';
import { ciudadService } from '../../services/ciudad.service';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#44b4c4'),
    backgroundColor: '#44b4c4',
    '&:hover': {
        backgroundColor: '#44b4c4',
    },
}));

export default class Register extends React.Component {

    constructor() {
        super();
        this.state = {
            nombre_comercial: '',
            nombre: '',
            apellido: '',
            dni: '',
            provincia: '',
            ciudad: '',
            direccion: '',
            telefono: '',
            celular: '',
            email: '',
            password: '',
            confirm_password: '',
            terminos: false,
            isModalTerminos: false,
            codigo: '',
            showPassword: {
                pass: false,
                confirm: false
            },
            error: {
                nombre_comercial: { error: false, text: '', required: true },
                nombre: { error: false, text: '', required: true },
                apellido: { error: false, text: '', required: true },
                dni: { error: false, text: '', required: true },
                provincia: { error: false, text: '', required: true },
                ciudad: { error: false, text: '', required: true },
                direccion: { error: false, text: '', required: true },
                telefono: { error: false, text: '', required: false },
                celular: { error: false, text: '', required: true },
                email: { error: false, text: '', required: true },
                password: { error: false, text: '', required: true },
                confirm_password: { error: false, text: '', required: true },
            },
            blockButton: true,
            imageCompany: '',
            imagenFile: '',
            listadoProvincias: [],
            listadoCiudades: []
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.register = this.register.bind(this);
    }

    componentDidMount() {
        this.getProvincias()
    }

    getProvincias() {
        provinciasService.getRegisters().get().then(res => {
            let provincias = []
            res.forEach(item => {
                let provincia = item.data()
                provincia.id = item.id
                provincias.push(provincia)
            })
            this.setState({ listadoProvincias: provincias })
        })
    }

    getCiudades(id_provincia) {
        ciudadService.getCiudades(id_provincia, res => {
            this.setState({ listadoCiudades: res })
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'terminos' && value) {
            this.setState({ blockButton: false })
        } else {
            this.setState({ blockButton: true })
        }
        if (name === 'provincia') {
            this.getCiudades(value)
        }
        this.setState({
            [name]: value
        });
    }

    handleImageChange = (event) => {
        try {
            Resizer.imageFileResizer(
                event.target.files[0],
                400,
                400,
                "JPEG",
                70,
                0,
                (uri) => {

                    this.setState({ imageCompany: URL.createObjectURL(uri), imagenFile: uri });
                },
                "file",
                200,
                200
            );
        } catch (err) {
            console.error(err);
        }

    }

    showImage() {
        if (this.state.imageCompany === '') {
            return (<img src='assets/usuario.webp' alt='Usuario' className="imagen" />)
        } else {
            return (<img src={this.state.imageCompany} alt='Empresa' className="imagen" />)
        }
    }

    handleInputBlur(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let error = { ...this.state.error };
        error = this.setErrorField(error, name, false, '');
        if (error[name].required && (value === '' || value === 0)) {
            error = this.setErrorField(error, name, true, 'Campo obligatirio');
        } else {
            if (name === 'email') {
                if (!this.validEmail(value)) {
                    error = this.setErrorField(error, name, true, 'Email incorrecto');
                }
            }
            if (name === 'celular') {
                if (!this.validCelular(value)) {
                    error = this.setErrorField(error, name, true, 'El celular esta incorrecto');
                }
            }
            // if (name === 'telefono') {
            //     if (!this.validTelefono(value)) {
            //         error = this.setErrorField(error, name, true, 'El teléfono esta incorrecto');
            //     }
            // }

            if (name === 'dni') {
                if (!this.validDni(value)) {
                    error = this.setErrorField(error, name, true, 'El campo esta incorrecto');
                }
            }

            if (name === 'password') {
                if (value.length < 6) {
                    error = this.setErrorField(error, name, true, 'La contraseña debe tener mas de 6 caracteres');
                }
            }

            if (name === 'confirm_password') {
                if (value !== this.state.password) {
                    error = this.setErrorField(error, name, true, 'La contraseña no coiciden');
                }
            }
        }
        this.setState({ error })
    }

    setErrorField(error, name, status, message) {
        error[name].error = status;
        error[name].text = message;
        return error;
    }

    handleClickShowPassword = (type) => () => {
        let showPassword = { ...this.state.showPassword }
        if (type === 'P') {
            showPassword.pass = !showPassword.pass;
        } else {
            showPassword.confirm = !showPassword.confirm;
        }
        this.setState({ showPassword });
    };

    handleMouseDownPassword(event) {
        event.preventDefault();
    };

    validEmail(emailValue) {
        let result = false;
        // let reg = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
        let reg = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (reg.test(emailValue)) {
            result = true;
        }
        return result;
    }

    validCelular(celularValue) {
        let error = { ...this.state.error };
        let result = false;
        let reg = /^([0-9]+){9}$/i;
        if (celularValue.length === 9) {
            if (reg.test(celularValue)) {
                if (celularValue.startsWith(9)) {
                    result = true;
                } else {
                    error.celular.text = 'El celular debe empezar con el número 9';
                }

            }
        } else {
            error.celular.text = 'celular incorrecto';

        }

        return result;
    }

    validTelefono(telefonoValue) {
        let error = { ...this.state.error };
        let result = false;
        let reg = /^([0-9]+){9}$/i;
        if (telefonoValue.length === 9) {
            if (reg.test(telefonoValue)) {
                result = true;
            }
        } else {
            error.telefono.text = 'teléfono incorrecto';

        }

        return result;
    }


    validDni(dniValue) {
        let error = { ...this.state.error };
        let result = false;
        let reg = /^([0-9]+){9}$/i;
        if (dniValue.length === 10 || dniValue.length === 13) {
            if (reg.test(dniValue)) {
                result = true;
            }
        } else {
            error.dni.text = 'Valor incorrecto';

        }

        return result;
    }


    register = () => () => {
        if (this.validateFields()) {
            this._registerUser();
        }
    }

    _registerUser() {
        firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
            .then((res) => {
                this.saveImage(res, this);
            })
            .catch((err) => {
                console.error(err);
                if (err.code === 'auth/email-already-in-use') {
                    toast.error('La dirección de correo electrónico ya está siendo utilizada por otra cuenta.');
                } else {
                    toast.error('Error al registrar el usuario');
                }
            })
    }
    saveImage(res, componente) {
        if (this.state.imageCompany == null) return;
        let nameImage = 'images/' + this.state.dni + '.png';
        firebase.storage().ref(nameImage).put(this.state.imagenFile)
            .then((snapshot) => {
                snapshot.ref.getDownloadURL().then(function (url) {  // Now I can use url
                    componente.updateUser(res, url)
                });
            });
    }

    updateUser(res, url) {
        res.user.updateProfile({
            displayName: this.state.nombre,
            photoURL: url
        }).then(() => {

            this.createUser(res)
        }, (error) => {
            console.error(error);
        });
    }

    createUser(res) {
        let fechaCreacion = new Date()
        let data = {
            nombre_comercial: this.state.nombre_comercial,
            nombre: this.state.nombre,
            apellido: this.state.apellido,
            dni: this.state.dni,
            provincia: this.state.provincia,
            ciudad: this.state.ciudad,
            direccion: this.state.direccion,
            telefono: this.state.telefono,
            celular: this.state.celular,
            email: this.state.email,
            subscripcion: 'registrado',
            estado: 1,
            codigo: this.state.nombre.substring(0, 1).toLowerCase() + this.state.apellido.substring(0, 3).toLowerCase() + this.state.dni.slice(6, 10),
            denuncias: 0,
            fechaCreacion: fechaCreacion,
            fechaCreacionSrt: fechaCreacion.toDateString()
        }

        userService.setRegister(firebase.auth().currentUser.uid, data, () => {

            res.user.sendEmailVerification()

            firebase.auth().signOut().then(() => {

                toast.info(
                    'Para completar el proceso se le envio un link para la verificacion de su cuenta',
                    {
                        onClose: () => { this.props.navigate('/'); }
                    }
                );
             
            });

        })

    }

    validateFields() {
        let validation = true;
        let error = { ...this.state.error };

        if (this.state.name === '') {
            ;
            error.name.error = true;
            error.name.text = 'Campo obligatorio';
            validation = false;
        }

        if (this.state.imageCompany === '') {
            validation = false;
            toast.error('Debe subir una imagen, para continuar');
        }

        if (!this.validEmail(this.state.email)) {
            error.email.error = true;
            error.email.text = 'Email incorrecto';
            validation = false;
        }
        if (!this.validCelular(this.state.celular)) {
            error.celular.error = true;
            error.celular.text = 'El celular solo debe tener 10 digitos ';
            validation = false;
        }

        // if (!this.validTelefono(this.state.telefono)) {
        //     error.telefono.error = true;
        //     error.telefono.text = 'El teléfono solo debe tener 9 digitos ';
        //     validation = false;
        // }

        if (!this.validDni(this.state.dni)) {
            error.dni.error = true;
            error.dni.text = 'El campo no puede tener mas de 13 digitos';
            validation = false;
        }


        if (this.state.password.length < 6) {
            error.password.error = true;
            error.password.text = 'La contraseña debe tener mas de 6 caracteres';
            validation = false;
        }



        if (this.state.confirm_password !== this.state.password) {
            error.confirm_password.error = true;
            error.confirm_password.text = 'La contraseña no coiciden';
            validation = false;
        }

        if (!validation) {
            this.setState({ error })
        } else {
            this.setState({ error })
        }
        return validation;
    }

    viewModal = (value) => () => {
        this.setState({ isModalTerminos: value })

    }

    render() {
        return (
            <div className='container-div'
                style={{
                    backgroundImage: `url("/assets/IMAGEN2.jpeg")`, 
                    backgroundRepeat: 'no-repeat', 
                    backgroundSize: 'cover'
                }}
            >
                 <Container>
                <div className="register-div">
                    <Card style={{marginBlockEnd: 50}}>
                        <CardContent>
                            <div>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <div style={{ position: 'relative', textAlign: 'center' }}>
                                            <div className='container-imagen'>
                                                {this.showImage()}

                                            </div>
                                            <div>
                                                <label htmlFor="contained-button-file">
                                                    <input accept="image/*" id="contained-button-file" multiple type="file" hidden onChange={(event) => this.handleImageChange(event)} />
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        <PhotoCamera />
                                                    </IconButton>
                                                </label>
                                            </div>

                                            <p style={{fontSize: '18px'}}>Coloque su selfi</p>
                                        </div>


                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <div className="div-element">
                                            <h2>¿ DESEA VENDER ? <br/>REGÍSTRATE CON NOSOTROS</h2>
                                            
                                        </div>


                                        <div style={{fontSize:'18px'}}>Si no posee nombre comercial escriba N/A (No aplica)</div>

                                        <TextField
                                            name='nombre_comercial'
                                            label='Nombre comercial'
                                            value={this.state.nombre_comercial ? this.state.nombre_comercial : ""}
                                            error={this.state.error.nombre_comercial.error}
                                            helperText={this.state.error.nombre_comercial.error ? this.state.error.nombre_comercial.text : ''}
                                            fullWidth
                                            margin="normal"
                                            required={this.state.error.nombre_comercial.required}
                                            onChange={this.handleInputChange}
                                            onBlur={this.handleInputBlur}
                                        />
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='dni'
                                                    label='Cédula o RUC'
                                                    value={this.state.dni ? this.state.dni : ""}
                                                    error={this.state.error.dni.error}
                                                    helperText={this.state.error.dni.error ? this.state.error.dni.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.dni.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='nombre'
                                                    label='Nombre'
                                                    value={this.state.nombre ? this.state.nombre : ""}
                                                    error={this.state.error.nombre.error}
                                                    helperText={this.state.error.nombre.error ? this.state.error.nombre.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.nombre.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                />

                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='apellido'
                                                    label='Apellido'
                                                    value={this.state.apellido ? this.state.apellido : ""}
                                                    error={this.state.error.apellido.error}
                                                    helperText={this.state.error.apellido.error ? this.state.error.apellido.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.apellido.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                />

                                            </Grid>

                                        </Grid>


                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    select
                                                    name='provincia'
                                                    label='Seleccione la Provincia'
                                                    value={this.state.provincia ? this.state.provincia : ""}
                                                    error={this.state.error.provincia.error}
                                                    helperText={this.state.error.provincia.error ? this.state.error.provincia.text : ''}
                                                    required={this.state.error.provincia.required}
                                                    fullWidth
                                                    margin="normal"
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}

                                                >
                                                    {this.state.listadoProvincias.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.provincia}
                                                        </MenuItem>

                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} md={4}>

                                                <TextField
                                                    select
                                                    name='ciudad'
                                                    label='Seleccione la Ciudad'
                                                    value={this.state.ciudad ? this.state.ciudad : ""}
                                                    error={this.state.error.ciudad.error}
                                                    helperText={this.state.error.ciudad.error ? this.state.error.ciudad.text : ''}
                                                    required={this.state.error.ciudad.required}
                                                    fullWidth
                                                    margin="normal"
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}

                                                >
                                                    {this.state.listadoCiudades.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.canton}
                                                        </MenuItem>

                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='direccion'
                                                    label='Direccion'
                                                    value={this.state.direccion ? this.state.direccion : ""}
                                                    error={this.state.error.direccion.error}
                                                    helperText={this.state.error.direccion.error ? this.state.error.direccion.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.direccion.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                />
                                            </Grid>


                                        </Grid>

                                        <div style={{ fontSize: 15 }}>* Ingrese con su código de provincia</div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='telefono'
                                                    label='Teléfono'
                                                    value={this.state.telefono ? this.state.telefono : ""}
                                                    error={this.state.error.telefono.error}
                                                    helperText={this.state.error.telefono.error ? this.state.error.telefono.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.telefono.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='celular'
                                                    label='Celular'
                                                    value={this.state.celular ? this.state.celular : ""}
                                                    error={this.state.error.celular.error}
                                                    helperText={this.state.error.celular.error ? this.state.error.celular.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.celular.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}

                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">
                                                            +593
                                                        </InputAdornment>,
                                                    }}
                                                />


                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    name='email'
                                                    label='Email'
                                                    value={this.state.email ? this.state.email : ""}
                                                    error={this.state.error.email.error}
                                                    helperText={this.state.error.email.error ? this.state.error.email.text : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    required={this.state.error.email.required}
                                                    onChange={this.handleInputChange}
                                                    onBlur={this.handleInputBlur}
                                                />
                                            </Grid>
                                        </Grid>

                                        <div className="div-element">
                                            <TextField
                                                type={this.state.showPassword.pass ? 'text' : 'password'}
                                                name='password'
                                                label='Contraseña'
                                                value={this.state.password ? this.state.password : ""}
                                                error={this.state.error.password.error}
                                                helperText={this.state.error.password.error ? this.state.error.password.text : ''}
                                                fullWidth
                                                margin="normal"
                                                required={this.state.error.password.required}
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword('P')}
                                                            onMouseDown={this.handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {this.state.showPassword.pass ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }}
                                            />
                                            <TextField
                                                type={this.state.showPassword.confirm ? 'text' : 'password'}
                                                name='confirm_password'
                                                label='Confirmar contraseña'
                                                value={this.state.confirm_password ? this.state.confirm_password : ""}
                                                error={this.state.error.confirm_password.error}
                                                helperText={this.state.error.confirm_password.error ? this.state.error.confirm_password.text : ''}
                                                fullWidth
                                                margin="normal"
                                                required={this.state.error.confirm_password.required}
                                                onChange={this.handleInputChange}
                                                onBlur={this.handleInputBlur}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword('C')}
                                                            onMouseDown={this.handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {this.state.showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <Link to='#' className="link-text" onClick={this.viewModal(true)}>
                                                <p style={{fontSize: '18px'}}>Términos y Condiciones </p>
                                            </Link>
                                        </div>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox value={this.state.terminos} onChange={this.handleInputChange} name="terminos" />
                                                }
                                                label="Aceptar Términos y Condiciones"
                                            />

                                        </div>


                                    </Grid>

                                </Grid>

                                <div className="div-element">
                                    <ColorButton
                                        variant="contained"
                                        onClick={this.register()}
                                        disabled={this.state.blockButton}
                                    ><b>Registrarse </b></ColorButton>
                                </div>
                                <div className="div-element" style={{ textAlign: 'right' }}>
                                    <Link className="link-text" to='/'>Regresar</Link>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
                {this.state.isModalTerminos ? (
                    <ModalTerminos
                        onCancel={this.viewModal(false)}
                        isModalShow={this.state.isModalTerminos}
                    />
                ) : null}
            </Container>

            </div>
           
        )
    }
}
