import { Button, Grid, IconButton, Container } from '@mui/material';
import React, { Component } from 'react';
import { categoriasService } from '../../services/categorias.service';
import ModalCategorias from '../pages/ModalCategorias';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteModal from '../../components/DeleteModal';
import { productosService } from '../../services/productos.service';
import { toast } from 'react-toastify';



export default class Categorias extends React.Component {

    constructor() {
        super()
        this.state = {
            categorias: {
                nivel1: [],
                nivel2: [],
                nivel3: [],
                nivel4: [],
                nivel5: []
            },
            isModalCategorias: false,
            idRegister: 0,
            dataCategoria: '',
            isModalShowDelete: false
        }



    }
    componentDidMount() {
        this.getCategorias()

    }
    agregarCategoria = () => () => {
        this.setState({ isModalCategorias: true, idRegister: 0, dataCategoria: '' })
    }


    getCategorias() {
        categoriasService.getCategoriasNivel1((items) => {
            let categorias = { ...this.state.categorias }
            items.forEach(element => {
                element.expand = false;
            });
            categorias.nivel1 = items
            this.setState({ categorias })

        })

    }

    getSubCategoriaNivel(value, nombre) {
        let categorias = { ...this.state.categorias }
        /*categorias[nombre] = []
        this.setState({ categorias })*/

        categoriasService.getSubCategoria(value, (items) => {
            items.forEach(element => {
                element.expand = false;
            });
            categorias[nombre] = items
            this.setState({ categorias })

        })
    }


    closeModal = (origin) => () => {

        if (origin === 'D') {
            this.setState({ isModalShowDelete: false })
            
        } else {
            this.setState({ isModalCategorias: false })
            this.getCategorias()
        }
    }



    expandirCategoria = (nivel, index) => () => {
        let nivelSiguente = nivel + 1;
        let labelNivel = 'nivel' + nivel;
        let categorias = { ...this.state.categorias }
        categorias[labelNivel][index].expand = !categorias[labelNivel][index].expand;
        categorias[labelNivel].forEach((element, indexE) => {
            if (indexE != index) {
                element.expand = false;
            }
        });
        this.setState({ categorias });
        if (categorias[labelNivel][index].expand) {
            this.getSubCategoriaNivel(categorias[labelNivel][index].id, 'nivel' + nivelSiguente);
        } else {
            categorias['nivel' + nivelSiguente] = [];
            this.setState({ categorias })
        }
    }

    editarCategoria = (item) => () => {
        this.setState({ isModalCategorias: true, idRegister: item.id, dataCategoria: item })
        
    }
    onAcceptEliminar =()=>()=>{
        this.comprobarProductos(this.state.idRegister)
        
    }

    eliminarCategoria = (item) => () => {
        this.setState({ isModalShowDelete: true, idRegister: item })
    }

    comprobarProductos(itemSeleccionado){
        productosService.getProductoPorCategorias(itemSeleccionado.id , (itemProductos)=>{
         
            if(itemProductos.length > 0){
                toast.error('No puede elimiar la categoria porque tiene productos dentro')
            } else {
                this.getArbolCategorias(itemSeleccionado)
                
            }

        })
        
    }

    getArbolCategorias(itemSeleccionado) {
        let nivelSeleccionado = itemSeleccionado.nivel+1
        categoriasService.getCategoriasPorNivel(nivelSeleccionado, (subcategorias) => {
            let resultado = []
            subcategorias.forEach(item => {
                if (item.nivel == nivelSeleccionado && item.idPadre == itemSeleccionado.id) {
                    item.subcategorias = []
                    resultado.push(item)
                }
            });
            let resultadoFinal = []
            resultado.forEach(item => {
                resultadoFinal.push(this.getDatosCategoria(subcategorias, item))
            })
            if(resultadoFinal.length > 0){
                this.elimnarArbolRecursivo(resultadoFinal)
                categoriasService.deleteRegister(itemSeleccionado.id)
            } else {
                categoriasService.deleteRegister(itemSeleccionado.id)
                this.getCategorias()
            }
            this.setState({ isModalShowDelete: false , idRegister: '' })
        })
    }

    getDatosCategoria(subcategorias, item) {
        subcategorias.forEach(subc => {
            if (item.id == subc.idPadre) {
                subc.subcategorias = []
                item.subcategorias.push(this.getDatosCategoria(subcategorias, subc))
            }

        })
        return item
    }

    elimnarArbolRecursivo(arrayItem){
        arrayItem.forEach(item => {
         if(item.subcategorias.lenght == 0){
           categoriasService.deleteRegister(item.id)
         }else{
            this.elimnarArbolRecursivo(item.subcategorias);
            categoriasService.deleteRegister(item.id)
         }
        })
        
      }
      


    render() {
        return (


            <Container style={{ padding: 50 }}>

                <div>
                    <Button onClick={this.agregarCategoria()} variant='contained' aria-label='Agregar Categoria'>
                        Agregar Categoria
                    </Button>
                </div>

                <div style={{ marginTop: 50 }}>
                    {this.state.categorias.nivel1.map((nivel1, indexN) => (
                        <div key={'N1-' + indexN}>
                            <div className='item-categoria'>
                                <Grid container>
                                    <Grid item xs={12} md={0.5}>
                                        <IconButton color="primary" onClick={this.expandirCategoria(1, indexN)}>
                                            {nivel1.expand ? (<IndeterminateCheckBoxOutlinedIcon />) : (
                                                <AddBoxOutlinedIcon />
                                            )}

                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12} md={8.5}  >
                                        <div style={{ alignItems: 'center', display: 'flex', height: '100%' }}>
                                            {nivel1.nombre}
                                        </div>

                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <IconButton style={{ color: 'blue' }} onClick={this.editarCategoria(nivel1)}>
                                            <EditOutlinedIcon />
                                        </IconButton>
                                        <IconButton style={{ color: 'red' }} onClick={this.eliminarCategoria(nivel1)}>
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </div>
                            {nivel1.expand &&
                                <div className='contenedor-subCategoria'>
                                    {this.state.categorias.nivel2.map((nivel2, indexN2) => (
                                        <div key={'N2-' + indexN2}>
                                            <div className='item-categoria'>
                                                <Grid container>
                                                    <Grid item xs={12} md={0.5}>
                                                        <IconButton color="primary" onClick={this.expandirCategoria(2, indexN2)}>
                                                            {nivel2.expand ? (<IndeterminateCheckBoxOutlinedIcon />) : (
                                                                <AddBoxOutlinedIcon />
                                                            )}
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={12} md={8.5}  >
                                                        <div style={{ alignItems: 'center', display: 'flex', height: '100%' }}>
                                                            {nivel2.nombre}
                                                        </div>

                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <IconButton style={{ color: 'blue' }} onClick={this.editarCategoria(nivel2)}>
                                                            <EditOutlinedIcon />
                                                        </IconButton>
                                                        <IconButton style={{ color: 'red' }} onClick={this.eliminarCategoria(nivel2)}>
                                                            <DeleteOutlineOutlinedIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            {nivel2.expand &&
                                                <div className='contenedor-subCategoria'>
                                                    {this.state.categorias.nivel3.map((nivel3, indexN3) => (
                                                        <div key={'N3-' + indexN3}>
                                                            <div className='item-categoria'>
                                                                <Grid container>
                                                                    <Grid item xs={12} md={0.5}>
                                                                        <IconButton color="primary" onClick={this.expandirCategoria(3, indexN3)}>
                                                                            {nivel3.expand ? (<IndeterminateCheckBoxOutlinedIcon />) : (
                                                                                <AddBoxOutlinedIcon />
                                                                            )}
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={8.5}  >
                                                                        <div style={{ alignItems: 'center', display: 'flex', height: '100%' }}>
                                                                            {nivel3.nombre}
                                                                        </div>

                                                                    </Grid>
                                                                    <Grid item xs={12} md={3}>
                                                                        <IconButton style={{ color: 'blue' }} onClick={this.editarCategoria(nivel3)}>
                                                                            <EditOutlinedIcon />
                                                                        </IconButton>
                                                                        <IconButton style={{ color: 'red' }} onClick={this.eliminarCategoria(nivel3)}>
                                                                            <DeleteOutlineOutlinedIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                            {nivel3.expand &&
                                                                <div className='contenedor-subCategoria'>
                                                                    {this.state.categorias.nivel4.map((nivel4, indexN4) => (
                                                                        <div key={'N4-' + indexN4}>
                                                                            <div className='item-categoria'>
                                                                                <Grid container>
                                                                                    <Grid item xs={12} md={0.5}>
                                                                                        <IconButton color="primary" onClick={this.expandirCategoria(4, indexN4)}>
                                                                                            {nivel4.expand ? (<IndeterminateCheckBoxOutlinedIcon />) : (
                                                                                                <AddBoxOutlinedIcon />
                                                                                            )}
                                                                                        </IconButton>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} md={8.5}  >
                                                                                        <div style={{ alignItems: 'center', display: 'flex', height: '100%' }}>
                                                                                            {nivel4.nombre}
                                                                                        </div>

                                                                                    </Grid>
                                                                                    <Grid item xs={12} md={3}>
                                                                                        <IconButton style={{ color: 'blue' }} onClick={this.editarCategoria(nivel4)}>
                                                                                            <EditOutlinedIcon />
                                                                                        </IconButton>
                                                                                        <IconButton style={{ color: 'red' }} onClick={this.eliminarCategoria(nivel4)}>
                                                                                            <DeleteOutlineOutlinedIcon />
                                                                                        </IconButton>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                            {nivel4.expand &&
                                                                                <div className='contenedor-subCategoria'>
                                                                                    {this.state.categorias.nivel5.map((nivel5, indexN5) => (
                                                                                        <div key={'N5-' + indexN5}>
                                                                                            <div className='item-categoria'>
                                                                                                <Grid container>
                                                                                                    <Grid item xs={12} md={0.5}>
                                                                                                        <IconButton color="primary" onClick={this.expandirCategoria(5, indexN5)}>
                                                                                                            <DisabledByDefaultOutlinedIcon />
                                                                                                        </IconButton>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12} md={8.5}  >
                                                                                                        <div style={{ alignItems: 'center', display: 'flex', height: '100%' }}>
                                                                                                            {nivel5.nombre}
                                                                                                        </div>

                                                                                                    </Grid>
                                                                                                    <Grid item xs={12} md={3}>
                                                                                                        <IconButton style={{ color: 'blue' }} onClick={this.editarCategoria(nivel5)}>
                                                                                                            <EditOutlinedIcon />
                                                                                                        </IconButton>
                                                                                                        <IconButton style={{ color: 'red' }} onClick={this.eliminarCategoria(nivel5)}>
                                                                                                            <DeleteOutlineOutlinedIcon />
                                                                                                        </IconButton>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </div>

                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    ))}
                </div>

                <div>
                    {this.state.isModalCategorias &&
                        <ModalCategorias
                            onCancel={this.closeModal('E')}
                            onAccept={this.closeModal('E')}
                            isModalShow={this.state.isModalCategorias}
                            idRegister={this.state.idRegister}
                            data={this.state.dataCategoria}
                        />
                    }
                    <DeleteModal
                        onAccept={this.onAcceptEliminar()}
                        onCancel={this.closeModal('D')}
                        isModalShow={this.state.isModalShowDelete}
                        
                    />

                </div>

            </Container>




        )


    }



}