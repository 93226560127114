import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class PlanesService extends BaseService {

    constructor() {
        super();
        this.collection = 'planes';
    }

    getPlanesBloque(idBloque, callback) {
        firebase.firestore()
            .collection(this.collection)
            .where('bloque', '==', idBloque)
            .get().then(snapshot => {
                let planes = []
                snapshot.forEach(item => {
                    let plan = item.data()
                    plan.id = item.id
                    planes.push(plan)
                })
                callback(planes)
            })

    }

    async getPlanesBloquesAsync(idBloque) {
        let snapshot = await firebase.firestore()
            .collection(this.collection)
            .where('bloque', '==', idBloque)
            .orderBy('orden', 'asc')
            .get()

        let planes = []
        snapshot.forEach(item => {
            let plan = item.data()
            plan.id = item.id
            planes.push(plan)
        })

        return planes

    }

    async getPlanAsync(idPlan) {
        let snapshot = await firebase.firestore()
            .collection(this.collection)
            .doc(idPlan)
            .get()
        return snapshot.data()

    }

    getRegistersOrden(){
        return firebase.firestore()
        .collection(this.collection)
        .orderBy('orden', 'asc')
    }
    



}

export const planesService = new PlanesService();
