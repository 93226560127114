import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Container, Grid, Button, TextField, Card, CardContent } from '@mui/material';
import { toast } from 'react-toastify';
import { grey } from '@mui/material/colors';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Verificacion from './Verificacion';
import ChangePassword from './ChangePassword';



const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    '&:hover': {
        backgroundColor: grey[700],
    },
}));



export default class Autenticacion extends React.Component {

    constructor() {
        super();
        this.state = {
            mode: ''
        }
    }
    componentDidMount() {
        let parametros = new URLSearchParams(this.props.location.search)
        this.setState({ mode: parametros.get("mode") })
    }

    render() {
        let showImage = true;
        if (window.screen.width <= 480) {
            showImage = false;
        }
        let heightBlock = window.screen.height - 60 - 144;
        return (
            <div className='container-div' style={{ height: heightBlock }}>
                <Container>
                    {this.state.mode == 'verifyEmail' &&
                        <Verificacion data={this.props} />

                    }
                    {this.state.mode == 'resetPassword' &&
                        <ChangePassword data={this.props} />
                    }

                </Container>


            </div>

        )
    }


}