import React from 'react';
import { Card, CardContent, CardMedia } from '@mui/material';
import Helper from '../utils/Helper';

class PublicidadCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      imagenPrincipal: ''
    }
    this.helper = new Helper();
  }

  getImagesStorage(urlImage) {
    let url = this.helper.getImagenResize(urlImage, '400x400', false);
    this.setState({ imagenPrincipal: url });
  }

  componentDidMount() {
    if (this.props.item.imagenes) {
      this.getImagesStorage(this.props.item.imagenPrincipal);
    }

  }

  render() {
    const { item, verDetalle } = this.props;
    return (
      <Card variant="outlined" onClick={verDetalle}  className='publicidad-celular'>
        <CardMedia
          component="img"
          height="290"
          image={this.state.imagenPrincipal}
          alt=""
          sx= {{objectFit: 'contain'}}
        />
        <CardContent style={{ paddingTop:0, paddingBlockEnd:0}} >
        </CardContent>
      </Card>

    )
  }
}
export default PublicidadCard;