import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class DenunciasService extends BaseService {

    constructor() {
        super();
        this.collection = 'denuncias';
    }

    getTiposDenuncias(callback) {
        firebase.firestore()
            .collection('tiposDenuncias').onSnapshot(snapshot => {
                let denuncias = []
                snapshot.forEach(item => {
                    let denuncia = item.data()
                    denuncia.id = item.id
                    denuncias.push(denuncia)
                })
                callback(denuncias)
            })

    }

    getRegistersDenuncias(){
        return firebase.firestore()
        .collection(this.collection)
        .limit(5)    
    }


    getNextDenuncias(lastVisible){
        return firebase.firestore()
        .collection(this.collection)
        .startAfter(lastVisible)
        .limit(5)       
    }

    getBackDenuncias(lastVisible){
        return firebase.firestore()
        .collection(this.collection) 
        .endBefore(lastVisible)  
        .limit(5)      
    }



    



}

export const denunciasService = new DenunciasService();