import React from 'react';
import { Container, Card, Button, Grid, CardContent, Divider } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { userService } from '../../services/user.service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { planesService } from '../../services/planes.service';
import ModalPagos from './ModalPagos';
import PpxButton from "../../components/PpxButton";
import { data } from "../../configuration/ppx.data";

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    '&:hover': {
        backgroundColor: grey[700],
    },
}));

export default class Facturacion extends React.Component {

    constructor() {
        super();
        this.state = {
            facturacion: {
                razonSocial: '',
                dni: '',
                direccion: '',
                celular: '',
                email: ''
            },
            error: {
                razonSocial: { error: false, text: '', required: true },
                dni: { error: false, text: '', required: true },
                direccion: { error: false, text: '', required: true },
                celular: { error: false, text: '', required: true },
                email: { error: false, text: '', required: true }
            },
            conservarDatos: true,
            usuario: '',
            plan: '',
            tipoPago: 'T',
            isModalTransferencia: false,
            isModalBotonPagos: false,
            automatico: ''
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.getUser()
        localStorage.setItem('pagado', 0)
    }

    componentDidUpdate(){
        let save = localStorage.getItem('pagado')
        if (save == 1){
            this.props.navigate('/')
        }

    }

    getUser() {
        userService.getUserDoc(firebase.auth().currentUser.uid).onSnapshot(
            res => {
                let usuario = res.data();
                usuario.id = res.id;
                this.setState({ usuario })
                this.setDatosFacturacion(usuario)
                this.getPlan()
            }
        )
    }

    setDatosFacturacion(usuario) {
        let razonSocial = ''
        if (usuario.nombre && usuario.apellido) {
            razonSocial = usuario.nombre + ' ' + usuario.apellido
        }
        data.PayboxSendmail = usuario.email;
        data.PayboxSendname = razonSocial;
        data.PayboxDirection = usuario.direccion;
        data.PayBoxClientIdentification = usuario.dni;
        data.PayBoxClientPhone = usuario.celular;
        

        this.setState({
            facturacion: {
                razonSocial: razonSocial,
                dni: usuario.dni ? usuario.dni : '',
                direccion: usuario.direccion ? usuario.direccion : '',
                celular: usuario.celular ? usuario.celular : '',
                email: usuario.email ? usuario.email : ''
            },
        })
    }

    getPlan() {
        planesService.getRegister(this.props.params.idPlan).then(item => {
            let plan = item.data();
            plan.id = item.id
            //data.PayboxBase0 = parseFloat(plan.precio);
            data.PayboxBase12 = parseFloat(plan.precio);
            data.PayboxDescription = 'Pago por plan: '+plan.nombre;
            this.setState({ plan })
            let data_pay = {
                user: this.state.usuario, 
                plan: plan,
            }
            localStorage.setItem('data_pay', JSON.stringify(data_pay));
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }

    continuar = (value) => () => {
        this.setState({ isModalTransferencia: value })
        if(!value){
            this.props.navigate('/')
        }
    }


    paySuccess = (response) => {
        if (response.status === "succeeded") {
            alert("regresa a Facturacion");
        } 
    }
    generarAutomatico(){
        //this.setState({automatico: 'ingreso'})
    }

    render() {
        
        return (
            <Container>
                <Card style={{ marginTop: 20, marginBlock: 60 }}>
                    <CardContent>

                        <div className='factura'>
                            <div>
                                <h3>Datos de su compra</h3>
                            </div>
                            <div>

                                <Grid container spacing={2}>
                                    <Grid item className='labelFacturacion' xs={6} md={4}>
                                        {this.state.plan.nombre}
                                    </Grid>
                                    <Grid item className='valueFacturacion' xs={6} md={8}>
                                        {'$' + this.state.plan.precio}
                                    </Grid>

                                </Grid>

                            </div>
                            <Divider style={{ marginTop: 20 }} />

                            <div>
                                <h3>Datos para su factura</h3>
                            </div>

                            <Grid container spacing={2}>
                                <Grid item className='labelFacturacion' xs={6} md={4}>
                                    Nombre y Apellido:
                                </Grid>
                                <Grid item className='valueFacturacion' xs={6} md={8}>
                                    {this.state.facturacion.razonSocial}
                                </Grid>

                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item className='labelFacturacion' xs={6} md={4}>
                                    Cédula o Ruc:
                                </Grid>
                                <Grid item className='valueFacturacion' xs={6} md={8}>
                                    {this.state.facturacion.dni}
                                </Grid>

                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item className='labelFacturacion' xs={6} md={4}>
                                    Dirección:
                                </Grid>
                                <Grid item className='valueFacturacion' xs={6} md={8}>
                                    {this.state.facturacion.direccion}
                                </Grid>

                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item className='labelFacturacion' xs={6} md={4}>
                                    Celular:
                                </Grid>
                                <Grid item className='valueFacturacion' xs={6} md={8}>
                                    {'0' + this.state.facturacion.celular}
                                </Grid>

                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item className='labelFacturacion' xs={6} md={4}>
                                    Email:
                                </Grid>
                                <Grid item className='valueFacturacion' xs={6} md={8}>
                                    {this.state.facturacion.email}
                                </Grid>

                            </Grid>
                            <Divider style={{ marginTop: 20 }} />
                            <div>
                                <h3>Elige tu forma de pago</h3>
                                <FormControl>
                                    {/* <FormLabel id="demo-controlled-radio-buttons-group">Elige tu forma de pago</FormLabel> */}
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="tipoPago"
                                        value={this.state.tipoPago}
                                        onChange={this.handleInputChange}
                                    >
                                        <FormControlLabel value="B" control={<Radio />} label="Pagar por Transferencia Bancaria" />
                                        <FormControlLabel value="T" control={<Radio />} label="Pagar con Tarjeta de Credito o Debito" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        {this.state.tipoPago === 'B' &&
                            <div style={{ marginTop: 20, textAlign: 'center' }}>
                                <ColorButton style={{ width: '20%', marginRight: 10 }}
                                    variant="contained"
                                    disabled={this.state.blockButton}
                                    onClick={this.continuar(true)}

                                >Continuar</ColorButton>
                            </div>
                        }
                        {this.state.tipoPago === 'T' &&
                            <div style={{textAlign:'center'}}>
                                <PpxButton data={data} />

                            </div>
                        }
                        <div style={{ marginTop: 20, marginBottom: 30, textAlign: 'center' }}>
                            <ColorButton style={{ width: '20%' }}
                                variant="contained"
                                disabled={this.state.blockButton}
                                onClick={() => { this.props.navigate('/') }}

                            >Regresar</ColorButton>
                        </div>
                    </CardContent>
                </Card>
                {this.state.isModalTransferencia ? (
                    <ModalPagos
                        onCancel={this.continuar(false)}
                        isModalShow={this.state.isModalTransferencia}
                        idPlan={this.props.params.idPlan}
                        plan={this.state.plan}
                        usuario={this.state.usuario}
                    />
                ) : null}
            </Container>
        )
    }
}