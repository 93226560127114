import React from 'react';
import {
    Modal,
    Box,
    Button,
} from '@mui/material';

const styleBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.screen.width <= 480 ? 300 : 300,
    bgcolor: 'background.paper',
    border: '2px solid #108df3',
    boxShadow: 24,
    p: 2,
};

class DeleteModal extends React.Component {
    
    render(){
        const { onAccept, onCancel, isModalShow, title } = this.props;
        let mensaje = 'Desea eliminar este registro?'
        if(title){
            mensaje = title
        }
        return (
            <Modal
                open={isModalShow}
                onClose={onCancel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleBox}>
                    <div style={{marginBottom: 10, fontSize: 17, textAlign: 'center'}}>
                        {mensaje}
                    </div>
                    <div style={{textAlign: 'center', marginTop: 20}}>
                        <Button aria-label='Aceptar Elminar' variant="outlined" color="primary" size="small" onClick={onAccept} style={{marginRight: 10}}>Aceptar</Button>
                        <Button aria-label='Cancelar Eliminación' variant="outlined" color="error" size="small" onClick={onCancel}>Cancelar</Button>
                    </div>
                </Box>
            </Modal>
        )
    }
}

export default DeleteModal;