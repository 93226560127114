import React from 'react';
import "../../App.css";
import { categoriasService } from "../../services/categorias.service";
import ResponsiveGrid from "../../components/ResponsiveGrid";
import { Container} from "@mui/material";




class CategoriasInicio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categorias: []

        }
    }


    componentDidMount() {
        this.getCategorias();

    }
    getCategorias() {
        categoriasService.getCategoriasNivel1((categorias) => {
            this.setState({ categorias });
         
        });
    }



    render() {
        return (
            <div
                className="categorias"
                style={{ paddingTop: 20, marginTop: 5, paddingBottom: 30 }}
            >
                <Container>
                    <div style={{ marginTop: 30, textAlign: "center" }}>
                        <div className="title-block" style={{ marginBlockEnd: 50 }}>
                            CATEGORÍAS
                        </div>
                    </div>
                    <div>
                        <ResponsiveGrid categorias={this.state.categorias} />
                    </div>
                </Container>
            </div>
        )



    }
}
export default CategoriasInicio;