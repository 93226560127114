import React from 'react';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Container, Button, Card, CardContent } from '@mui/material';
import { toast } from 'react-toastify';
import { grey } from '@mui/material/colors';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';


const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    '&:hover': {
        backgroundColor: grey[700],
    },
}));

export default class Verificacion extends React.Component {

    constructor() {
        super();
        this.sendEmail = this.sendEmail.bind(this);
    }

    sendEmail() {
        let parametros = new URLSearchParams(this.props.data.location.search)
        firebase.auth().applyActionCode(parametros.get('oobCode'))
            .then(() => {
                toast.info('Correo verificado correctamente')
                this.props.data.navigate('/')
            })
            .catch((error) => {
                // error
            });
    }

    render() {
        let heightBlock = window.screen.height - 60 - 144;
        return (
            <div className='container-div' style={{height: heightBlock}}>
                <Container>
                    <div className="login-div">
                        <Card>
                            <CardContent>
                                <div>
                                    <div className="div-element">
                                        <h5>Verificar Correo</h5>
                                    </div>
                                    <div className="div-element">
                                        <ColorButton style={{ width: '100%' }}
                                            variant="contained"
                                            onClick={this.sendEmail.bind(this)}

                                        >Verificar</ColorButton>
                                    </div>
                                    <div className="div-element" style={{ textAlign: 'right' }}>
                                        <Link style={{ textAlign: 'right' }} to='/'>Regresar</Link>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </Container>
            </div>
        )
    }
}