import React from "react";
import {
  Container,
  Grid,
  Card,
  IconButton,
  CardMedia,
  CardActions,
  Rating,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen1: false,
      tooltipOpen2: false,
      permission: false,
    };
  }

  irQuienes = () => () => {
    this.props.navigate("/quienessomos");
  };

  render() {
    const { title, rating } = this.props;
    let date = new Date();
    let year = date.getFullYear();
    return (
      <div
        style={{
          backgroundColor: "#44b4c4",
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <Container>
          <div>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      marginBottom: 20,
                    }}
                  >
                    Links de Información
                  </div>
                  <div onClick={this.irQuienes}></div>
                  <ul>
                    <li style={{ marginTop: 10 }}>
                      {" "}
                      <Link style={{ color: "#000" }} to="/quienessomos">
                        ¿Quiénes Somos?{" "}
                      </Link>{" "}
                    </li>
                    <li style={{ marginTop: 10 }}>
                      {" "}
                      <Link style={{ color: "#000" }} to="/terminos">
                        Políticas de Privacidad{" "}
                      </Link>
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      marginBottom: 20,
                    }}
                  >
                    Sello de garantía Sitio seguro
                  </div>
                  <div style={{ marginTop: 50 }}>
                    <img src="/assets/sello.png" alt="Garantia de sitio seguro"></img>
                  </div>
                </Grid>

                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      marginBottom: 20,
                    }}
                  >
                    Información de Contacto
                  </div>
                  <div>
                    <div>
                      <a
                        href="mailto:ventasdacomprando@gmail.com"
                        style={{ fontSize: "15px", color: "#000" }}
                      >
                        <img
                          src="/assets/sobre.png"
                          style={{ width: "30px", marginBlockEnd: "-10px" }}
                          alt="Informancion de contacto ventas"
                        ></img>
                        &nbsp; ventasdacomprando@gmail.com
                      </a>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <a
                        href="mailto:servicioalcliente.dacomprando@gmail.com"
                        style={{
                          fontSize: "15px",
                          color: "#000",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        <img
                          src="/assets/sobre.png"
                          style={{ width: "30px", marginBlockEnd: "-10px" }}
                          alt="Informancion de contacto servicio"
                        ></img>
                        &nbsp;servicioalcliente.dacomprando@gmail.com
                      </a>
                      <div></div>
                    </div>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <a
                      href="https://www.facebook.com/profile.php?id=100092252168369"
                      style={{ fontSize: "15px", color: "#000" }}
                    >
                      {" "}
                      <img
                        src="/assets/facebook.png"
                        style={{ width: "30px", marginBlockEnd: "-5px" }}
                        alt="Facebook"
                      ></img>
                      &nbsp;Da Comprando
                    </a>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <a
                      href="https://instagram.com/dacomprando?igshid=MzRlODBiNWFlZA=="
                      style={{ fontSize: "15px", color: "#000" }}
                    >
                      {" "}
                      <img
                        src="/assets/instagram.png"
                        style={{ width: "30px", marginBlockEnd: "-5px" }}
                        alt="Instagram"
                      ></img>
                      &nbsp;Da Comprando
                    </a>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                color: "#fff",
                textAlign: "left",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              Copyright {year}.Da Comprando. Todos los derechos reservados.
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default Footer;
