import React, { Component } from 'react';
import {
    Container, IconButton, Table, TableBody, TableContainer, TableHead, TableRow,
    Paper, Tooltip, Menu, MenuItem, TableCell, tableCellClasses, Grid, TextField, Fab,
    Badge
}
    from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MenuIcon from '@mui/icons-material/Menu';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import DeleteModal from '../../components/DeleteModal';
import ModalSubscripciones from './ModalSubscripciones';
import { userService } from '../../services/user.service';
import { planesService } from '../../services/planes.service';
import Helper from '../../utils/Helper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default class Usuarios extends Component {
    constructor() {
        super()
        this.state = {
            persona: {
                nombres: '',
                dni: '',
                email: ''
            },
            usuarios: [],
            idRegister: 0,
            isModalShowDelete: false,
            estadoRegister: '',
            anchorEl: null,
            mensaje: '',
            snapshotNext: '',
            snapshotBack: '',
            blockBack: true,
            blockNext: false,
            isModalSubscripciones: false,
            usuariosSelect: '',
            filters: {
                dni: '',
                codigo: '',
                estado: ''
            }

        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.indexPosition = 0;
        this.helper = new Helper();

    }

    handleClick = (event, indexUsuario) => {
        let usuarios = this.state.usuarios;
        usuarios[indexUsuario].open = true
        usuarios.forEach((item, index) => {
            if (index != indexUsuario) {
                item.open = false;
            }

        })
        this.setState({
            anchorEl: event.currentTarget,
            usuarios,
            idRegister: usuarios[indexUsuario].id
        })
    };

    handleClose = (indexUsuario) => () => {
        let usuarios = this.state.usuarios;
        usuarios[indexUsuario].open = false
        this.setState({ anchorEl: null, usuarios })
    };

    componentDidMount() {
        this.getUsuarios()
    }

    getUsuarios() {
        this.setState({ usuarios: [] })
        this.indexPosition = 0;
        userService.getUsuarios(this.state.filters)
            .get().then(async (snapshot) => {
                let snapshotBack = snapshot.docs[0];
                let snapshotNext = snapshot.docs[snapshot.docs.length - 1];
                this.setState({ snapshotBack, snapshotNext })
                this.generarUsuarios(snapshot)

            });

    }

    async generarUsuarios(snapshot) {
        let usuarios = [];
        snapshot.forEach((doc, key) => {
            let item = doc.data()
            item.id = doc.id
            if (item.nombre && item.apellido) {
                item.nombres = item.nombre + ' ' + item.apellido
            }
            item.fechaCreacionSrt = this.helper.generateDate(item.fechaCreacionSrt, false);
            if(firebase.auth().currentUser.email != item.email){
                usuarios.push(item);
            }
            item.numPlanes = 0;
            if(item.planes){
                item.planes.forEach(plan => {
                    if(plan.estado == 'inactivo' || plan.estado == 'reactivar'){
                        item.numPlanes++;
                    }
                })
            }

            if(item.subscripcion == 'registrado'){
                item.subscripcionCss = 'status-text status-text-blue'
            }
            if(item.subscripcion == 'pendiente'){
                item.subscripcionCss = 'status-text status-text-orange'
            }
            if(item.subscripcion == 'activo'){
                item.subscripcionCss = 'status-text status-text-green'
            }
            if(item.subscripcion == 'inactivo'){
                item.subscripcionCss = 'status-text status-text-gray'
            }
        })
        let usuariosFinales = []
        for (let index = 0; index < usuarios.length; index++) {
            usuarios[index].nombrePlan = ''
            usuarios[index].open = false;
           
            usuariosFinales.push(usuarios[index])
        }
        this.setState({ usuarios: usuariosFinales })
    }

    openActualizarEstado = (usuario, estado) => () => {
        let mensaje = '¿Estas seguro de bloquear el usuario?'
        if (estado == 1) {
            mensaje = '¿Estas seguro de activar el usuario?'

        }
        this.setState({
            isModalShowDelete: true,
            estadoRegister: estado,
            mensaje: mensaje
        })
    }

    actualizarEstado = () => () => {
        let data = { estado: this.state.estadoRegister }
        userService.actualizarEstadoVendedor(this.state.idRegister, data, () => {
            toast.info('Usuario actualizado correctamente');
            this.setState({ isModalShowDelete: false, idRegister: '', estadoRegister: '' })
            this.handleClose()
            this.getUsuarios()
        })
    }

    closeModal = (origen) => () => {
        if(origen == 'D'){
            this.setState({ isModalShowDelete: false, idRegister: '', estadoRegister: '' })
        } else {
            this.setState({isModalSubscripciones: false, usuariosSelect: ''})
        }
        this.handleClose()
        this.getUsuarios()
        
    }

    getUsuariosPreview = () => () => {
        this.setState({ usuarios: [] })
        this.indexPosition--;
        if (this.indexPosition <= 0) {
            this.setState({ blockBack: true, blockNext: false })
        }
        userService.getBackUsuarios(this.state.filters,this.state.snapshotBack)
            .get().then(snapshot => {
                if (snapshot.docs.length > 0) {
                    let snapshotBack = snapshot.docs[0];
                    let snapshotNext = snapshot.docs[snapshot.docs.length - 1];
                    this.setState({ snapshotBack, snapshotNext })
                    this.generarUsuarios(snapshot)
                } else {
                    this.getUsuarios();
                }
            });
    }
    getUsuariosNext = () => () => {
        this.setState({ usuarios: [] })
        this.indexPosition++;
        if (this.indexPosition <= 0) {
            this.setState({ blockBack: true, blockNext: false })
        }
        userService.getNextUsuarios(this.state.filters,this.state.snapshotNext)
            .get().then(snapshot => {
                if (snapshot.docs.length > 0) {
                    let snapshotBack = snapshot.docs[0];
                    let snapshotNext = snapshot.docs[snapshot.docs.length - 1];
                    this.setState({ snapshotBack, snapshotNext })
                    this.generarUsuarios(snapshot)
                } else {
                    this.getUsuarios();
                }
            });
    }

    

    viewModal = (row) => () => {
        this.setState({ isModalSubscripciones: true, usuariosSelect: row })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let filters = {...this.state.filters}
        filters[name] = value
        this.setState({filters});
    }

    search = () => () => {
        this.getUsuarios();
    }

    clear = () => () => {
        let filters = {...this.state.filters}
        filters.dni = ''
        filters.codigo = ''
        filters.estado = ''
        this.setState({filters});
        let interval = setInterval(() => {
            this.getUsuarios();
            clearInterval(interval);
        }, 100)
    }

    render() {
        return (
            <Container style={{ marginTop: 50 }}>
                <div>
                    <div>Filtros</div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name='dni'
                                label='Cédula o RUC'
                                value={this.state.filters.dni ? this.state.filters.dni : ""}
                                fullWidth
                                margin="normal"
                                onChange={this.handleInputChange}
                            />
                            
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                name='codigo'
                                label='Código'
                                value={this.state.filters.codigo ? this.state.filters.codigo : ""}
                                fullWidth
                                margin="normal"
                                onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                select
                                name='estado'
                                label='Estado'
                                value={this.state.filters.estado ? this.state.filters.estado : ""}
                                fullWidth
                                margin="normal"
                                onChange={this.handleInputChange}
                            >
                                <MenuItem value="">todos</MenuItem>
                                <MenuItem value="registrado">registrado</MenuItem>
                                <MenuItem value="pendiente">pendiente</MenuItem>
                                <MenuItem value="activo">activo</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                                <Tooltip title="Buscar">
                                    <Fab size="small" color="secondary" aria-label="buscar" onClick={this.search()}>
                                        <SearchIcon />
                                    </Fab>
                                </Tooltip>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                                <Tooltip title="Limpiar busqueda">
                                    <Fab size="small" color="secondary" aria-label="buscar" onClick={this.clear()}>
                                        <AutoFixNormalIcon />
                                    </Fab>
                                </Tooltip>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Usuario</StyledTableCell>
                                    <StyledTableCell>Cédula</StyledTableCell>
                                    <StyledTableCell>Código</StyledTableCell>
                                    <StyledTableCell>Contacto</StyledTableCell>
                                    <StyledTableCell>Estado</StyledTableCell>
                                    <StyledTableCell>Fecha Solicitud</StyledTableCell>
                                    <StyledTableCell>Estado del Plan</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.usuarios.map((row, index) => (
                                    <StyledTableRow key={'US' + index}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.nombres}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.dni}</StyledTableCell>
                                        <StyledTableCell>{row.codigo}</StyledTableCell>
                                        <StyledTableCell>
                                            <div><strong>Email: </strong> {row.email}</div>
                                            <div><strong>Telefono: </strong> {row.celular}</div>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {row.estado == 1 ? 
                                                (<CheckIcon style={{color: '#00FF00'}}></CheckIcon>) 
                                                : 
                                                (<CloseIcon style={{color: '#FF0000'}}></CloseIcon>)
                                            }
                                        </StyledTableCell>

                                        <StyledTableCell>{row.fechaCreacionSrt}</StyledTableCell>

                                        <StyledTableCell>
                                            <div className={row.subscripcionCss}>{row.subscripcion}</div> 
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <div>
                                                <Badge badgeContent={row.numPlanes} color="warning">
                                                    <IconButton
                                                        id={"basic-button" + index}
                                                        aria-controls={row.open ? 'basic-menu' + index : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={row.open ? 'true' : undefined}
                                                        onClick={(event) => { this.handleClick(event, index) }}
                                                    >
                                                        <MenuIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id={"basic-menu" + index}
                                                        anchorEl={this.state.anchorEl}
                                                        open={row.open}
                                                        onClose={this.handleClose(index)}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'basic-button' + index,
                                                        }}
                                                    >
                                                        {(row.subscripcion == 'pendiente' || row.subscripcion == 'activo') && 
                                                            <MenuItem onClick={this.viewModal(row)}>Planes</MenuItem>
                                                        }

                                                        <MenuItem onClick={this.openActualizarEstado(row, 1)}>Desbloquear</MenuItem>
                                                        <MenuItem onClick={this.openActualizarEstado(row, 0)}>Bloquear</MenuItem>

                                                    </Menu>
                                                </Badge>
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ padding: 10, textAlign: 'center' }}>
                        <Tooltip title="Atras">
                            <IconButton onClick={this.getUsuariosPreview()}>
                                <NavigateBeforeIcon />
                            </IconButton>
                        </Tooltip>
                        {' '}
                        <Tooltip title="Siguiente">
                            <IconButton onClick={this.getUsuariosNext()}>
                                <NavigateNextIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{marginTop: 10, marginBottom: 20}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <span style={{fontWeight: 'bold'}}>Desbloqueado: </span>
                            <CheckIcon style={{color: '#00FF00'}}></CheckIcon>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <span style={{fontWeight: 'bold'}}>Bloqueado: </span>
                            <CloseIcon style={{color: '#FF0000'}}></CloseIcon>
                        </div>
                    </div>
                </div>
                <DeleteModal
                    title={this.state.mensaje}
                    onAccept={this.actualizarEstado()}
                    onCancel={this.closeModal('D')}
                    isModalShow={this.state.isModalShowDelete}

                />
                {this.state.isModalSubscripciones ? (
                    <ModalSubscripciones
                        onCancel={this.closeModal('S')}
                        isModalShow={this.state.isModalSubscripciones}
                        usuario={this.state.usuariosSelect}
                    />

                ): null }
            </Container>
        )
    }
}