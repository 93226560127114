import React, { Component } from 'react';
import {
    Container, IconButton, Table, TableBody, TableContainer, TableHead, TableRow,
    Paper, Tooltip, TableCell, tableCellClasses
}
    from '@mui/material';
import { styled } from '@mui/material/styles';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { userService } from '../../services/user.service';
import { denunciasService } from '../../services/denuncias.service';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default class TablaDenuncias extends Component {
    constructor() {
        super()
        this.state = {
            persona: {
                nombres: '',
                dni: '',
                email: ''
            },
            nombreCliente: '',
            cedulaCliente: '',
            correoCliente: '',
            codigo: '',
            denuncias: [],
            snapshotNext: '',
            snapshotBack: '',
            usuarios: [],
        }
    }

    componentDidMount() {
        this.getDenuncias()
    }

    getDenuncias() {
        this.setState({ denuncias: [] })
        denunciasService.getRegistersDenuncias()
            .get().then(async (snapshot) => {
                let snapshotBack = snapshot.docs[0];
                let snapshotNext = snapshot.docs[snapshot.docs.length - 1];
                this.setState({ snapshotBack, snapshotNext })
                this.generarDenuncias(snapshot)

            });
    }

    async generarDenuncias(snapshot) {
        let denuncias = [];
        snapshot.forEach((doc, key) => {
            let item = doc.data()
            item.id = doc.id
            denuncias.push(item);
        })
        let denunciasFinales = []
        for (let index = 0; index < denuncias.length; index++) {
            denuncias[index].usuario = await userService.getUsuarioAsync(denuncias[index].idVendedor)
            denunciasFinales.push(denuncias[index])
        }
        this.setState({ denuncias: denunciasFinales })
    }

    getDenunciasPreview = () => () => {
        this.setState({ denuncias: [] })
        denunciasService.getBackDenuncias(this.state.snapshotBack)
            .get().then(snapshot => {
                if (snapshot.docs.length > 0) {
                    let snapshotBack = snapshot.docs[0];
                    let snapshotNext = snapshot.docs[snapshot.docs.length - 1];
                    this.setState({ snapshotBack, snapshotNext })
                    this.generarDenuncias(snapshot)
                } else {
                    this.getDenuncias();
                }
            });
    }

    getDenunciasNext = () => () => {
        this.setState({ denuncias: [] })
        denunciasService.getNextDenuncias(this.state.snapshotNext)
            .get().then(snapshot => {
                if (snapshot.docs.length > 0) {
                    let snapshotBack = snapshot.docs[0];
                    let snapshotNext = snapshot.docs[snapshot.docs.length - 1];
                    this.setState({ snapshotBack, snapshotNext })
                    this.generarDenuncias(snapshot)
                } else {
                    this.getDenuncias();
                }
            });
    }

    render() {
        return (
            <Container style={{ marginTop: 50 }}>
                <div style={{ marginBottom: 50 }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="right">Denunciante</StyledTableCell>
                                    <StyledTableCell align="center">Cédula Denunciante</StyledTableCell>
                                    <StyledTableCell align="right">Correo del denunciante</StyledTableCell>
                                    <StyledTableCell align="center">Motivo de la denuncia</StyledTableCell>
                                    <StyledTableCell align="right">Usuarios</StyledTableCell>
                                    <StyledTableCell align="right">Cédula</StyledTableCell>
                                    <StyledTableCell align="right">Código</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.denuncias.map((row, index) => (
                                    <StyledTableRow key={'US' + index}>
                                        <StyledTableCell align="right">{row.nombreCliente}</StyledTableCell>
                                        <StyledTableCell align="center">{row.cedulaCliente}</StyledTableCell>
                                        <StyledTableCell align="center">{row.correoCliente}</StyledTableCell>
                                        <StyledTableCell align="center">{row.denuncia}</StyledTableCell>
                                        <StyledTableCell align="right">{row.usuario.nombre + ' ' + row.usuario.apellido}</StyledTableCell>
                                        <StyledTableCell align="right">{row.usuario.dni}</StyledTableCell>
                                        <StyledTableCell align="right">{row.usuario.codigo}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ padding: 10, textAlign: 'center' }}>
                        <Tooltip title="Atras">
                            <IconButton onClick={this.getDenunciasPreview()}>
                                <NavigateBeforeIcon />
                            </IconButton>
                        </Tooltip>
                        {' '}
                        <Tooltip title="Siguiente">
                            <IconButton onClick={this.getDenunciasNext()}>
                                <NavigateNextIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Container>
        )
    }
}