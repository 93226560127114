import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class PublicidadService extends BaseService {

    constructor() {
        super();
        this.collection = 'publicidad';
    }

    getPublicidades(){
        return firebase.firestore()
        .collection(this.collection)
        .orderBy('nombre', 'asc')
    
    }

    getNextPublicidad(lastVisible){
        return firebase.firestore()
        .collection(this.collection)
        .orderBy('nombre', 'asc') 
        .startAfter(lastVisible)
            
    }

    getBackPublicidad(lastVisible){
        return firebase.firestore()
        .collection(this.collection) 
        .orderBy('nombre', 'asc')
        .endBefore(lastVisible)  
              
    }

}

export const publicidadService = new PublicidadService();