import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class CategoriasService extends BaseService {

    constructor() {
        super();
        this.collection = 'categorias';
    }

    getCategoriaPorNickname(nickName, callback) {
        firebase.firestore()
            .collection(this.collection)
            .where('nickName', '==' , nickName)
            .get().then(snapshot => {
                let categorias = []
                snapshot.forEach(item => {
                    let categoria = item.data();
                    categoria.id = item.id
                    categorias.push(categoria)
                })
                callback(categorias[0])
            })

    }


    getSubCategoria(idPadre, callback) {
        firebase.firestore()
            .collection(this.collection)
            .where('idPadre', '==', idPadre)
            .get().then(snapshot => {
                let subcategorias = []
                snapshot.forEach(item => {
                    let subcategoria = item.data();
                    subcategoria.id = item.id
                    subcategorias.push(subcategoria)
                })
                callback(subcategorias)
            })

    }


    getCategoriasPorNivel(nivel, callback) {
        firebase.firestore()
            .collection(this.collection)
            .where('nivel', '>=', nivel)
            .get().then(snapshot => {
                let subcategorias = []
                snapshot.forEach(item => {
                    let subcategoria = item.data();
                    subcategoria.id = item.id
                    subcategorias.push(subcategoria)
                })
                callback(subcategorias)
            })

    }

    getCategoriasNivel1(callback) {
        firebase.firestore()
            .collection(this.collection)
            .where('nivel', '==', 1)
            .orderBy('nombre', 'asc')
            .get().then(snapshot => {
                let subcategorias = []
                snapshot.forEach(item => {
                    let subcategoria = item.data();
                    subcategoria.id = item.id
                    subcategorias.push(subcategoria)
                })
                callback(subcategorias)
            })

    }


    getImagenesCategoria(callback) {
        firebase.firestore()
            .collection('imagenesCategoria').get().then(snapshot => {
                let imagenes = []
                snapshot.forEach(item => {
                    imagenes.push(item.get('nombre'))
                })
                callback(imagenes)
            })

    }

    async getCategoriaAsync(categoridaId) {
        let categoria = await firebase.firestore()
            .collection(this.collection)
            .doc(categoridaId).get();
        return categoria.data();
    }

    async getSubCategoriasAsync(idPadre) {
        let snapshot = await firebase.firestore()
            .collection(this.collection)
            .where('idPadre', '==', idPadre).get()
        let subcategorias = []
        snapshot.forEach(item => {
            let subcategoria = item.data();
            subcategoria.id = item.id
            subcategorias.push(subcategoria)
        })
        return subcategorias
    }

    getCategoriaPorNombre(nombre, callback) {
        firebase.firestore()
            .collection(this.collection)
            .orderBy('nombre')
            .startAt(nombre).endAt(nombre + '\uf8ff')
            .get().then(snapshot => {
                let categorias = []
                snapshot.forEach(item => {
                    let categoria = item.data();
                    categoria.id = item.id
                    categorias.push(categoria)
                })
                callback(categorias)
            })

    }
}

export const categoriasService = new CategoriasService();