import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Guest from './navigation/Guest';
import Logged from './navigation/Logged';
import Admin from './navigation/Admin';
import Preloader from './components/Preloader';


import firebaseConfig from './utils/Firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.firestore().settings({ experimentalForceLongPolling: true });

const theme = createTheme({
  palette: {
    primary: {
      main: '#1f93a1',
    },
    secondary: {
      main: '#FFBF00',
    },
  },
});

export default class App extends React.Component {

  constructor() {
    super();
    this.state = {
      isLogged: false,
      isAdmin: false,
      loader: false
    }
  }

  componentDidMount() {
    firebaseApp.auth().onAuthStateChanged((user) => {

      if (user !== null) {
        if (user.emailVerified) {
          this.validAdmin()

        }
      } else {
        this.setState({ isLogged: false, isAdmin: false, loader: true });
      }



    })
  }

  validAdmin() {
    firebase.firestore().collection('admin')
      .get()
      .then(snapshot => {
        snapshot.forEach(element => {
          if (element.data().email === firebase.auth().currentUser.email) {
            this.setState({ isLogged: false, isAdmin: true, loader: true });
          } else {
            this.setState({ isLogged: true, isAdmin: false, loader: true });
          }
        })
      });
  }


  render() {
    const { loader, isLogged, isAdmin } = this.state;
    if (!loader) {
      return (<Preloader />);
    } else {
      if (!isLogged && !isAdmin) {
        return (
          <ThemeProvider theme={theme}>
            <Guest />
          </ThemeProvider>
        )
      }
      return (
        <ThemeProvider theme={theme}>
          {isLogged && <Logged />}
          {isAdmin && <Admin />}
        </ThemeProvider>
      )
    }
  }






}