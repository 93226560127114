import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class ProvinciasService extends BaseService {

    constructor() {
        super();
        this.collection = 'tbl_provincia';
    }

    async getProvinciaAsync(id_provincia) {
        let provincia = await firebase.firestore()
            .collection(this.collection)
            .doc(id_provincia).get();
        return provincia.data();
    }

}


export const provinciasService = new ProvinciasService();