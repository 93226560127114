import React from 'react';
import { Card, CardContent, Container } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Bloques from './Bloques';
import Planes from './Planes';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

class PlanesAdmin extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            indexTab: 0
        }
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange = (event, newValue) => {
        this.setState({indexTab: newValue})
    };

    render(){
        return (
          <Container>
            <Card style={{marginTop: 20}}>
                <CardContent>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={this.state.indexTab} onChange={this.handleTabChange} aria-label="basic tabs example">
                      <Tab label="Bloques" {...a11yProps(0)} />
                      <Tab label="Planes" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={this.state.indexTab} index={0}>
                    {this.state.indexTab == 0 &&
                      <Bloques/>
                    }
                  </TabPanel>
                  <TabPanel value={this.state.indexTab} index={1}>
                    {this.state.indexTab == 1 &&
                      <Planes/>
                    }
                  </TabPanel>
                </CardContent>
            </Card>
          </Container>
        )
    }
}

export default PlanesAdmin;